import gql from "graphql-tag"

export default gql`
    query getDiscounts($pagination: PaginationInput, $filter: DiscountFilterInput) {
    getDiscounts(pagination: $pagination, filter: $filter){
        statusCode
        message
        result{
            count
            discounts{
                id
                uid
                # operator{
                #     uid
                #     name
                #     userType
                # }
                platformType
                serviceType
                flight{
                    airlines
                    exceptAirlines
                    originAirportCodes
                    destinationAirportCodes
                    exceptOriginAirportCodes
                    exceptDestinationAirportCodes
                    applyOn
                }
                discount{
                    type
                    value
                }
                validityFrom
                validityTo
                # isCompanyDiscount
                isActive
                inActiveNote
                isDisplayable
                createdAt
                updatedAt
            }
        }
    }
}
`