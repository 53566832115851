import React from "react"
import { makeStyles } from '@material-ui/core/styles'
// import CircularProgress from '@material-ui/core/CircularProgress'
import theme from "config/theme"

import Lottie from 'react-lottie';
// import * as animationData from "assets/circular_loader.json"
import * as animatedCircle from "assets/circular_loader_2.json"

const useStyles = makeStyles({
    root: {
        // position: 'relative',
        // minHeight: "27rem",
        display: "flex",
        alignItems: "center"
    },
    bottom: {
        color: theme.primaryColor,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
})

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animatedCircle.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

export default function CircularLoader(props){
    const classes = useStyles()

    const { width } = props

    return(
        <div className={classes.root}>
            {/* <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={24}
                thickness={4}
                {...props}
            /> */}
            <Lottie 
                options={defaultOptions}
                width={width ? width : 250}
                // height={height ? height : 150}
            />
        </div>
    )
}