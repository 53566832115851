import client from "apolloClient"
import getDiscounts from "gql/discount/queries/getDiscounts"

export default (uid) => async () => {
    try {
        const response = await client.query({
            query: getDiscounts,
            variables: {
                filter: { uid }
            }
        }).then(response => response.data.getDiscounts.result.discounts[0])

        return response
        
    } catch (error) {
        console.error("Err in getDiscounts", error)
    }
}