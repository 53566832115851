import gql from "graphql-tag"

export default gql`
    mutation logoutAgent {
        logoutAgent{
            message
            statusCode
            result{
                id
            }
        }
    }
`