import React from "react"

import * as markupActions from "modules/flightMarkup/actions"
import * as actions from "modules/promocode/actions"
import * as appActions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import AnimatedPageRoute from "modules/common/components/AnimatedPageRoute"
import PromoCodeOverviewComponent from "modules/promocode/components/PromoCodeOverviewComponent"

const PromoCodeContainer = props => <AnimatedPageRoute><PromoCodeOverviewComponent {...props}/></AnimatedPageRoute>

const mapStateToProps = state => ({
    ...state
})

console.log(Object.assign(actions, markupActions, appActions));
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign(actions, markupActions, appActions), dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoCodeContainer))