import client from "apolloClient";
import createDiscount from "gql/discount/mutations/createDiscount";

export default ({discount}) => async () => {
  try {
    const response = await client
      .mutate({
        mutation: createDiscount,
        variables: { discount }
      })
      .then(response => response.data.createDiscount);

    if (response) return response;

    console.log("createDiscount", response);
  } catch (error) {
    console.error("Err in createDiscount", error);
  }
};
