import client from "apolloClient"
import getBankAccounts from "gql/bankAccount/queries/getBankAccounts"

export default ({pagination}) => async () => {
    try {
        const bankAccountsResponse = await client.query({
            query: getBankAccounts,
            variables: {
                pagination,
                filter: {
                    isCompanyBankAccount: false
            } }
        }).then(response => response.data.getBankAccounts.result)

        return bankAccountsResponse

    } catch (error) {
        console.log("Err in getBankAccounts:", error)
    }
}