// import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "100%",
        maxHeight: "100%",
        backgroundColor: "#f5f5f5",
        height: "100vh"
    },
    textContainer: {
        display: "block",
        maxWidth: "60%",
        margin: "0 auto",
        padding: "1rem 0",
        textAlign: "center"

    },
    title: {
        lineHeight: "1.2"
    },
    subtitle: {
        fontSize: "1.2rem",
    },
    imgContainer: {
        textAlign: "center",
        paddingTop: "3rem"
    },
    img: {
        maxWidth: "80vw",
        width: "600px"
    },
    bgImage: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        width: "100%",
        position: "relative",
        top: 0,
        left: 0,
        //height: "calc(100vh - 48px)"
        height: "100vh"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "2rem 0 0 0",

        "& .button": {
            width: "1.5rem",
            height: "1.5rem",
            minWidth: "1.1rem",
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",

            "&:hover:not(.active)":{
                backgroundColor: "#bbbbbb",
                color: "#ffffff",
                transition: "all 0.3s"
            },

            
        },

        "& .active": {
            backgroundColor: theme.primaryColor,
            color: "#ffffff",
            transition: "all 0.3s"
        },

        "& .button:not(:last-child)": {
            marginRight: ".8vw"
        }
    },
    [`@media (min-width: ${theme.smWidth})`]: {
        container: {
        }
    }
}))

export default function Home(props){
    const classes = useStyles();

    return props.children(classes)
}