import client from "apolloClient"
import updatePromoCode from "gql/promocode/mutations/updatePromoCode"

export default ({ promoCodeUid, promoCode }) => async () => {
    try {
        const response = await client.mutate({
            mutation: updatePromoCode,
            variables: { promoCodeUid, promoCode }
        }).then(response => response.data.updatePromoCode)

        if(response && (response.statusCode === 200 || response.statusCode === 201)){
            return response.result
        }

        console.log("updatePromoCode", response)
    } catch (error) {
        console.error("Err in updatePromoCode", error)
    }
}