import gql from "graphql-tag"

export default gql`
mutation updateBankAccount($bankAccountUid: String!, $bankAccount: BankAccountUpdateInput) {
    updateBankAccount(bankAccountUid: $bankAccountUid, bankAccount: $bankAccount){
        message
        statusCode
        result{
            id
            uid
            operator{
                uid
                userType
            }
            accountName
            accountNumber
            bankName
            branchName
            routingNumber
            city
            address
            swiftCode
            IFSC
            isCompanyBankAccount
            isActive
            inActiveNote
        }
    }
}
`