import CommonButton from "modules/common/components/CommonButton";
import CommonImage from "modules/common/components/CommonImage";
import { Component } from "react";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error found at ErrorBoundary", error, errorInfo)
    }

    reload(){
        window.location.reload();
        return false;
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="d-flex flex-column align-center justify-center w-100 mt-5">
                    <CommonImage
                        src="/images/bg/error-img.svg"
                        alt="error"
                        height="15rem"
                        className="mb-5"
                    />
                    {/* <h2 className="text-lightweight">Something went wrong!</h2> */}
                    <CommonButton
                        className="mt-3"
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={this.reload.bind(this)}
                    >
                        Reload
                    </CommonButton>
                </div>
            );
        }

        return this.props.children;
    }
}