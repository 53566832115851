import React from "react"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    key: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
        textTransform: "capitalize"
    }
}))

export default function CommonResponsiveList(props){
    const { items, segmentsCount } = props
    const classes = useStyles()

    let mdWidth = segmentsCount ? (12/segmentsCount) : 6;

    return (
        <Grid container spacing={2} className="MuiTypography-body2">
            {items && items.map((item,index) => (
                <Grid key={index} item xs={12} sm={12} md={mdWidth} lg={mdWidth}>
                    <Grid container spacing={1} >
                        <Grid 
                            item 
                            xs={6} 
                            className={`${classes.key}`}
                        >
                            { item.title }
                        </Grid>
                        <Grid item xs={6} className={item.valueClassName}>
                            { item.value }
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

CommonResponsiveList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.any,
        value: PropTypes.any
    })),
    segmentsCount: PropTypes.number
}