import client from "apolloClient"
import logoutAgent from "gql/auth/mutations/logoutAgent"

export default () => async () => {
    try {
        const logoutResponse = await client.mutate({
            mutation: logoutAgent
        }).then(response => response.data.logoutAgent)

        // dispatch({ type: "LOGOUT_REQUEST" })

        return logoutResponse
        
    } catch (error) {
        console.error("Err in logoutAgent", error)
    }
}