import gql from "graphql-tag"

export default gql`
query getBankAccounts($pagination: PaginationInput, $filter: BankAccountFilterInput) {
    getBankAccounts(pagination: $pagination, filter: $filter){
        message
        statusCode
        result{
            count
            bankAccounts{
                # id
                uid
                # operator{
                #     uid
                #     userType
                # }
                accountName
                accountNumber
                bankName
                branchName
                routingNumber
                city
                address
                swiftCode
                IFSC
                isCompanyBankAccount
                isActive
                inActiveNote
            }
        }
    }
}`