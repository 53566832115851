import React from "react"
import * as actions from "modules/bankAccount/actions"
import * as dashboardActions from "modules/dashboard/actions"
import * as appActions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import AnimatedPageRoute from "modules/common/components/AnimatedPageRoute"
import BankAccountDetailsComponent from "modules/bankAccount/components/BankAccountDetailsComponent"

const BankAccountDetailsContainer = props => <AnimatedPageRoute><BankAccountDetailsComponent {...props}/></AnimatedPageRoute>

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({}, actions, appActions, dashboardActions), dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankAccountDetailsContainer))