import gql from "graphql-tag"

export default gql`
    query getAirports($pagination: PaginationInput, $filter: AirportFilterInput) {
        getAirports(pagination: $pagination, filter: $filter){
            message
            statusCode
            result{
                # count
                airports{
                    # fullName
                    shortName
                    meta{
                        iataCode
                        # cityCode
                        cityName
                        # countryCode
                        # countryName
                    }
                    # isActive
                    # inActiveNote
                }
                # version
            }
        }
    }
`