import React from "react"
import * as actions from "modules/auth/actions"
import * as appActions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AnimatedPageRoute from "modules/common/components/AnimatedPageRoute"

import ForgotPasswordPasswordInputComponent from "modules/auth/components/ForgotPasswordPasswordInputComponent"

const ForgotPasswordPasswordInputContainer = props => <AnimatedPageRoute><ForgotPasswordPasswordInputComponent {...props}/></AnimatedPageRoute>

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({}, actions, appActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPasswordInputContainer)