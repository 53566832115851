import gql from "graphql-tag"

export default gql`
    mutation resetAgentPassword($token: String!, $newPassword: String!, $confirmPassword: String!) {
        resetAgentPassword(token: $token, newPassword: $newPassword, confirmPassword: $confirmPassword){
            message
            statusCode
            result{
                id
            }
        }
    }
`