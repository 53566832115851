import client from "apolloClient"
import getBankAccountForDetails from "gql/bankAccount/queries/getBankAccountForDetails"

export default (uid) => async () => {
    try {
        const bankAccountsResponse = await client.query({
            query: getBankAccountForDetails,
            variables: { pagination: { limit: 10, skip: 0 }, filter: { uid } }
        }).then(response => response.data.getBankAccounts.result.bankAccounts[0])

        return bankAccountsResponse

    } catch (error) {
        console.log("Err in getBankAccountForDetails:", error)
    }
}