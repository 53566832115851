import React, { useState } from "react"
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from "prop-types"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiSpeedDial-actions": {
            marginRight: "-44px"
        }
    },
    fab: {
        boxShadow: "none",
        backgroundColor: "transparent"
    }
}))

export default function CommonSpeedDial(props){
    const classes = useStyles(), theme = useTheme()
    const { items } = props

    const [ open, setOpen ] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const getBgColor = type => {
        let color = theme.palette.primary.main

        if(type && type === "success") color = theme.palette.success.main
        if(type && type === "error") color = theme.palette.error.main
        if(type && type === "warning") color = theme.palette.warning.main

        return color
    }

    return (
        <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon icon={<MoreVertIcon/>} openIcon={<MoreHorizIcon/>} />}
            // openIcon={<MoreHorizIcon/>}
            direction="left"
            open={open}
            FabProps={{
                size: "small",
                color: "inherit"
            }}
            onClose={handleClose}
            onOpen={handleOpen}
            classes={classes}
            onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
            }}
        >
            {items && items.length && items.map((item,i) => (
                <SpeedDialAction
                    key={i}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    onClick={item.onClick ? item.onClick : () => {}}
                    tooltipPlacement="top"
                    FabProps={{
                        style: { backgroundColor: getBgColor(item.color), margin: "8px 4px", color: "#fff" }
                    }}
                    
                />
            ))}
        </SpeedDial>
    )
}

CommonSpeedDial.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.any,
        onClick: PropTypes.func
    }))
}