import client from "apolloClient"
import resetAgentPassword from "gql/auth/mutations/resetAgentPassword"

export default ({ token, newPassword, confirmPassword }) => async () => {
    try {
        const resetPasswordResponse = await client.mutate({
            mutation: resetAgentPassword,
            variables: { token, newPassword, confirmPassword }
        }).then(response => response.data.resetAgentPassword)

        return resetPasswordResponse
    } catch (error) {
        console.error("Err in resetAgentPassword", error)
    }
}