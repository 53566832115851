const initialState = {
    searchQuery: {},
    airports: [],
    airportsVersion: null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SEARCH_FLIGHT':
            return {
                result: payload
            }
        
        case 'SET_AIRPORTS_DATA':
            if(state.airportsVersion !== payload.version) return { ...state, airports: [...payload.airports] }
            return state

        default:
            return state
    }
}