import gql from "graphql-tag"

export default gql`
    mutation createBankAccount($bankAccount: BankAccountCreateInput!) {
    createBankAccount(bankAccount: $bankAccount){
        message
        statusCode
        result{
            id
            uid
            operator{
                uid
                userType
            }
            accountName
            accountNumber
            bankName
            branchName
            routingNumber
            city
            address
            swiftCode
            IFSC
            isCompanyBankAccount
            isActive
            inActiveNote
        }
    }
}
`