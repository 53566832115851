import { Component, Fragment } from 'react'
import ReportDownloadOption from './ReportDownloadOption';

const REPORTS = [
  {
    heading: "Sales Report",
    urlSlug: "/download-b2b-flight-sales-csv"
  },
  {
    heading: "Deposit Report",
    urlSlug: "/download-deposit-csv"
  },
  {
    heading: "Ledger Report",
    urlSlug: "/download-ledger-csv"
  },
]

export default class ReportOverviewComponent extends Component {
  render() {
    return (
      <div className="container">
        {
          REPORTS.map((report, i) => (
            <Fragment>
              <h4 className={`text-uppercase mb-4 ${i !== 0 ? 'mt-5' : ''}`}>{report.heading}</h4>
              <ReportDownloadOption
                heading={report.heading}
                urlSlug={report.urlSlug}
              />
            </Fragment>
          ))
        }
      </div>
    )
  }
}
