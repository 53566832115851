import client from "apolloClient"
import getAirlines from "gql/common/queries/getAirlines"

export default searchQuery => async dispatch => {
    try {
        const airlinesResponse = await client.query({
            query: getAirlines,
            variables: searchQuery ? { filter: { query: searchQuery }, pagination: { limit: null, skip: 0 } } : { pagination: { limit: 10, skip: 0 } }
        }).then(response => response.data.getAirlines.result.airlines)

        return airlinesResponse
    } catch (error) {
        console.error("Err in getAirlines", error)
    }
}