import React, { lazy } from "react";

import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import FlightIcon from "@material-ui/icons/Flight";
import PeopleIcon from '@material-ui/icons/People';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd'
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import NotFoundContainer from "modules/notFound/containers/NotFoundContainer";
import LoginContainer from "modules/auth/containers/LoginContainer";
import RegistrationContainer from "modules/auth/containers/RegistrationContainer";
import ForgotPasswordEmailInputContainer from "modules/auth/containers/ForgotPasswordEmailInputContainer"
import ForgotPasswordPasswordInputContainer from "modules/auth/containers/ForgotPasswordPasswordInputContainer"
import BankAccountDetailsContainer from "modules/bankAccount/containers/BankAccountDetailsContainer";
import SubAgentContainer from "modules/subAgents/containers/SubAgentContainer";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DiscountContainer from "modules/discounts/containers/DiscountContainer";
import NotificationOverviewContainer from "modules/notification/containers/NotificationOverviewContainer";
import PromoCodeContainer from "modules/promocode/containers/PromoCodeContainer";
import { AUTH_CREATE_FLIGHT_BOOKING, AUTH_DOWNLOAD_B2B_FLIGHT_SALE, AUTH_GET_BANK_ACCOUNTS, AUTH_GET_DEPOSITS, AUTH_GET_DISCOUNTS, AUTH_GET_FLIGHT_BOOKINGS, AUTH_GET_FLIGHT_MARKUPS, AUTH_GET_ITINERARY_GROUP, AUTH_GET_LEDGERS, AUTH_GET_PROMOCODES, AUTH_GET_SUBAGENTS, AUTH_GET_TRAVELLERS } from "config/authActions";
import ReportContainer from "modules/reports/containers/ReportContainer";
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
// import PaymentConfirmationContainer from "modules/misc/containers/PaymentConfirmationContainer";

// AUTH REQUIRED ROUTES
const DashboardContainer = lazy(() => import("modules/dashboard/containers/DashboardContainer"));
const FlightQueueContainer = lazy(() => import("modules/flightQueue/containers/FlightQueueContainer"));
const DepositDetailsContainer = lazy(() => import("modules/deposit/containers/DepositDetailsContainer"));
const TravellersOverviewContainer = lazy(() => import("modules/travellers/containers/TravellersOverviewContainer"));
const TravellerDetailsContainer = lazy(() => import("modules/travellers/containers/TravellerDetailsContainer"));
const DepositContainer = lazy(() => import("modules/deposit/containers/DepositContainer"));
const FlightBookingInputsContainer = lazy(() => import("modules/flightBooking/containers/FlightBookingInputsContainer"));
const FlightBookingDetailsContainer = lazy(() => import("modules/flightQueue/containers/FlightBookingDetailsContainer"));
const MyProfileContainer = lazy(() => import("modules/myProfile/containers/MyProfileContainer"));
const FlightMarkupContainer = lazy(() => import("modules/flightMarkup/containers/FlightMarkupContainer"));
const LedgersContainer = lazy(() => import("modules/ledgers/containers/LedgerOverviewContainer"));
const BankAccountsOverviewContainer = lazy(() => import("modules/bankAccount/containers/BankAccountsOverviewContainer"))
const CompanyBankAccountsOverviewContainer = lazy(() => import("modules/companyBankAccount/containers/BankAccountOverviewContainer"))
const CompanyBankAccountDetailsContainer = lazy(() => import("modules/companyBankAccount/containers/BankAccountDetailsContainer"))
const PartialPaymentsListContainer = lazy(() => import("modules/partialPayments/containers/PartialPaymentsListContainer"))
const FlightSearchAndFeedContainer = lazy(() => import("modules/flightSearch/containers/FlightSearchAndFeedContainer"))
const GroupFareInputContainer = lazy(() => import("modules/groupFare/containers/GroupFareInputContainer"))
const GroupFareRequestDetailsContainer = lazy(() => import("modules/groupFare/containers/GroupFareRequestDetailsContainer"))

const WithoutAuthRoutes = [
    {
        path: "/login",
        exact: true,
        component: LoginContainer,
        title: "Auth",
    },
    {
        path: "/forgot-password-email",
        exact: true,
        component: ForgotPasswordEmailInputContainer,
        title: "Auth",
    },
    {
        path: "/resetpassword",
        exact: true,
        component: ForgotPasswordPasswordInputContainer,
        title: "Auth",
    },
    {
        path: "/",
        exact: true,
        component: RegistrationContainer,
        title: "Auth",
    },
];

const RoutesOnSidebar = [
    {
        path: "/booking-panel",
        exact: true,
        component: <FlightSearchAndFeedContainer />,
        title: "Booking Panel",
        icon: <WidgetsOutlinedIcon />,
        prioritize: true,
        privilege: AUTH_GET_ITINERARY_GROUP
    },
    ...(process.env.REACT_APP_FEATURE_GROUP_FARE === "ON" ? [{
        path: "/groupfare-request",
        exact: true,
        component: <GroupFareInputContainer />,
        title: "Group Fare Request",
        icon: <PeopleIcon />,
        prioritize: true,
        privilege: AUTH_GET_ITINERARY_GROUP
    }] : []),
    {
        path: "/dashboard",
        exact: true,
        component: <DashboardContainer />,
        title: "Dashboard",
        icon: <InsertChartOutlinedIcon />,
        prioritize: true,
    },
    {
        path: "/flight-queue",
        exact: true,
        component: <FlightQueueContainer />,
        title: "Flight Queue",
        icon: <FlightIcon />,
        prioritize: true,
        privilege: AUTH_GET_FLIGHT_BOOKINGS
    },    
    {
        path: "/travellers",
        exact: true,
        component: <TravellersOverviewContainer />,
        title: "Travellers",
        icon: <PermContactCalendarOutlinedIcon />,
        privilege: AUTH_GET_TRAVELLERS
    },
    {
        path: "/bank-accounts",
        exact: true,
        component: <BankAccountsOverviewContainer />,
        title: "Bank Accounts",
        icon: <AccountBalanceOutlinedIcon />,
        privilege: AUTH_GET_BANK_ACCOUNTS
    },
    {
        path: "/company-bank-accounts",
        exact: true,
        component: <CompanyBankAccountsOverviewContainer />,
        title: "Company Bank Accounts",
        icon: <ApartmentOutlinedIcon />,
        privilege: AUTH_GET_BANK_ACCOUNTS
    },
    {
        path: "/deposits",
        exact: true,
        component: <DepositContainer />,
        title: "Deposits",
        icon: <SaveAltOutlinedIcon />,
        privilege: AUTH_GET_DEPOSITS
    },
    {
        path: "/partial-payments/:type",
        exact: true,
        component: <PartialPaymentsListContainer />,
        title: "Partial Payments",
        icon: <CardMembershipOutlinedIcon />,
        privilege: AUTH_GET_DEPOSITS,
        features: [
            {
                title: "All",
                path: "/partial-payments/all",
                icon: <CardMembershipOutlinedIcon/>,
                component: <PartialPaymentsListContainer />,
                privilege: AUTH_GET_DEPOSITS
            },
            {
                title: "Due",
                path: "/partial-payments/due",
                icon: <CardMembershipOutlinedIcon/>,
                component: <PartialPaymentsListContainer />,
                privilege: AUTH_GET_DEPOSITS
            },
            {
                title: "Completed",
                path: "/partial-payments/completed",
                icon: <CardMembershipOutlinedIcon/>,
                component: <PartialPaymentsListContainer />,
                privilege: AUTH_GET_DEPOSITS
            }
        ]
    },
    {
        path: "/ledgers",
        exact: true,
        component: <LedgersContainer />,
        title: "Ledgers",
        icon: <SwapVerticalCircleOutlinedIcon />,
        privilege: AUTH_GET_LEDGERS
    },
    {
        path: "/flight-markup",
        exact: true,
        component: <FlightMarkupContainer />,
        title: "Flight Markup",
        icon: <PostAddIcon />,
        privilege: AUTH_GET_FLIGHT_MARKUPS
    },
    {
        path: "/subagents",
        exact: true,
        component: <SubAgentContainer />,
        title: "Sub Agents",
        icon: <PersonAddIcon />,
        privilege: AUTH_GET_SUBAGENTS
    },
    {
        path: "/discounts",
        exact: true,
        component: <DiscountContainer />,
        title: "Discounts",
        icon: <MonetizationOnIcon />,
        privilege: AUTH_DOWNLOAD_B2B_FLIGHT_SALE
    },
    {
        path: "/reports",
        exact: true,
        component: <ReportContainer />,
        title: "Reports",
        icon: <AssessmentIcon />,
        privilege: AUTH_GET_DISCOUNTS
    },
    // {
    //     path: "/promocodes",
    //     exact: true,
    //     component: <PromoCodeContainer />,
    //     title: "Promocodes",
    //     icon: <LoyaltyIcon />,
    //     privilege: AUTH_GET_PROMOCODES
    // },
    // {
    //     path: "/my-account",
    //     exact: true,
    //     component: <MyAccountContainer />,
    //     title: "My Account",
    //     icon: <WorkOutlineOutlinedIcon/>,
    // }
];

const RoutesForBookingPanel = [
    {
        path: "/book-flight",
        exact: true,
        component: <FlightBookingInputsContainer />,
        title: "Book Flight",
        privilege: AUTH_CREATE_FLIGHT_BOOKING
    },
    {
        path: "/feed",
        exact: true,
        component: <FlightSearchAndFeedContainer />,
        title: "Flight Feed",
        privilege: AUTH_GET_ITINERARY_GROUP
    },
];

const RoutesForFlightQueue = [
    {
        path: "/flight-booking",
        exact: true,
        component: <FlightBookingDetailsContainer />,
        title: "Flight Booking Details",
    },
    {
        path: "/payment/:bookingUid",
        exact: true,
        component: <FlightBookingDetailsContainer />,
        title: "Flight Booking Details",
    },
];

const RoutesForCompanyBankAccount = [
    {
        path: "/company-bank-account-details",
        exact: true,
        component: <CompanyBankAccountDetailsContainer />,
        title: "Company Bank Account Details",
    },
];

const RoutesForDeposit = [
    {
        path: "/deposit-details",
        exact: true,
        component: <DepositDetailsContainer />,
        title: "Flight Deposit Details",
    },
];
const RoutesForGroupFare = [
    ...(process.env.REACT_APP_FEATURE_GROUP_FARE === "ON" ? [{
        path: "/groupfare-details",
        exact: true,
        component: <GroupFareRequestDetailsContainer />,
        title: "Group Fare Request Details",
    }] : [] )
];

const RoutesForTraveller = [
    {
        path: "/traveller-details",
        exact: true,
        component: <TravellerDetailsContainer />,
        title: "Flight Deposit Details",
    },
];


const RoutesNotOnSidebar = [
    {
        path: "/404",
        exact: true,
        component: <NotFoundContainer />,
        title: "NotFound",
        // icon: <DashboardIcon />,
    },
    {
        path: "/my-profile",
        exact: true,
        component: <MyProfileContainer />,
        title: "My Account",
        icon: <AccountCircleIcon />,
    },
    {
        path: "/notifications",
        exact: true,
        component: <NotificationOverviewContainer />,
        title: "Notifications",
        icon: <AccountCircleIcon />,
    },
];

const RoutesForSettings = [
    // {
    //     path: "/flight-markup",
    //     exact: true,
    //     component: <FlightMarkupContainer />,
    //     title: "Flight Markup",
    //     icon: <TrendingUpIcon />,
    // },
];

const RoutesForBankAccounts = [
    {
        path: "/bank-account",
        exact: true,
        component: <BankAccountDetailsContainer/>,
    }
]

export {
    WithoutAuthRoutes,
    RoutesOnSidebar,
    RoutesNotOnSidebar,
    RoutesForBookingPanel,
    RoutesForFlightQueue,
    RoutesForDeposit,
    RoutesForGroupFare,
    RoutesForSettings,
    RoutesForTraveller,
    RoutesForBankAccounts,
    RoutesForCompanyBankAccount
};
