import gql from "graphql-tag"

export default gql`
    mutation loginAgent($auth: AuthInput!, $password: String!, $device: CreateDeviceInput) {
        loginAgent(auth: $auth, password: $password, device: $device){
            message
            statusCode
            result{
                token
                refreshToken
                expiresAt
            }
        }
    }
`