import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CommonTextField from "./CommonTextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import { fullTextPopper } from "./CustomPopper";
// import InputAdornment from '@material-ui/core/InputAdornment'
// import SearchIcon from '@material-ui/icons/Search';



export default function CommonAsyncSearchBox(props){
    const {
        value,
        open,
        onOpen,
        onClose,
        loading,
        multiple,
        size,
        className,
        popper,
        // disabled,
        // disableOpenOnFocus,
        renderOption,
        getOptionLabel,
        getOptionSelected,
        label,
        limitTags,
        options,
        noOptionsText,
        onChange,
        onInputChange,
        errorText,
        disabled,
        InputProps,
        ...restProps
    } = props

    return (
        <Autocomplete
            PopperComponent={popper ?? fullTextPopper}
            value={value}
            open={open}
            onOpen={onOpen}
            multiple={multiple}
            size={size}
            className={className}
            onClose={onClose}
            loading={loading}
            disabled={disabled}
            getOptionSelected={getOptionSelected}
            // disableOpenOnFocus={disabled}
            renderOption={renderOption}
            // InputProps={{
            //     startAdornment: this.props.value ? (
            //         <InputAdornment position="start">
            //             <CommonImage
            //                 src={`${logoImageUrlPrefix}${this.props.value}.png`}
            //                 height="2rem"
            //             />
            //         </InputAdornment>
            //     ) : ""
            // }}
            getOptionLabel={getOptionLabel}
            label={label ?? 'Select'}
            limitTags={limitTags}
            options={options}
            noOptionsText={noOptionsText}
            renderInput={params => {
                return (
                    <CommonTextField 
                        {...params} 
                        label={props.label} 
                        errorText={errorText}
                        disabled={disabled}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {props.loading ? <CircularProgress color="primary" size={20} /> : null}
                                    {disabled ? "" : params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            ...InputProps
                            // startAdornment: (
                            //     <InputAdornment position="start">
                            //         <SearchIcon
                            //             className="gray-text mx-1" 
                            //             style={{ color: "rgba(0, 0, 0, 0.45)" }}/>
                            //     </InputAdornment>
                            // )
                        }}
                    />
                )
            }}
            disableClearable
            onChange={onChange}
            onInputChange={onInputChange}
            filterOptions={options => options}
            // {...restProps}
        />
    )
}