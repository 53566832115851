import client from "apolloClient";
import createAgent from "gql/auth/mutations/createAgent";

export default (agent) => async () => {
    console.log("AgentObj in API Call -> ", agent);
    try {
        const createAgentResponse = await client.mutate({
            mutation: createAgent,
            variables: { agent },
        }).then((response) => response.data.createAgent);

        console.log("createAgentResponse", createAgentResponse);

        return createAgentResponse;
    } catch (error) {
        console.error("Err in createAgent", error);
        return;
    }
};
