import client from "apolloClient"
import getClientBankAccountForUpdate from "gql/bankAccount/queries/getBankAccountForUpdate"

export default ({ bankAccountId }) => async () => {
    try {
        const getClientBankAccountResponse = await client.query({
            query: getClientBankAccountForUpdate,
            variables: { filter: { _id: bankAccountId } }
        }).then(response => response.data.getClientBankAccounts.result)

        return getClientBankAccountResponse
    } catch (error) {
        console.error("Err in getClientBankAccountForUpdate", error)
    }
}