import client from "apolloClient";
import createSubAgent from "gql/subAgents/mutations/createSubAgent";

export default ({subAgent}) => async () => {
  try {
    const createSubAgentResponse = await client
      .mutate({
        mutation: createSubAgent,
        variables: { subAgent }
      })
      .then(response => response.data.createSubAgent);

    console.log("createSubAgentResponse", createSubAgentResponse);

    return createSubAgentResponse;
  } catch (error) {
    console.error("Err in createSubAgent", error);
  }
};
