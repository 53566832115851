import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducers"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [ thunk ]

if(process.env.NODE_ENV !== "production"){
    const { createLogger } = require("redux-logger")
    const logger = createLogger({
        duration: true
    })

    middlewares.push(logger)
}

export default () => {
    let store = createStore(persistedReducer, {}, applyMiddleware(...middlewares))
    let persistor = persistStore(store)
    return { store, persistor }
}