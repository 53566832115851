import React, { Component } from "react"
import CommonAsyncSearchBox from "modules/common/components/CommonAsyncSearchBox"

export default class MultiAirportSearchBox extends Component {
    initAirports = [
        { shortName: "All Airports",meta: { cityName: "All", iataCode: "_ALL_" } },
        { shortName: "Hazrat Shahjalal International Airport",meta: { cityName: "Dhaka", iataCode: "DAC" } },
        { shortName:"Singapore Changi Airport",meta:{ iataCode:"SIN", cityName: "Singapore" } },
        { shortName:"Suvarnabhumi Airport",meta:{ iataCode:"BKK", cityName: "Bangkok" } },
        { shortName:"Kuala Lumpur International Airport",meta:{ iataCode:"KUL", cityName: "Kuala Lumpur" } },
        { shortName:"Ngurah Rai (Bali) International Airport",meta:{ iataCode:"DPS", cityName: "Denpasar" } },
        { shortName:"Netaji Subhash Chandra Bose International Airport",meta:{ iataCode:"CCU", cityName: "Kolkata" } },
        { shortName:"Tribhuvan International Airport",meta:{ iataCode:"KTM", cityName: "Kathmandu" } },
        { shortName:"Bandaranaike International Colombo Airport",meta:{ iataCode:"CMB", cityName: "Colombo" } }
    ]
    
    state = {
        airports: [ ...this.initAirports ],
        isLoadingAirports: false,
        isOpenDropdown: false,
        query: ""
    }

    searchInputTimer = null;

    componentWillUnmount(){
        if(this.searchInputTimer) clearTimeout(this.searchInputTimer)
    }

    openDropdown(){
        this.setState({ isOpenDropdown: true })
    }

    closeDropdown(){
        this.setState({ isOpenDropdown: false }, () => {
            if(
                this.state.query && 
                this.state.query.length === 3 &&
                this.props.value && 
                this.props.value.meta && 
                this.props.value.meta.iataCode && 
                this.state.query.toLowerCase() !== this.props.value.meta.iataCode.toLowerCase()
            ){
                this.props.onInput({ meta: { iataCode: this.state.query.toUpperCase() } })
            } else if(
                this.state.query && 
                this.props.value && 
                this.props.value.meta && 
                this.props.value.meta.iataCode && 
                this.state.query.toLowerCase() !== this.props.value.meta.iataCode.toLowerCase()
            ) {
                this.props.onInput(null)
            }
        })
    }

    async getAirports(searchQuery){
        if(!searchQuery || !this.state.isOpenDropdown) {
            this.setState({ isLoadingAirports: false, airports: [ ...this.initAirports ] })
            return;
        } else if(searchQuery && searchQuery.length < 3){
            this.setState({ isLoadingAirports: false, airports: [] })
            return;
        }

        const airports = await this.props.actionGetAirports(searchQuery)

        this.setState({ isLoadingAirports: false, airports })
    }

    handleInputChange(event,value){
        console.log("input", JSON.stringify(value))
        this.props.onInput(value)
    }

    onInputChange(e,val){
        if(val && !this.state.isLoadingAirports && this.state.isOpenDropdown) this.setState({ isLoadingAirports: true })

        this.setState({ query: val })

        if(this.searchInputTimer) clearTimeout(this.searchInputTimer)
        this.searchInputTimer = setTimeout(() => { this.getAirports(val) }, 800)
    }

    render(){
        const { label, value, errorText } = this.props

        return (
            <CommonAsyncSearchBox
                size="small"
                value={value}
                multiple={true}
                open={this.state.isOpenDropdown}
                onOpen={this.openDropdown.bind(this)}
                onClose={this.closeDropdown.bind(this)}
                options={this.state.airports}
                noOptionsText="No Airports Found!"
                loading={this.state.isLoadingAirports}
                label={label}
                errorText={errorText}
                renderOption={option => (
                    <React.Fragment>
                        { option.shortName }, { option.meta && option.meta.cityName } ({ option.meta && option.meta.iataCode })
                    </React.Fragment>
                )}
                getOptionLabel={option => option && option.meta && option.meta.iataCode ? `${option.meta.iataCode}` : ""}
                // onOpen={() => this.getAirports()}
                onChange={this.handleInputChange.bind(this)}
                onInputChange={this.onInputChange.bind(this)}
            />
        )
    }
}
