import CommonLink from "modules/common/components/CommonLink";

export default function OtherLinks({ className }){
    return (
        <div className={className ? className : "" + " text-center mt-4"}>
            <small>
                <CommonLink
                    href="/terms"
                    className="mr-4"
                >
                    Terms of Use
                </CommonLink>

                <CommonLink
                    href="/privacy-policy"
                >
                    Privacy Policy
                </CommonLink>
            </small>
        </div>
    )
}