import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
  cloneObjectOrArray,
  translateEnumToText,
  formatDate,
  minifyArrayToString,
  isAuthorized
} from "helper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CommonChip from "modules/common/components/CommonChip";
import CommonSpeedDial from "modules/common/components/CommonSpeedDial";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
// import createOrUpdateSubAgentComponent from "./createOrUpdateSubAgentComponent";
import CommonTextField from "modules/common/components/CommonTextField";
import ConfirmationDialog from "modules/common/components/ConfirmationDialog";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import CircularLoader from "modules/common/components/CircularLoader";
import CommonTable from "modules/common/components/CommonTable";
import DiscountCreateOrUpdateDialog from "./DiscountCreateOrUpdateDialog";
import { AUTH_CREATE_DISCOUNT, AUTH_UPDATE_DISCOUNT } from "config/authActions";

function ConfirmationDialogContent(props) {
  if (props.isActive) {
    // get inactive note
    return (
      <div className="px-3">
        <div className="mb-3">
          Are you sure you want to{" "}
          <strong>Inactivate this discount?</strong>
        </div>
        <CommonTextField
          label="Describe the reason to inactivate"
          value={props.inactiveNoteValue}
          required
          multiline
          rows={4}
          onChange={props.handleInactiveNoteChange}
        />
      </div>
    );
  } else {
    // confirm to active
    return (
      <div className="px-3">
        Are you sure? You want to{" "}
        <strong>Activate this discount?</strong>
      </div>
    );
  }
}

function InActiveNoteTooltip(props) {
  return (
    <span className="d-flex">
      Inactive
      <Tooltip title={props.discount.inActiveNote ? props.discount.inActiveNote : ""} placement="bottom">
        <HelpIcon color={props.discount.inActiveNote ? "inherit" : "disabled"} fontSize="small" className="ml-2" />
      </Tooltip>
    </span>
  )
}

export default class DiscountOverviewComponent extends Component {
  state = {
    discounts: [],
    discountCount: 0,
    isLoading: false,
    isOpenCreateOrUpdateDialog: false,
    selectedDiscount: {},
    isOpenConfirmationDialog: false,
    inactiveNoteValue: "",
  };

  componentDidMount() {
    this.getDiscounts();
  }

  openCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: true, selectedDiscount: {} });
  }

  closeCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: false, selectedDiscount: {} });
  }

  openUpdateDialog(discount) {
    this.setState({ selectedDiscount: discount, isOpenCreateOrUpdateDialog: true });

  }

  getNewlyCreatedData(discount) {
    if (discount) this.setState({ discounts: [discount, ...this.state.discounts], isOpenCreateOrUpdateDialog: false });
    console.log("getNewlyCreatedData -> ", discount);
  }

  getUpdatedData(discount) {
    let currentDiscounts = cloneObjectOrArray(this.state.discounts),
      index;

    index = currentDiscounts.findIndex(
      (sagent) => sagent.uid === discount.uid
    );

    if (index > -1) currentDiscounts[index] = discount;

    this.setState({
      discounts: currentDiscounts,
      isOpenCreateOrUpdateDialog: false,
      selectedDiscount: {}
    });
  }

  async getDiscounts(skip = 0) {
    let discounts = cloneObjectOrArray(this.state.discounts),
      discountCount = 0;

    if (skip === 0) this.setState({ isLoading: true });

    const apiResponse = await this.props.actionGetDiscounts(
      {
        pagination: {
          limit: 10,
          skip: skip
        }
      }
    );

    if (
      apiResponse &&
      apiResponse.count &&
      apiResponse.discounts
    ) {
      discounts = [...discounts, ...apiResponse.discounts];
      discountCount = apiResponse.count;
    }

    this.setState({ isLoading: false, discounts, discountCount });
    // this.props.actionStopLoading();
  }

  openConfirmationDialog(discount) {
    this.setState({
      selectedDiscount: discount,
      isOpenConfirmationDialog: true,
    });
  }

  closeConfirmationDialog() {
    this.setState({
      selectedDiscount: {},
      isOpenConfirmationDialog: false,
    });
  }

  async changeActiveStatus() {
    if (
      (this.state.selectedDiscount &&
        this.state.selectedDiscount.isActive &&
        this.state.inactiveNoteValue) ||
      (this.state.selectedDiscount &&
        !this.state.selectedDiscount.isActive)
    ) {
      let discounts = cloneObjectOrArray(this.state.discounts),
        index;

      this.setState({ isOpenConfirmationDialog: false }, () => {
        this.props.actionStartLoading();
      });

      const updatedDiscount = await this.props.actionUpdateDiscount({
        discountUid: this.state.selectedDiscount.uid,
        discount: {
          isActive: !this.state.selectedDiscount.isActive,
          inActiveNote: this.state.selectedDiscount.isActive
            ? this.state.inactiveNoteValue
            : null,
        },
      });

      if (updatedDiscount && updatedDiscount.uid)
        index = discounts.findIndex(
          (discount) => discount.uid === updatedDiscount.uid
        );

      if (index > -1) {
        discounts[index] = updatedDiscount;
        this.props.actionShowSnackbar({
          message: `Discount has been successfully ${this.state.selectedDiscount.isActive
              ? "Inactivated"
              : "Activated"
            }!`,
          type: "SUCCESS",
        });
      } else {
        this.props.actionShowSnackbar({
          message: "Something went wrong! Try again later..",
          type: "ERROR",
        });
      }

      this.setState({ discounts, selectedDiscount: {} }, () => {
        this.props.actionStopLoading();
      });
    }
  }

  handleInactiveNoteChange(e) {
    this.setState({ inactiveNoteValue: e.target.value });
  }


  render() {

    const TableHeaders = [
      { title: "UID", key: 'uid' },
      { title: "Discount", align: "center", buildCell: item => `${item.discount.value} ${item.discount.type == '_FLAT_' ? 'BDT (Flat)' : '%'}` },
      { title: "Status", align: "center", buildCell: item => <CommonChip color={item.isActive ? "success" : "error"} label={item.isActive ? "Active" : "Inactive"} /> },
      { title: "Origin", uppercase: true, buildCell: item => minifyArrayToString(item.flight.originAirportCodes) },
      { title: "Destination", uppercase: true, align: "center", buildCell: item => minifyArrayToString(item.flight.destinationAirportCodes) },
      { title: "Airline", uppercase: true, align: "center", buildCell: item => minifyArrayToString(item.flight.airlines) },
      { title: "Validity From", align: "center", buildCell: item => formatDate(item.validityFrom, 'DD MMM \'YY', null, false) },
      { title: "Validity To", align: "center", buildCell: item => formatDate(item.validityTo, 'DD MMM \'YY', null, false) },
      { title: "Created At", align: "center", buildCell: item => formatDate(item.createdAt, 'DD MMM \'YY, hh:mm A', null, false) },
    ]

    if(isAuthorized({
      privileges: this.props.app.privileges,
      featurePrivilege: AUTH_UPDATE_DISCOUNT
    })){
      TableHeaders.push({
        title: 'Actions',
        align: 'right',
        buildCell: item =>
        (
          <CommonSpeedDial
            items={[
              {
                name: "Edit", icon: <EditIcon />,
                onClick: e => {
                  e.stopPropagation()
                  this.openUpdateDialog(item)
                }
              },
              {
                name: item.isActive ? "Deactivate" : "Activate",
                icon: item.isActive ? <BlockIcon /> : <CheckIcon />,
                color: item.isActive ? "error" : "success",
                onClick: e => {
                  e.stopPropagation()
                  this.openConfirmationDialog(item)
                }
              },
            ]}
          />
        )
      })
    }

    const {
      discounts,
      discountCount,
      selectedDiscount,
      isLoading,
      isOpenCreateOrUpdateDialog,
      isOpenConfirmationDialog,
      inactiveNoteValue
    } = this.state;
    return (
      <div className="container">
        <Paper className="p-5">
          <div className="d-flex justify-between align-center mb-3">
            <h5 className="text-uppercase m-0">Discounts Overview</h5>
            {
              isAuthorized({
                privileges: this.props.app.privileges,
                featurePrivilege: AUTH_CREATE_DISCOUNT
              }) ? 
              <Button
                variant="contained"
                color="primary"
                // size="small"
                onClick={this.openCreateDialog.bind(this)}
              >
                <AddIcon className="mr-1" />
                Add Discount
              </Button> : ""
            }
            
          </div>
          <div className="w-100 overflow-x-auto">
            {isLoading ? (
              <CircularLoader />
            ) : (
              <CommonTable
                headers={TableHeaders}
                items={discounts}
                onLoadMore={this.getDiscounts.bind(this)}
                hasMore={discounts?.length < discountCount}
                noItemText="No item found"
              />
            )}
          </div>
          <ConfirmationDialog
            isOpen={isOpenConfirmationDialog}
            content={
              <ConfirmationDialogContent
                // operatorName={
                //     selectedDiscount &&
                //     selectedDiscount.meta &&
                //     `${selectedDiscount.meta.firstName} ${selectedDiscount.meta.lastName}`
                // }
                isActive={selectedDiscount.isActive}
                inactiveNoteValue={inactiveNoteValue}
                handleInactiveNoteChange={this.handleInactiveNoteChange.bind(
                  this
                )}
              />
            }
            onCancel={this.closeConfirmationDialog.bind(this)}
            onProceed={this.changeActiveStatus.bind(this)}
            proceedButtonText={
              selectedDiscount.isActive
                ? "Inactivate"
                : "Activate"
            }
          />
          <DiscountCreateOrUpdateDialog
            isOpen={isOpenCreateOrUpdateDialog}
            onClose={this.closeCreateDialog.bind(this)}
            discountUid={selectedDiscount && selectedDiscount.uid}
            onCreateNew={this.getNewlyCreatedData.bind(this)}
            onUpdate={this.getUpdatedData.bind(this)}
            {...this.props}
          />

        </Paper>
      </div>
    );
  }
}
