import React, { Fragment } from "react"
import packageJson from '../../package.json'
import ConfirmationDialog from "modules/common/components/ConfirmationDialog.jsx"
global.appVersion = packageJson.version

const semverGreaterThan = (versionA, versionB) => {
    // if(versionA && versionB && versionA === versionB) return true
    if(versionA && versionB){
        const versionsA = versionA.split(/\./g)

        const versionsB = versionB.split(/\./g)
        
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift())

            const b = Number(versionsB.shift())
            // eslint-disable-next-line no-continue
            if (a === b) continue
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b)
        }
    }
    return false
}

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isLatestVersion: false,
            isOpenConfirmationDialog: false,
            latestVersion: null
        }
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version
                const currentVersion = global.appVersion

                console.info("LATEST VERSION is", latestVersion, " and current app version is: ", currentVersion)

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)

                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`)
                    if(this.props.app && this.props.app.appUpdateCount){
                        this.setState({ isOpenConfirmationDialog: false, isLatestVersion: true, latestVersion })
                    } else {
                        this.setState({ isOpenConfirmationDialog: true, latestVersion })
                    }
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`)
                    // this.setState({ loading: false, isLatestVersion: true })
                    this.setState({ isLatestVersion: true, latestVersion }, () => {
                        if(this.props.app && this.props.app.appUpdateCount){
                            this.props.actionSetAppUpdateCount("RESET")
                        }
                    })
                }
            })

        // console.log("ServiceWorkerGlobalScope", navigator.serviceWorker)

        // if ('serviceWorker' in navigator) {
        //     window.addEventListener('load', function() {
        //         console.info("Entered into SW load event")
        //         navigator.serviceWorker.register('service-worker.js').then(function(reg) {
        //             reg.onupdatefound = function() {
        //                 console.info("SW reg onupdatefound")

        //                 var newSW = reg.installing;
        //                 newSW.onstatechange = function() {
        //                     if (newSW.state === 'waiting') {
        //                         console.info("FOUND AN SW WAITING to be INSTALLED")

        //                         // This assumes there's a button with id='skip-waiting-button' that
        //                         // users should click to get the new SW to activate immediately.
        //                         var button = document.querySelector('#update-new-version-btn');
        //                         button.addEventListener('click', function() {
        //                             console.log("BUTTON CLICKED")
        //                             newSW.postMessage('skipWaiting');
        //                         });
        //                         // Assume that 'display' is 'none' initially.
        //                         // button.style.display = 'inline';
        //                     }
        //                     // Handle whatever other SW states you care about, like 'active'.
        //                 };
        //             };
        //         })
        //     });
        // }
    }

    refreshCacheAndReload() {
        

        if (caches) {
            console.log('Clearing cache and hard reloading...')
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
                // for (let name of names) caches.delete(name)
                return Promise.all(names.map(function (cacheName) {
                    return caches.delete(cacheName).then(function () {
                        console.log("Cache with name " + cacheName + " is deleted");
                    });
                }))
            }).then(() => {
                window.location.reload(true)
            })
        } else {
            console.log('No cache found!! Hard reloading...')
            // delete browser cache and hard reload
            window.location.reload(true)
        }
        
        
    }

    closeConfirmationDialog(){
        this.setState({ isLatestVersion: true, isOpenConfirmationDialog: false })
    }

    updateApp(){
        this.setState({ isOpenConfirmationDialog: false, isLatestVersion: false }, () => {
            this.props.actionSetAppUpdateCount("INCREASE")
            this.refreshCacheAndReload()
        })
    }

    render() {
        const { loading, isLatestVersion, isOpenConfirmationDialog, latestVersion } = this.state
        return (
            <Fragment>
                
                {this.props.children({ loading, isLatestVersion, refreshCacheAndReload: this.refreshCacheAndReload.bind(this) })}

                <ConfirmationDialog
                    isOpen={isOpenConfirmationDialog}
                    content={(
                        <div className="py-4">New updates are available. Would you like to update your app { latestVersion ? `to v${latestVersion}` : "" } now?</div>
                    )}
                    onCancel={this.closeConfirmationDialog.bind(this)}
                    onProceed={this.updateApp.bind(this)}
                    proceedButtonText="Update"
                    header="New version Available"
                    proceedButtonId="update-new-version-btn"
                />
            </Fragment>
        )
    }
}