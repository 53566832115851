import React from "react"
import * as actions from "modules/discounts/actions"
import * as appActions from "actions"
import * as flightMarkupActions from "modules/flightMarkup/actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import AnimatedPageRoute from "modules/common/components/AnimatedPageRoute"
import DiscountOverviewComponent from "modules/discounts/components/DiscountOverviewComponent"

const DiscountContainer = props => <AnimatedPageRoute><DiscountOverviewComponent {...props}/></AnimatedPageRoute>

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign(actions, flightMarkupActions, appActions), dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountContainer))