import { combineReducers } from "redux"
import newAppReducer from "reducers/appReducer"
import authReducer from "modules/auth/reducers"
import flightSearchReducer from "modules/flightSearch/reducers"
import bookingPanelReducer from "modules/bookingPanel/reducers"

const appReducer = combineReducers({
    app: newAppReducer,
    auth: authReducer,
    flightSearch: flightSearchReducer,
    bookingPanel: bookingPanelReducer
})

const rootReducer = (state,action) => {
    if(action.type === "LOGOUT_REQUEST"){
        state = undefined
    }

    return appReducer(state,action)
}

export default rootReducer