import gql from "graphql-tag"

export default gql`
query getPromoCodes($pagination: PaginationInput, $filter: PromoCodeFilterInput) {
    getPromoCodes(pagination: $pagination, filter: $filter){
        statusCode
        message
        result{
            count
            promoCodes{
                id
                uid
                code
                platformType
                serviceType
                flight{
                    airlines
                    originAirportCodes
                    destinationAirportCodes
                    applyOn
                    isApplicableForIndividualTraveller
                }
                type
                amount
                usage{
                    limit
                    limitType
                    counter
                }
                validityFrom
                validityTo
                description
                isActive
                inActiveNote
                createdAt
                updatedAt
            }
        }
    }
}
`