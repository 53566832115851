import React, { Component, Fragment } from "react"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import { UiConfig } from "config"
import PropTypes from "prop-types"
import { formatDate } from "helper/formatDate";
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    inputRoot: {
        [`& label`]: {
            color: theme.palette.secondary.main
        }
    }
}))

function StyledPicker({ children }){
    const classes = useStyles()
    return children(classes)
}

export default class CustomDatePicker extends Component {

    handleDateChange(date){
        // if(this.props.onChange) this.props.onChange(moment(date,UiConfig.DATE_PICKER_DATE_FORMAT).format(this.props.format ? this.props.format : UiConfig.DATE_FORMAT))
        if(this.props.onChange) this.props.onChange(formatDate(date,this.props.format ?? UiConfig.DATE_FORMAT, UiConfig.DATE_PICKER_DATE_FORMAT, false))
    }

    render(){
        const { label, value, disablePast, className, minDate, onChange, errorText, ...restProps } = this.props
        
        return (
            <StyledPicker>
                {classes => (
                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                        <DatePicker
                            value={value}
                            autoOk
                            disableToolbar
                            disablePast={disablePast ? true : false}
                            minDate={minDate}
                            variant="inline"
                            format={UiConfig.DATE_PICKER_DATE_FORMAT}
                            // margin="normal"
                            // id="date-picker-inline"
                            label={label ? label : "Select date"}
                            // value={selectedDate}
                            onChange={date => this.handleDateChange(date)}
                            // KeyboardButtonProps={{
                            //     'aria-label': 'change date',
                            // }}
                            inputVariant="outlined"
                            className={`${classes.inputRoot} ${className ? className : ""}`}
                            // style={{ margin: 0 }}
                            // orientation="landscape"
                            error={Boolean(errorText)}
                            helperText={errorText}
                            {...restProps}
                        />
                        {/* <DatePicker
                            disableToolbar
                            variant="inline"
                            label="Only calendar"
                            helperText="No year selection"
                            value={value}
                            // onChange={handleDateChange}
                            onChange={date => this.handleDateChange(date)}
                        /> */}

                    </MuiPickersUtilsProvider>
                )}
            </StyledPicker>
        )
    }
}

CustomDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    disablePast: PropTypes.bool,
    onChange: PropTypes.func.isRequired
}