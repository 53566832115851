import React from "react"
import Paper from "@material-ui/core/Paper"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
// import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from "@material-ui/core/styles"
import { formatToBdtCurrency } from "helper"
import Skeleton from '@material-ui/lab/Skeleton'
import PaperWithHeader from "modules/common/components/PaperWithHeader"

const useStyles = makeStyles(theme => ({
    progressBar: {
        height: "0.75rem !important",
        width: "100%",
        borderRadius: "8px"
    }
}))

function StyledAccountStatus(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class AccountStatusComponent extends React.Component {
    state = {
        agent: {},
        isAgentLoading: true
    }

    componentDidMount(){
        this.getAccountInfo()
    }

    async getAccountInfo(){
        let agent = {}

        const accountResponse = await this.props.actionGetAgentAccountInfo()

        if(accountResponse && accountResponse.payment) agent = accountResponse

        this.setState({ agent, isAgentLoading: false })
    }

    render(){
        console.log("AccountStatusComponent", this.props)
        const { className } = this.props, { agent, isAgentLoading } = this.state;

        return (
            <StyledAccountStatus>
                {classes => (
                    <PaperWithHeader 
                        header="Account Status"
                        className={className}>
                        {/* <h5 className="text-uppercase"></h5> */}
                    
                        {/* <div className="d-flex align-center mb-3 mt-4">
                            {
                                isAgentLoading ?
                                <Skeleton variant="text" width="15rem"/> :
                                <Fragment>
                                    <small className="mr-2">{this.getBalancePercentage()}%</small>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={this.getBalancePercentage()}
                                        className={classes.progressBar}
                                    />
                                </Fragment>
                            }
                        </div> */}
                        
                        <Table aria-label="account-status-table">
                            <TableBody>
                                {/* <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="right">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            "Deposit"
                                        }
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell className="pl-0">Currency</TableCell>
                                    <TableCell align="right" className="pr-0">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            "BDT"
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell className="pl-0">Commission</TableCell> */}
                                    {/* <TableCell align="right" className="pr-0">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            <span>{ agent && agent.service && agent.service.flight && formatToBdtCurrency(agent.service.flight.commission) }%</span>
                                        }
                                    </TableCell> */}
                                </TableRow>
                                <TableRow>
                                    <TableCell className="pl-0">Total Deposit</TableCell>
                                    <TableCell align="right" className="pr-0">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            agent.payment && formatToBdtCurrency(agent.payment.totalDeposit)
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="pl-0">Total Spent</TableCell>
                                    <TableCell align="right" className="pr-0">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            agent.payment && formatToBdtCurrency(agent.payment.totalSpent)
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="pl-0">Current Balance</TableCell>
                                    <TableCell align="right" className="pr-0">
                                        {
                                            isAgentLoading ?
                                            <Skeleton variant="text" width="5rem"/> :
                                            agent.payment && formatToBdtCurrency(agent.payment.currentBalance)
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </PaperWithHeader>
                )}
            </StyledAccountStatus>
            
        )
    }
}