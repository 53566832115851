import gql from "graphql-tag"

export default gql`
    mutation createDiscount($discount: DiscountCreateInput!) {
    createDiscount(discount: $discount){
        statusCode
        message
        result{
            id
            uid
            operator{
                uid
                name
                userType
            }
            platformType
            serviceType
            flight{
                airlines
                exceptAirlines
                originAirportCodes
                destinationAirportCodes
                exceptOriginAirportCodes
                exceptDestinationAirportCodes
                applyOn
            }
            discount{
                type
                value
            }
            validityFrom
            validityTo
            isCompanyDiscount
            isActive
            inActiveNote
            isDisplayable
            createdAt
            updatedAt
        }
    }
}
`