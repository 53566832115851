import React, { Fragment, useState, useEffect } from "react"
import CommonTextField from "./CommonTextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Autocomplete from "@material-ui/lab/Autocomplete"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import countryFlagUrlPrefix from "config/countryFlagUrlPrefix";

const createStyles = makeStyles(theme => ({
    container: {
        width: "100%",

        "& .MuiInput-underline::before, & .MuiInput-underline::after": {
            borderBottom: "none !important",
            transition: "none" 
        },
        "& .MuiInputAdornment-positionStart":{
            marginRight: 14
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: 45
        }
    }
}))

const countryCodeList = [
    { name: "Bangladesh", code: "+880", shortName: "BD" },
    { code: "+973", name: "Bahrain", shortName: "BH" }
]

export default function PhoneNumberField(props){
    const { 
        label, id, numberValue, countryCodeValue, handleCountryCodeChange, 
        handleNumberChange, errorText, className, required
    } = props

    const classes = createStyles()

    const [ countryCodeObj, setCountryCodeObj ] = useState({})

    useEffect(() => {
        // console.log("countryCodeValue", countryCodeValue)
        if(countryCodeValue){
            let filteredCountryCodes = countryCodeList.filter(obj => obj.code === countryCodeValue)

            if(filteredCountryCodes && filteredCountryCodes.length) setCountryCodeObj(filteredCountryCodes[0])
        } else {
            setCountryCodeObj({})
        }
    }, [countryCodeValue])

    const onChangeCountryCode = (e,val) => {
        // console.log("onChangeCountryCode", val)
        if(val && val.code) handleCountryCodeChange(val.code)
        else handleCountryCodeChange("")
    }

    return (
        <CommonTextField
            label={label}
            id={id}
            value={numberValue}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Fragment>
                            {
                                countryCodeObj && countryCodeObj.shortName ?
                                <img 
                                    src={`${countryFlagUrlPrefix}${countryCodeObj.shortName.toLowerCase()}.png`}
                                    alt={countryCodeObj.shortName + " logo"}
                                    className="mr-1"
                                    width="16px"
                                /> : ""
                            }
                            <Autocomplete
                                id={id + "country-code-combo"}
                                value={countryCodeObj}
                                options={countryCodeList}
                                renderOption={option => (
                                    <React.Fragment>
                                        <small className="mr-1 mt-1">{option.shortName}</small>{option.code}
                                    </React.Fragment>
                                )}
                                getOptionLabel={option => option && option.code ? `${ option.code }` : ""}
                                renderInput={params => {
                                    params.inputProps.autoComplete = 'new-input';
                                    return <CommonTextField
                                        {...params}
                                        style={{ width: 70 }}
                                        autoComplete="off"
                                        
                                        variant="standard"
                                    />;
                                }}
                                disableClearable
                                onChange={onChangeCountryCode}
                            />
                        </Fragment>
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            type="tel"
            errorText={errorText}
            className={`${classes.container} ${className}`}
            required={required}
            onChange={handleNumberChange}
        />
    )
}

PhoneNumberField.propTypes ={
    label: PropTypes.string,
    id: PropTypes.string, 
    numberValue: PropTypes.any, 
    countryCodeValue: PropTypes.any, 
    handleCountryCodeChange: PropTypes.func, 
    handleNumberChange: PropTypes.func, 
    errorText: PropTypes.string, 
    required: PropTypes.bool,
    className: PropTypes.string 
}