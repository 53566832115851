import React, { Component } from "react";
import { Avatar, Paper } from "@material-ui/core";
import { formatDate, translateEnumToText, cloneObjectOrArray, formatToBdtCurrency } from "helper";
// import Chip from "@material-ui/core/Chip";
import CircularLoader from "modules/common/components/CircularLoader";
import CommonChip from "modules/common/components/CommonChip";
import CommonTable from "modules/common/components/CommonTable";
import ImageGallery from "modules/common/components/ImageGallery";

export default class NotificationOverviewComponent extends Component {
    state = {
        notifications: [],
        notificationCount: 0,
        isLoadingNotifications: true,
        filters: {}
    };

    componentDidMount() {
        this.getNotifications();
    }

    async getNotifications(skip = 0) {
        let notifications = cloneObjectOrArray(this.state.notifications), notificationCount = 0

        if(skip === 0) this.setState({ isLoadingNotifications: true })  

        const response = await this.props.actionGetNotifications({
            pagination: { limit: 10, skip: skip },
            filter: Object.keys(this.state.filters).length ? this.state.filters : null
        });
        
        if (response && response.inAppNotifications) {
            notifications = [ ...notifications, ...response.inAppNotifications ]
            notificationCount = response.count
        }

        this.setState({ notifications, notificationCount, isLoadingNotifications: false })
    }

    getStatusColor(type){
        if(type === "_DEBIT_") return "warning"
        else if(type === "_DEPOSIT_") return "success"
        else if(type === "_CREDIT_") return "primary"
        return "default"
    }

    render() {
        const { notifications, isLoadingNotifications, notificationCount } = this.state;

        const TableHeaders = [
            {
                title: 'Title',
                key: 'title',
                // buildCell: item => <span className="text-uppercase">{translateEnumToText(item.transactionType)}</span>,
            },
            {
                title: 'Content',
                buildCell: item => item.content
            },
            {
                title: 'Image',
                buildCell: item => <ImageGallery images={[ { src: item.image.url, name: item.title } ]} />,
            },
            // {
            //     title: "Type",
            //     align: "center",
            //     buildCell: item => (
            //         <CommonChip
            //             color={this.getStatusColor(item.type)}
            //             label={translateEnumToText(item.type)}
            //         />)
            // },
            // {
            //     title: 'Created At',
            //     buildCell: item => (
            //         item && item.createdAt ? formatDate(item.createdAt,"YYYY-MM-DD h:mm A", "+06:00") : "N/A"
            //     )
            // },
            // {
            //     title: 'Description',
            //     key: 'description'
            // },
        ]

        return (
                    <div className="container">
                        <Paper className="p-5">
                            <div className="d-flex justify-between align-center mb-3">
                                <h5 className="text-uppercase m-0">Notifications Overview</h5>
                            </div>

                            <div className="w-100 overflow-x-auto">
                                {
                                    isLoadingNotifications && !notifications.length ?
                                    <CircularLoader/> :
                                    <CommonTable
                                        headers={TableHeaders}
                                        items={notifications}
                                        onLoadMore={this.getNotifications.bind(this)}
                                        hasMore={notifications?.length < notificationCount}
                                        noItemText="No item found"
                                    />
                                }
                            </div>
                        </Paper>
                    </div>
        );
    }
}
