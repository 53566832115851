import client from "apolloClient"
import updateSubAgent from "gql/subAgents/mutations/updateSubAgent"

export default ({ subAgentUid, subAgent }) => async () => {
    try {
        const updateSubAgentResponse = await client.mutate({
            mutation: updateSubAgent,
            variables: { subAgentUid, subAgent }
        }).then(response => response.data.updateSubAgent)

        if(updateSubAgentResponse && (updateSubAgentResponse.statusCode === 200 || updateSubAgentResponse.statusCode === 201)){
            return updateSubAgentResponse.result
        }

        console.log("updateSubAgentResponse", updateSubAgentResponse)
    } catch (error) {
        console.error("Err in updateSubAgent", error)
    }
}