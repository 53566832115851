import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '.8rem',
        textTransform: 'uppercase',
    },
    iconRoot: {
        fontSize: '1rem',
        marginRight: '.2rem',
    }
}))

const CommonGoBack = ({history, pathname, onClick}) => {

    const classes = useStyles()

    const goBack = (event) => {
        if (event) event.preventDefault();
        if (onClick) return onClick();
        if (pathname) return history.push({ pathname });

        history.goBack();
    };

    return (
        <div>
            <Link
                href="#"
                color="secondary"
                className={`d-inline-flex align-center mb-3 ${classes.root}`}
                onClick={(e) => goBack(e)}>
                <ArrowBackIosIcon className={classes.iconRoot} />
                <strong>Go Back</strong>
            </Link>
        </div>
    )
}

export default CommonGoBack