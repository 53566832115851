import client from "apolloClient"
import getProfileSummary from "gql/common/queries/getProfileSummary"

export default () => async dispatch => {
    try {
        const profileResponse = await client.query({
            query: getProfileSummary
        }).then(response => response.data.getAgentProfile.result)

        if(profileResponse?.role?.privileges){
            dispatch({
                type: "SET_PROFILE_PRIVILEGES",
                payload: profileResponse.role.privileges.map(pri => pri.action.shortCode)
            })
        }

        return dispatch({
            type: "SET_PROFILE_INFORMATION",
            payload: profileResponse
        })
    } catch (error) {
        console.error("Err in getProfile", error)
    }
}