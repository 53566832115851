import React, { Component, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import CommonTextField from "modules/common/components/CommonTextField"
import CommonPasswordField from "modules/common/components/CommonPasswordField"
import Button from "@material-ui/core/Button"
import Grow from "@material-ui/core/Grow"
import { authToken, homePageAfterAuth } from "config"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import { isValidEmail, setADelay } from "helper"
import { getToken, wsLink } from "apolloClient"
import OtherLinks from "./OtherLinks"
import CommonLink from "modules/common/components/CommonLink"

const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.secondary.main
    },
    loginButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1.5rem"
    },
    errorText: {
        color: theme.palette.error.main,
        fontSize: "x-small",
        marginTop: "1rem",
        textAlign: "center"
    }
}))

function Login(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class LoginComponent extends Component {
    state = {
        email: "",
        password: "",
        isErrorOccured: false,
        errorMsg: "",
        isLoading: false,
        errorType: null
    }

    componentDidMount(){
        console.log("LoginComponent", this.props)
        this.checkPreviousError()
        this.props.actionStopLoading()
    }

    checkPreviousError(){
        const params = new URLSearchParams(window.location.search)
        const errorType = params.get("errorType")

        if(errorType) this.setState({ errorType })
    }

    handleChange(value,fieldName){
        this.setState({ [`${fieldName}`] : value, isErrorOccured: false })
    }

    validateAndSubmitData(event){
        if(event) event.preventDefault()

        let isValid = true
        const { email, password } = this.state
    
        if (!email) { isValid = false; this.setState({ errorMsg: 'Email or Password can not be empty' }); }
        else if(!isValidEmail(email)) { isValid = false; this.setState({ errorMsg: 'Your have entered an invalid email' }); }
        if (!password) { isValid = false; this.setState({errorMsg: 'Email or Password can not be empty'})}
      

        if(isValid) this.submitData()
        else this.setState({ isErrorOccured: true })
    }

    async submitData(){
        this.props.actionStartLoading()
        const deviceUuid = await this.props.actionGetDeviceUuid()

        if(deviceUuid){
            const loginResponse = await this.props.actionLoginAgent({
                auth: {
                    email: this.state.email,
                    deviceUuid
                },
                password: this.state.password
            })

            if(loginResponse && loginResponse.result && loginResponse.result.token){
                localStorage.setItem(authToken.key, loginResponse.result.token)
                // console.log("TOKEN ===========>", localStorage.getItem(authToken.key))
                wsLink.subscriptionClient.close(true)
                this.props.actionSetAuthTokens(loginResponse.result)
                this.props.history.push(homePageAfterAuth)
            } else if (loginResponse && loginResponse.message && loginResponse.message === "NOT_A_VALID_USER") {
                this.setState({ isErrorOccured: true, errorMsg: "We could not find your FlightLocal account"})
            } else if (loginResponse && loginResponse.message && loginResponse.message === "NOT_A_VERIFIED_USER") {
                this.setState({ isErrorOccured: true, errorMsg: "Your account has not been verified yet"})
            } else if (loginResponse && loginResponse.message && loginResponse.message === "NOT_AN_ACTIVE_USER") {
                this.setState({ isErrorOccured: true, errorMsg: "Your account is not active. Contact Support"})
            } else if (loginResponse && loginResponse.message && loginResponse.message === "USERNAME_OR_PASSWORD_MISMATCH") {
                this.setState({ isErrorOccured: true, errorMsg: "Username or Password mismatch"})
            } else if (loginResponse && loginResponse.message && loginResponse.message === "MAXIMUM_LOGIN_ATTEMPTS_IS_EXCEEDED") {
                this.setState({ isErrorOccured: true, errorMsg: "Too many failed login attempts. Contact Support"})
            }
            else {
                this.setState({ isErrorOccured: true, errorMsg: "Something went wrong!" })
            }
        }

        this.props.actionStopLoading()
    }

    render(){
        const { email, password, isErrorOccured, errorMsg, isLoading, errorType } = this.state

        return(
            <Login>
                {classes => (
                    <Paper className="p-5 my-5">
                        <form onSubmit={this.validateAndSubmitData.bind(this)}>
                            <div className="text-center">
                                {
                                    errorType ?
                                    <h4 className={classes.heading}>Please Login Again To Continue</h4> :
                                    <Fragment>
                                        <h4 className={classes.heading}>Welcome Back!</h4>
                                        <div>Please login to your account</div>
                                    </Fragment>
                                }
                            </div>
                            <div className="mt-5 mx-3">
                                <CommonTextField
                                    label="Email"
                                    value={email}
                                    type="email"
                                    className="mb-3"
                                    required
                                    disabled={isLoading}
                                    onChange={e => this.handleChange(e.target.value,"email")}
                                />
                                <CommonPasswordField
                                    label="Password"
                                    value={password}
                                    required
                                    disabled={isLoading}
                                    onChange={e => this.handleChange(e.target.value,"password")}
                                />
                                <Grow in={isErrorOccured}>
                                    <div className={classes.errorText}>
                                        {errorMsg ? errorMsg: 'Something went wrong, please try again later'}
                                    </div>
                                </Grow>
                                
                                <div className={classes.loginButtonContainer}>
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            size="large"
                                            className={classes.loginButton}
                                            fullWidth
                                            onClick={e => {
                                                e.preventDefault()
                                                this.props.history.push("/")
                                            }}
                                            >
                                            Register
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                            color="secondary"
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            className={classes.loginButton}
                                            fullWidth
                                            onClick={this.validateAndSubmitData.bind(this)}
                                            >
                                            Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                    
                                <div className="mt-4 text-center">
                                    <CommonLink
                                        href="/forgot-password-email"
                                        // color="secondary"
                                        // onClick={e => {
                                        //     e.preventDefault()
                                        //     this.props.history.push("/forgot-password-email")
                                        // }}
                                    >
                                        Forgot password?
                                    </CommonLink>
                                    {/* New Here?
                                    <Link
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault()
                                            this.props.history.push("/")
                                        }}
                                        className="ml-1"
                                    >
                                        Register Now
                                    </Link> */}
                                </div>
                                <OtherLinks/>
                            </div>
                        </form>
                    </Paper>
                )}
            </Login>
            
        )
    }
}