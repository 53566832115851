import React, { Fragment } from "react"
import CommonDialog from "./CommonDialog"
import Button from "@material-ui/core/Button"
import PropTypes from "prop-types"

export default function ConfirmationDialog(props){
    return (
        <CommonDialog
            header={props.header ? props.header : "Confirmation"}
            content={props.content}
            footer={(
                <Fragment>
                    <Button
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onProceed}
                        id={props.proceedButtonId}
                    >
                        { props.proceedButtonText ? props.proceedButtonText : "Proceed" }
                    </Button>
                </Fragment>
            )}
            removeFullScreenFromSm
            isOpen={props.isOpen}
            onClose={props.onCancel}
        />
    )
}

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.any.isRequired,
    onCancel: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired,
    proceedButtonText: PropTypes.string,
    header: PropTypes.string,
    proceedButtonId: PropTypes.string
}