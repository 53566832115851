import React from "react"
import { motion } from "framer-motion"

export default function AnimatedPageRoute(props){
    const pageVariants = {
        initial: {
            x: -20,
            opacity: 0,
        },
        in: {
            x: 0,
            opacity: 1
        },
        out: {
            x: 200,
            opacity: 0,
        }
    }

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
        >
            {props.children}
        </motion.div>
    )
}