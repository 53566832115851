import React, { Component } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Collapse from "@material-ui/core/Collapse"
import Autocomplete from '@material-ui/lab/Autocomplete'
import CommonTextField from './CommonTextField'

export default class CommonSelectComponent extends Component {
    state = {
        valueObj: null,
        isOpenDropdown: false
    }

    componentDidMount(){
        this.syncWithPrevValue()
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.value !== this.props.value) this.syncWithPrevValue()
    }

    openDropdown(){
        if(!this.props.disabled) this.setState({ isOpenDropdown: true })
    }

    closeDropdown(){
        this.setState({ isOpenDropdown: false })
    }

    syncWithPrevValue(){
        if(
            (this.props.value && this.props.valueObj && this.props.value !== this.props.valueObj.value) ||
            (this.props.value && !this.props.valueObj)
        ){
            let selectedValues = this.props.items.filter(item => item.value === this.props.value)

            if(selectedValues && selectedValues.length){
                this.setState({ valueObj: selectedValues[0] })
            }
        } else if (this.props.valueObj && this.props.valueObj.value && !this.props.value) {
            this.setState({ valueObj: null })
        }
    }

    handleChange(e,obj){
        this.setState({ valueObj: obj }, () => {
            if(this.props.onChange) this.props.onChange(obj && obj.value ? obj.value : null)
        })
    }

    render(){
        const { className, label, items, value, onChange, disabled, autoFocus,
            required, errorText, ...restProps } = this.props

        return (
            <Autocomplete
                open={this.state.isOpenDropdown}
                onOpen={this.openDropdown.bind(this)}
                onClose={this.closeDropdown.bind(this)}
                options={items}
                value={this.state.valueObj}
                renderOption={option => (
                    <React.Fragment>{ option.text }</React.Fragment>
                )}
                getOptionLabel={option => option && option.text ? `${ option.text }` : ""}
                renderInput={params => (
                    <CommonTextField 
                        {...params} 
                        label={label} 
                        errorText={errorText}
                        disabled={disabled}
                        required={required}
                        autoFocus={autoFocus}
                        // variant="standard" 
                    />
                )}
                disableClearable
                onChange={this.handleChange.bind(this)}
                {...restProps}
            />
        )
    }
}