import client from "apolloClient"
import subscribeAgentBalance from "gql/common/subscriptions/subscribeAgentBalance"

export default agentUid => async dispatch => {
    try {
        const subscriptionResult = await client.subscribe({
            query: subscribeAgentBalance,
            variables: { agentUid }
        })

        console.log("SUBSCRIBE AGENT BALANCE", subscriptionResult, " FOR ", { agentUid })
    } catch (error) {
        console.error("Err in subscribeAgentBalance", error)
    }
}