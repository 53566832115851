import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles(theme => ({
    wrapperContainer: {
        position: "relative"
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: 0,
        marginLeft: -9
    }
}))

export default function ButtonWithLoader (props){
    const classes = useStyles()

    const { variant, loading, children, progressClassname, progressSize, ...restProps } = props

    return (
        <div className={classes.wrapperContainer}>
            <Button
                variant={variant ? variant : "outlined"}
                disabled={loading}
                {...restProps}
            >
                {children}
            </Button>
            { 
                loading && 
                <CircularProgress 
                    size={progressSize ? progressSize : 18} 
                    className={`${classes.buttonProgress} ${progressClassname}`} /> 
            }
        </div>
    )
}