import React, { Component, Fragment } from "react"
import CommonDialog from "modules/common/components/CommonDialog"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import CommonTextField from "modules/common/components/CommonTextField"
import { cloneObjectOrArray, stringToObjRef } from "helper"
import { UiConfig } from "config"
import PhoneNumberField from "modules/common/components/PhoneNumberField";
import CommonSelectComponent from "modules/common/components/CommonSelectComponent";

function SubAgentCreateContent(props) {
    const { subAgent, subAgentError } = props

    const handleChange = (value, fieldType, value2, fieldType2) => {
        let currentSubAgent = cloneObjectOrArray(subAgent), currentError = cloneObjectOrArray(subAgentError)

        stringToObjRef(currentSubAgent, fieldType, value)
        stringToObjRef(currentError, fieldType, "")

        if (fieldType2 && value2) {
            stringToObjRef(currentSubAgent, fieldType2, value2)
            stringToObjRef(currentError, fieldType2, value2)
        }

        props.onFormChange({ subAgent: currentSubAgent, subAgentError: currentError })
    }

    // const handleChange = fieldName => event => {
    //     let currentSubAgent = cloneObjectOrArray(subAgent), currentError = cloneObjectOrArray(subAgentError);

    //     currentSubAgent[`${fieldName}`] = event.target.value
    //     if(currentError[`${fieldName}`]) currentError[`${fieldName}`] = ''

    //     props.onFormChange({ subAgent: currentSubAgent, subAgentError: currentError })
    // }

    return (
        <div className="p-2">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.meta.firstName}
                        label="First Name"
                        required
                        errorText={subAgentError.meta.firstName}
                        onChange={e => handleChange(e.target.value, "meta.firstName")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.meta.lastName}
                        label="Last Name"
                        required
                        errorText={subAgentError.meta.lastName}
                        onChange={e => handleChange(e.target.value, "meta.lastName")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PhoneNumberField
                        label="Phone Number"
                        id="outlined-start-adornment"
                        numberValue={subAgent.contact.account.phone}
                        countryCodeValue={subAgent.contact.account.countryCode}
                        handleNumberChange={e => handleChange(e.target.value, "contact.account.phone")}
                        handleCountryCodeChange={val => handleChange(val, "contact.account.countryCode")}
                        errorText={subAgentError.contact.account.phone}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.contact.account.email}
                        errorText={subAgentError.contact.account.email}
                        label="Email"
                        required
                        onChange={e => handleChange(e.target.value, `contact.account.email`)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonSelectComponent
                        value={subAgent.role}
                        className="w-100"
                        label="Role"
                        items={[
                            { text: "Staff", value: "_STAFF_" },
                            { text: "Administrator", value: "_ADMINISTRATOR_" }
                        ]}
                        required
                        errorText={subAgentError.role}
                        onChange={val => handleChange(val, "role")}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.swiftCode}
                        label="Swift Code"
                        type="number"
                        errorText={subAgentError.swiftCode}
                        onChange={handleChange("swiftCode")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.IFSC}
                        label="IFSC"
                        errorText={subAgentError.IFSC}
                        onChange={handleChange("IFSC")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        value={subAgent.city}
                        label="City"
                        errorText={subAgentError.city}
                        onChange={handleChange("city")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        value={subAgent.address}
                        label="Address"
                        errorText={subAgentError.address}
                        onChange={handleChange("address")}
                        multiline
                        rowsMax={3}
                    />
                </Grid> */}
            </Grid>
        </div>
    )
}

export default class SubAgentCreateOrUpdateDialog extends Component {
    subAgentObj = {
        meta: {
            firstName: '',
            lastName: '',
        },
        contact: {
            account: {
                countryCode: '+880',
                phone: '',
                email: '',
            }
        },
        role: '',
    }

    subAgentErrorObj = {
        meta: {
            firstName: '',
            lastName: '',
        },
        contact: {
            account: {
                countryCode: '+880',
                phone: '',
                email: '',
            }
        },
        role: '',
    }

    state = {
        subAgent: { ...this.subAgentObj },
        subAgentError: { ...this.subAgentErrorObj }
    }

    componentDidUpdate(prevProps) {
        // init inputs & errors
        if (!this.props.isOpen && prevProps.isOpen) this.setState({ subAgent: { ...this.subAgentObj }, subAgentError: { ...this.subAgentErrorObj } })

        // get full object to update
        if (this.props.subAgentUid && !prevProps.subAgentUid && this.props.isOpen) this.getSubAgentInfo(this.props.subAgentUid)
    }

    async getSubAgentInfo(subAgentUid) {

        this.props.actionStartLoading("Getting Sub Agent info. Please wait..");

        const apiResponse = await this.props.actionGetSubAgentForUpdate(subAgentUid);

        if (apiResponse && apiResponse.count) {
            let subAgent = cloneObjectOrArray(
                apiResponse.subAgents[0]
            );

            const tempRole = subAgent?.role?.name;

            if (tempRole) subAgent.role = tempRole;

            this.setState({ subAgent }, () => {
                this.props.actionStopLoading();
                console.log(
                    "apiResponse-> ",
                    apiResponse
                );
            });
        } else this.props.actionStopLoading();
    }

    validateAndSubmit() {
        let requiredFields = [
            'meta.firstName',
            'meta.lastName',
            'role',
            'contact.account.countryCode',
            'contact.account.phone',
            'contact.account.email',

        ], isValidInputs = true, currentErrors = cloneObjectOrArray(this.state.subAgentError)

        requiredFields.forEach(field => {
            if (!stringToObjRef(this.state.subAgent, field)) {
                stringToObjRef(currentErrors, field, UiConfig.REQUIRED_MESSAGE)
                isValidInputs = false
                console.log(`${field} is missing`)

            }
        })

        // requiredFields.forEach(field => {
        //     if(!this.state.subAgent[`${field}`]){
        //         currentErrors[`${field}`] = UiConfig.REQUIRED_MESSAGE
        //         isValidInputs = false
        //         console.log(`${field} is missing`)
        //     }
        // })

        if (isValidInputs) {
            let formattedBankAccount = cloneObjectOrArray(this.state.subAgent);
            if (this.props.subAgentUid) this.updateSubAgent(this.state.subAgent);
            else this.createSubAgent(formattedBankAccount);
        } else this.setState({ subAgentError: currentErrors })

    }

    async createSubAgent() {
        this.props.actionStartLoading()

        const newSubAgent = await this.props.actionCreateSubAgent({ subAgent: this.state.subAgent })

        if (newSubAgent?.statusCode === 200) {
            this.props.onCreateNew(newSubAgent.result)
            this.props.actionShowSnackbar({
                message: "Sub-agent has been added successfully",
                type: "SUCCESS"
            })
        } else if(newSubAgent?.message === "SUBAGENT_EMAIL_ALREADY_EXIST"){
            const currentError = cloneObjectOrArray(this.state.subAgentError);

            stringToObjRef(currentError, "contact.account.email", "This email is already associated with another account!");

            this.setState({ subAgentError: currentError })
        } else {
            this.props.actionShowSnackbar({
                message: "Something went wrong! Try again later..",
                type: "ERROR"
            })
        }

        this.props.actionStopLoading()
    }

    async updateSubAgent(subAgent) {
        this.props.actionStartLoading("Updating.. Please wait..");
        let formattedSubAgent = cloneObjectOrArray(subAgent);

        delete formattedSubAgent.id
        delete formattedSubAgent.uid

        const updatedSubAgent = await this.props.actionUpdateSubAgent({
            subAgentUid: this.props.subAgentUid,
            subAgent: formattedSubAgent,
        });

        if (updatedSubAgent) {
            this.props.actionShowSnackbar({
                message: "Sub-agent updated successfully!",
                type: "SUCCESS",
            });

            this.props.onUpdate(updatedSubAgent);
        } else {
            this.props.actionShowSnackbar({
                message: "Something went wrong!",
                type: "ERROR",
            });
        }

        this.props.actionStopLoading();
    }

    handleFormChange({ subAgent, subAgentError }) {
        this.setState({ subAgent, subAgentError })
    }

    render() {
        return (
            <CommonDialog
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                header={`${this.props.subAgentUid ? "Update" : "Add A"} Sub Agent`}
                content={(
                    <SubAgentCreateContent
                        subAgent={this.state.subAgent}
                        subAgentError={this.state.subAgentError}
                        onFormChange={this.handleFormChange.bind(this)}
                    />
                )}
                footer={(
                    <Fragment>
                        <Button
                            size="large"
                            color="primary"
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.validateAndSubmit.bind(this)}
                        >
                            Submit
                        </Button>
                    </Fragment>
                )}
            />
        )
    }
}