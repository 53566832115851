import React from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root: {
        [`& .MuiInputLabel-outlined`]: {
            color: theme.palette.secondary.main
        }
    }
}))

export default function CommonTextField(props){
    const { errorText, disableFullWidth, ...restProps } = props
    const classes = useStyles()

    return (
        <TextField
            classes={classes}
            variant="outlined"
            fullWidth={!disableFullWidth}
            error={Boolean(errorText)}
            helperText={errorText}
            { ...restProps }
        />
    )
}