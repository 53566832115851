import client from "apolloClient"
import getTodayFlightSaleForClient from "gql/dashboard/queries/getTodayFlightSaleForClient"

export default (filter) => async () => {
    try {
        const getTodayFlightSaleForClientResponse = await client.query({
            query: getTodayFlightSaleForClient,
            variables: filter
        }).then(response => response.data.getTodayFlightSaleForClient)
            console.log(getTodayFlightSaleForClient)
            return getTodayFlightSaleForClientResponse.result

    } catch (error) {
        console.error("Err in getTodayFlightSaleForClientResponse", error)
    }
}