import gql from "graphql-tag"

export default gql`
mutation createFlightMarkup($flightMarkup: FlightMarkupCreateInput!) {
    createFlightMarkup(flightMarkup: $flightMarkup){
        statusCode
        message
        result{
            id
            uid
            # operator{
            #     uid
            #     name
            #     userType
            # }
            title
            platformType
            airlines
            bookingClasses
            cabinType
            travelValidityFrom
            travelValidityTo
            fareFrom
            fareTo
            markup
            markupType
            applyOn
            applyFor
            fareType
            currency
            originAirportCodes
            destinationAirportCodes
            tripType
            isCompanyMarkup
            isActive
            inActiveNote
            createdAt
            updatedAt
        }
    }
}
`