import { authToken, ApiEndPoint } from "config"
import ApolloClient from "apollo-client"
import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { split, ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'

export const getToken = () => {
    const token = localStorage.getItem(authToken.key)

    return token ? token : ""
}

export const wsLink = new WebSocketLink({
    uri: ApiEndPoint().subscriptionUrl,
    options: {
        reconnect: true,
        connectionParams: async () => {
            const token = await localStorage.getItem(authToken.key)

            return ({
                authorization: token ? token : "",
            })
        }
    }
})

const cache = new InMemoryCache({
    addTypename: false
});

const httpLink = new HttpLink({
    uri: ApiEndPoint().url,
    // headers: {
    //     Authorization: getToken(),
    // },
})

const authLink = setContext((_, { headers }) => {
    const authorization = getToken();

    return {
        headers: {
            ...headers,
            authorization,
        }
    }
});

const combinedLink = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);

        // console.log("getMainDefinition ====>", definition, "token", {token: getToken()})

        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    authLink.concat(httpLink),
);

const globalErrorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if(graphQLErrors){
        graphQLErrors.forEach(err => {
            console.log("[GRAPHQL ERR] :", err)
            if(err.message === "UNAUTHENTICATED"){
                localStorage.removeItem(authToken.key)
                window.location.assign('/login?errorType=UNAUTHENTICATED')
            }
        })
    }
    if(networkError){
        console.error("[NETWORK ERR] :", networkError)
    }

    return forward(operation)
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
}

const client = new ApolloClient({ 
    link: ApolloLink.from([
        globalErrorHandler,
        combinedLink
    ]),
    cache,
    defaultOptions
});


export default client