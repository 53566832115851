import Paper from "@material-ui/core/Paper"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    container: {
        padding: "2rem 3rem 3rem"
    },
    headerContainer: {
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        padding: "1rem 3rem"
    },
    header: {
        margin: 0
    }
}))

export default function PaperWithHeader(props){
    const classes = useStyles()

    return (
        <Paper
            className={props.className}
        >
            <div
                className={classes.headerContainer}
            >
                <h5 className={classes.header}>{ props.header }</h5>
            </div>
            <div
                className={classes.container}
            >
                { props.children }
            </div>
        </Paper>
    )
}

PaperWithHeader.propTypes = {
    header: PropTypes.string.isRequired
}