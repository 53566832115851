import client from "apolloClient"
import getSubAgents from "gql/subAgents/queries/getSubAgentForUpdate"

export default (subAgentUid) => async () => {
    try {
        const getSubAgentsResponse = await client.query({
            query: getSubAgents,
            variables: {
                filter: {
                    uid: subAgentUid
                }
            }
        }).then(response => response.data.getSubAgents.result)

        return getSubAgentsResponse
        
    } catch (error) {
        console.error("Err in getSubAgents", error)
    }
}