import client from "apolloClient"
import loginAgent from "gql/auth/mutations/loginAgent"

export default ({ auth, password, device }) => async () => {
    try {
        // dispatch({ type: "LOGOUT_REQUEST" })

        const loginResponse = await client.mutate({
            mutation: loginAgent,
            variables: { auth, password, device }
        }).then(response => response.data.loginAgent)

        return loginResponse

        // console.log("loginResponse", loginResponse)
    } catch (error) {
        console.error("Err in loginAgent", error)        
    }
}