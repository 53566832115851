import { UiConfig } from "config"
import moment from "moment"
import dayjs from "dayjs";

//TODO: deprecate this function along with momentjs
export default (date,format,timeZone) => {
    if(date && !timeZone){
        return moment.utc(date).format(format ? format : UiConfig.DATE_FORMAT)
    } else if(date && timeZone){
        return moment.utc(date).utcOffset(timeZone).format(format ? format : UiConfig.DATE_FORMAT)
    }
    return date
}


export function formatDate (
    date,
    format=UiConfig.DATE_FORMAT,
    initialFormat=UiConfig.DATE_FORMAT,
    isUtc = false
) {
    if(date && !isUtc) return initialFormat ? dayjs(date,initialFormat).format(format) : dayjs(date).format(format);
    else if(date && isUtc){
        const utc = require('dayjs/plugin/utc')
        dayjs.extend(utc)

        return dayjs.utc(date).format(format)
    }

    return date;
}

export function isPast24Hours(time) {
    if (time) {
        const givenTime = dayjs(time);
       if (givenTime.isValid()) {
            const now = dayjs()
            return now.isAfter(givenTime.add('86400', 'second'));
        }
        else {
            throw Error(time+ ': not a valid time');
         }
    }
    return null
}