import React from "react"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import PropTypes from "prop-types"
import { stringToObjRef } from "helper"
import InfiniteScroll from "react-infinite-scroller"
import CircularLoader from "./CircularLoader"
import { makeStyles } from "@material-ui/core";
import InlineLoader from "./InlineLoader"

const useStyles = makeStyles(theme => ({
    textUppercase: {
        textTransform: 'uppercase',
    },
    tableRoot: {
        width: "100%",
        overflowX: "auto"
    },
    tableRow: {
        backgroundColor: theme.palette.secondary.main
    },
    tableHead: {
        color: theme.palette.background.paper,
        fontWeight: 800,
        fontSize: '13px',
    }
}))

function CommonTable({ headers, items, onLoadMore, hasMore, noItemText }) {
    const classes = useStyles();
    return (
        <InfiniteScroll
                pageStart={0}
                // threshold={650}
                initialLoad={false}
                loadMore={() => onLoadMore(items?.length)}
                hasMore={hasMore}
                loader={<InlineLoader key="loader" />}
            >
            <div className={classes.tableRoot}>
                <Table>
                {
                    headers && headers.length ?
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            {headers.map((header,i) => (
                                <TableCell
                                    key={i}
                                    className={classes.tableHead}
                                    align={header.align ? header.align : "left"}
                                >
                                    { header.title }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead> : ""
                }
                
                
                    <TableBody>
                        {
                            items && items.length ? items.map((item,index) => {
                                return (
                                    <TableRow 
                                        key={index}
                                        // hover
                                    >
                                        {
                                            headers.map((header,j) => {
                                                return (
                                                    <TableCell 
                                                        align={header.align ? header.align : "left"} 
                                                        className={header.uppercase ? classes.textUppercase : ''}
                                                        key={`${index}-${j}`}>
                                                        {
                                                            header.buildCell ? 
                                                            header.buildCell(item) : 
                                                            header.key && stringToObjRef(item,header.key) ?
                                                            stringToObjRef(item,header.key) : "N/A"
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            }) : 
                            <TableRow>
                                <TableCell colSpan={headers.length}>
                                    { noItemText ? noItemText : "No item found!"}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                
                
                </Table>
            </div>
        </InfiniteScroll>
    )
}

CommonTable.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        align: PropTypes.string,
        buildCell: PropTypes.func,
        key: PropTypes.string
    })).isRequired,
    items: PropTypes.array.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    noItemText: PropTypes.string
}

export default CommonTable