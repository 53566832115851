import { Popper } from "@material-ui/core";

const styles = (theme) => ({
    fullTextPopper: {
       width: "fit-content"
    }
});
 
export const fullTextPopper = function (props) {
    return <Popper {...props} style={styles.fullTextPopper} placement="bottom-start" />;
 };