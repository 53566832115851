import React, { Component, Fragment } from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import CommonTextField from "modules/common/components/CommonTextField"
import { UiConfig } from "config"
import Button from "@material-ui/core/Button"
import OtherLinks from "./OtherLinks"

const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.primary.main
    },
    loginButton: {
        width: "10rem",
    }
}))

function StyledPasswordInput(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class ForgotPasswordPasswordInputComponent extends Component {
    state = {
        password: "",
        confirmPassword: "",
        passwordError: "",
        confirmPasswordError: "",
        isPaaswordChanged: false,
        token: ""
    }
    
    componentDidMount(){
        this.checkIfValidToken()
    }

    async checkIfValidToken(){
        this.props.actionStartLoading()

        let isValid = false
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token')

        if(token){
            this.setState({ token })
            const validityResponse = await this.props.actionVerifyResetPasswordLink({ token })

            if(validityResponse && validityResponse.statusCode === 200) isValid = true
        }

        if(!isValid) this.props.history.push("/404")

        this.props.actionStopLoading()
    }

    handleChange(value, fieldName){
        this.setState({ [fieldName]: value, [`${fieldName}Error`]: "" })
    }

    validateAndSubmitData(event){
        if(event) event.preventDefault()

        let isValid = true

        if(!this.state.password){
            this.setState({ passwordError: UiConfig.REQUIRED_MESSAGE })
            isValid = false
        }

        if(!this.state.confirmPassword){
            this.setState({ confirmPasswordError: UiConfig.REQUIRED_MESSAGE })
            isValid = false
        }

        if(this.state.password && this.state.confirmPassword && this.state.password !== this.state.confirmPassword){
            isValid = false
            this.setState({ confirmPasswordError: "This password should match with your new password" })
        }

        if(isValid) this.submitData()
    }

    async submitData(){
        this.props.actionStartLoading()
        const passwordChangeResponse = await this.props.actionResetAgentPassword({
            token: this.state.token,
            newPassword: this.state.password,
            confirmPassword: this.state.confirmPassword
        })

        if(passwordChangeResponse && passwordChangeResponse.statusCode === 200){
            this.setState({ isPaaswordChanged: true })
        } else if(passwordChangeResponse && passwordChangeResponse.message === "PLEASE_ENTER_AT_LEAST_8_CHARACTER"){
            this.setState({ password: "", confirmPassword: "", passwordError: "Please enter at least 8 characters" })
        } else if(passwordChangeResponse && passwordChangeResponse.message === "NEW_PASSWORD_AND_CONFIRM_PASSWORD_NOT_SAME"){
            this.setState({ password: "", confirmPassword: "", passwordError: "Please enter at least 8 characters" })
        }
            
        this.props.actionStopLoading()
    }

    goToLogin(){
        this.props.history.push("/login")
    }

    render(){
        return (
            <StyledPasswordInput>
                {classes => (
                    <Paper className="p-5 mt-5">
                        {
                            this.state.isPaaswordChanged ?
                            <Fragment>
                                <div className="text-center mb-5">
                                    <h4 className={classes.heading}>Your password changed successfully!</h4>
                                    <div>You can now login to your account with your new password</div>
                                </div>
                                <div className="mt-5 d-flex justify-center">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className={classes.loginButton}
                                        onClick={this.goToLogin.bind(this)}
                                    >
                                        Go to Login
                                    </Button>
                                </div>
                            </Fragment> :
                            <Fragment>
                                <div className="text-center mb-5">
                                    <h4 className={classes.heading}>Reset Password</h4>
                                </div>
                                <form onSubmit={this.validateAndSubmitData.bind(this)}>
                                    <CommonTextField
                                        label="New Password"
                                        value={this.state.password}
                                        type="password"
                                        errorText={this.state.passwordError}
                                        required
                                        className="mb-3"
                                        onChange={e => this.handleChange(e.target.value,"password")}
                                    />
                                    <CommonTextField
                                        label="Re-enter new password"
                                        value={this.state.confirmPassword}
                                        type="password"
                                        errorText={this.state.confirmPasswordError}
                                        className="mb-5"
                                        required
                                        onChange={e => this.handleChange(e.target.value,"confirmPassword")}
                                    />
                                    <div className="d-flex justify-center">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            className={classes.loginButton}
                                            onClick={this.validateAndSubmitData.bind(this)}
                                        >Submit</Button>
                                    </div>
                                </form>
                            </Fragment>
                        }
                        <OtherLinks/>
                    </Paper>
                )}
            </StyledPasswordInput>
            
        )
    }
}