import React from 'react';
import * as actions from 'modules/notification/actions';
import * as appActions from 'actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import AnimatedPageRoute from 'modules/common/components/AnimatedPageRoute';
import NotificationOverviewComponent from 'modules/notification/components/NotificationOverviewComponent';

const NotificationOverviewContainer = (props) => (
	<AnimatedPageRoute>
		<NotificationOverviewComponent {...props} />
	</AnimatedPageRoute>
);

const mapStateToProps = (state) => ({
	...state,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(Object.assign({}, actions, appActions), dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationOverviewContainer));
