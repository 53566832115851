import client from "apolloClient"
import getFlightMarkups from "gql/flightMarkup/queries/getFlightMarkups"

export default ({ pagination, filter }) => async () => {
    try {
        const getFlightMarkupsResponse = await client.query({
            query: getFlightMarkups,
            variables: {
                pagination, filter
            }
        }).then(response => response.data.getFlightMarkups.result)

        return getFlightMarkupsResponse
        
    } catch (error) {
        console.error("Err in getFlightMarkups", error)
    }
}