import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    root: {
        fontWeight: "bold"
    }
})

function CommonLink ({ href, onClick, className, children, actDefault, ...restProps }) {
    const history = useHistory();
    const classes = useStyles()

    const handleClick = event => {
        event.preventDefault();
        if(onClick) onClick(event);
        if(href) history.push(href);
    } 

    return (
        <Link
            href={href ? href : ""}
            color="secondary"
            onClick={actDefault ? null : handleClick}
            className={`${classes.root} ${className ? className : ""}`}
            { ...restProps }
        >
            { children }
        </Link>
    )
}

CommonLink.propTypes = {
    children: PropTypes.any.isRequired,
    href: PropTypes.any,
    onClick: PropTypes.func
}

export default CommonLink;