import gql from 'graphql-tag'

export default gql`
    mutation createSubAgent($subAgent: SubAgentCreateInput!) {
    createSubAgent(subAgent: $subAgent){
        message
        statusCode
        result{
            id
            uid
            meta{
                firstName
                lastName
                # avatar{
                #     url
                #     name
                #     signedUrl
                # }
                company{
                    name
                    companyType
                    logo{
                        url
                        name
                    }
                    tradeLicenseNumber
                    webSiteUrl
                    address{
                        country
                        city
                        postalCode
                        line1
                        line2
                    }
                    documents{
                        url
                        name
                        type
                    }
                    country
                    email
                    countryCode
                    phone
                    timeZone
                }
                currency
            }
            contact{
                account{
                    countryCode
                    phone
                    isPhoneNumberVerified
                    email
                }
            }
            userType
            role{
                id
                uid
                name
                userType
                # privileges{
                #     feature{
                #         id
                #         name
                #     }
                #     action{
                #         id
                #         name
                #         shortCode
                #     }
                # }
                isActive
                inActiveNote
            }
            operator{
                id
                uid
                userType
                companyName
                name
            }
            isActive
            isVerified
            inActiveNote
        }
    }
}
`