import React from "react"
import { Redirect } from "react-router-dom"
import { authToken } from "config"
import { isAuthorized } from "helper"

export default function AuthRequired(props){
    if(localStorage.getItem(authToken.key)){
        if(
            !props.privilege ||
            (
                props.privilege && 
                props.app.privileges?.length &&
                isAuthorized({ 
                    privileges: props.app.privileges, 
                    featurePrivilege: props.privilege 
                })
            )
        ){
            return props.orComponent
        } else if(!props.app.privileges?.length){
            return "Please logout and login again!"
            // localStorage.removeItem(authToken.key)
            // return <Redirect to="/login"/>
        } else {
            return "You don't have permission to view this page!"
        }

        
    } else {
        return <Redirect to="/login"/>
    }
    
    // return props.orComponent
}