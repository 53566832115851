import client from "apolloClient"
import updateBankAccount from "gql/bankAccount/mutations/updateBankAccount"

export default ({ bankAccountUid, bankAccount }) => async () => {
    try {
        const updateBankAccountResponse = await client.mutate({
            mutation: updateBankAccount,
            variables: {
                bankAccountUid,
                bankAccount
            }
        }).then(response => response.data.updateBankAccount.result)

        return updateBankAccountResponse

    } catch (error) {
        console.error("Err in updateBankAccount", error)
    }
}