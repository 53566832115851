import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.background.paper,

        "&:hover": {
            backgroundColor: theme.palette.error.dark
        }
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.background.paper,

        "&:hover": {
            backgroundColor: theme.palette.warning.dark
        }
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.background.paper,

        "&:hover": {
            backgroundColor: theme.palette.success.dark
        }
    },
    fullHeight: {
        height: "100%"
    }
    // secondary: {
    //     color: theme.palette.background.paper
    // }
}))

export default function CommonButton(props){
    const { color, className, fullHeight, ...restProps } = props, classes = useStyles();

    let customClass, customColor;

    if(color === "error" || color === "warning" || color === "success" || color === "secondary"){
        customClass = classes[`${color}`]
    }
    
    if(color === "primary" || color === "secondary" || color === "default"){
        customColor = color
    }

    return (
        <Button
            color={customColor}
            className={`${className} ${customClass} ${fullHeight ? classes.fullHeight : ""}`}
            {...restProps}
        >
            { props.children }
        </Button>
    )
}

CommonButton.propTypes = {
    color: PropTypes.oneOf([ "primary", "secondary", "default", "error", "warning", "success" ]),
    className: PropTypes.string,
    
    // other props are same as 'Button' of Material UI
}