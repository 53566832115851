import gql from "graphql-tag"

export default gql`
    mutation createPromoCode($promoCode: PromoCodeCreateInput!) {
    createPromoCode(promoCode: $promoCode){
        statusCode
        message
        result{
            id
            uid
            code
            platformType
            serviceType
            flight{
                airlines
                originAirportCodes
                destinationAirportCodes
                applyOn
            }
            type
            amount
            usage{
                limit
                limitType
                counter
            }
            validityFrom
            validityTo
            description
            isActive
            inActiveNote
            createdAt
            updatedAt
        }
    }
}
`