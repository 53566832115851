import React from "react";
import * as actions from "modules/auth/actions";
import * as appActions from "actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AnimatedPageRoute from "modules/common/components/AnimatedPageRoute"
// import RegistrationComponent from "modules/auth/components/RegistrationComponent";
import RevisedRegisttation from "../components/RevisedRegistration";

const RegistrationContainer = (props) => <AnimatedPageRoute><RevisedRegisttation {...props} /></AnimatedPageRoute>;

const mapStateToProps = (state) => ({...state});

const mapDispatchToProps = (dispatch) => bindActionCreators(Object.assign({}, actions, appActions), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationContainer);
