import client from "apolloClient"
import getTotalFlightSaleForClient from "gql/dashboard/queries/getTotalFlightSaleForClient"

export default (filter) => async () => {
    try {
        const getTotalFlightSaleForClientResponse = await client.query({
            query: getTotalFlightSaleForClient,
            variables: filter
        }).then(response => response.data.getTotalFlightSaleForClient)
            console.log('getTotalFlightSaleForClient->', getTotalFlightSaleForClient)
            return getTotalFlightSaleForClientResponse.result

    } catch (error) {
        console.error("Err in getTotalFlightSaleForClientResponse", error)
    }
}