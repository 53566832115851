import client from "apolloClient"
import updateDiscount from "gql/discount/mutations/updateDiscount"

export default ({ discountUid, discount }) => async () => {
    try {
        const response = await client.mutate({
            mutation: updateDiscount,
            variables: { discountUid, discount }
        }).then(response => response.data.updateDiscount)

        if(response && (response.statusCode === 200 || response.statusCode === 201)){
            return response.result
        }

        console.log("updateDiscount", response)
    } catch (error) {
        console.error("Err in updateDiscount", error)
    }
}