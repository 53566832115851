import React, { Component } from "react"
import Paper from "@material-ui/core/Paper"
import CommonButton from "modules/common/components/CommonButton"
// import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { makeStyles } from "@material-ui/core/styles"
import CommonImage from "modules/common/components/CommonImage"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ReplayIcon from '@material-ui/icons/Replay'
import { ButtonGroup } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh"
    },
    appContainer: {
        padding: "3rem",

        [theme.breakpoints.up('sm')]: {
            margin: "2rem 15rem"
        }
    }
}))

function ErrorComponent(props){
    console.log("ErrorComponent", props)
    const classes = useStyles()

    const reloadApp = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name)
            })
        }
        // delete browser cache and hard reload
        window.location.reload(true)
    }

    return (
        <div className={classes.container}>
            <div className="container px-5 pt-5">
                <Paper className={classes.appContainer}>
                    <div className="d-flex flex-column justify-center mx-5">
                        <div className="d-flex justify-center">
                            <CommonImage
                                src="/images/bg/error-img.svg"
                                alt="error"
                                height="15rem"
                            />
                        </div>
                        <h6 className="mt-5 gray-text text-center text-uppercase text-lightweight">Whoops! An Error occured!!</h6>
                        <h4 className="gray-text text-center text-uppercase text-lightweight">Please Try again later</h4>
                        <div className="mt-5 d-flex justify-center">
                            <ButtonGroup>
                                <CommonButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => props.history.goBack()}
                                >
                                    <ArrowBackIosIcon className="mr-2" fontSize="small" />
                                    Go Back
                                </CommonButton>
                                <CommonButton
                                    color="primary"
                                    variant="contained"
                                    onClick={() => reloadApp()}
                                >
                                    <ReplayIcon className="mr-2" fontSize="small" />
                                    Reload
                                </CommonButton>
                            </ButtonGroup>
                        </div>
                        <div className="d-flex flex-column align-center mt-5">
                            {/* <CommonImage
                                height="30px"
                                src="images/logo/flight_local_logo.svg" 
                                alt="flight-local-logo-circle"
                                className="mt-3"
                            /> */}
                            <div className="py-3 px-2 text-center text-sm gray-text">
                                Copyright © { new Date().getFullYear() } FlightLocal.com. All rights reserved.
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default class GlobalErrorHandler extends Component {
    state = {
        isErrorOccured: false
    }

    componentDidUpdate(prevProps){
        const { location } = this.props

        if(
            location && prevProps.location && 
            location.pathname !== prevProps.location.pathname &&
            this.state.isErrorOccured
        ){
            this.setState({ isErrorOccured: false })
        }
    }

    componentDidCatch(error,info){
        console.error("Error occured :", error, "Info :", info)
        if(process.env.NODE_ENV === "production") this.setState({ isErrorOccured: true })
        // if(process.env.NODE_ENV === "production") window.location.reload()
    }

    render(){
        if(this.state.isErrorOccured) return <ErrorComponent {...this.props} />
        return this.props.children
    }
}