import client from "apolloClient"
import getFlightBookingManagementRequisitions from "gql/dashboard/queries/getFlightBookingManagementRequisitions"

export default ({ pagination, filter }) => async () => {
    try {
        const getFlightBookingManagementRequisitionsResponse = await client.query({
            query: getFlightBookingManagementRequisitions,
            variables: { pagination, filter }
        }).then(response => response.data.getFlightBookingManagementRequisitions.result)

        return getFlightBookingManagementRequisitionsResponse
        
    } catch (error) {
        console.error("Err in getFlightBookingManagementRequisitions", error)
    }
}