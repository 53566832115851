import { isUndefined } from "helper"

const initialState = {
    departureDate: null,
    returnDate: null,
    from: null,
    to: null,
    flightType: "_RETURN_",
    cabinType: "_ECONOMY_",
    passenger: {
        adults: 1,
        children: 0,
        infants: 0,
        childDobs: []
    },
    prefferedAirlines: [],
    multiCity: [
        { from: null, to: null, departureDate: null },
        { from: null, to: null, departureDate: null }
    ],
    feedItineraries: [],
    feedFilters: {},
    itineraryTotalCount: 0,
    selectedFeedFilters: {},
    isLoading: false,
    isSortingCheapest: true,
    selectedItineraries: [],
    sort: "_CHEAPEST_"
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'ADD_CITY_WITH_MULTI_CITY':
            // let currentMultiCity = JSON.parse(JSON.stringify(state.multiCity))
            return { ...state, multiCity: [ ...state.multiCity, { from: null, to: null, departureDate: null } ] }
            // return state.multiCity

        case 'UPDATE_MULTI_CITY_LIST':
            return { ...state, multiCity: payload }
            // return state.multiCity

        case 'SET_DEPARTURE_DATE':
            return { ...state, departureDate: payload }
            // return state.departureDate

        case 'SET_RETURN_DATE':
            return { ...state, returnDate: payload }
            // return state.returnDate

        case 'SET_FROM':
            return { ...state, from: payload }
            // return state.from

        case 'SET_TO':
            return { ...state, to: payload }
            // return state.to
            
        case 'SET_FLIGHT_TYPE':
            return { ...state, flightType: payload }
            // return state.flightType

        case 'SET_CABIN_TYPE':
            return { ...state, cabinType: payload }
            // return state.cabinType
            
        case 'SET_PASSENGER':
            return { ...state, passenger: {...state.passenger, ...payload} }
            // return state.passenger
        
        case "SET_DATE_OF_BIRTH":
            return { ...state, passenger: { ...state.passenger, childDobs: payload } }

        case 'SET_PREFFERED_AIRLINES':
            return { ...state, prefferedAirlines: payload }
            // return state.prefferedAirlines

        case 'SET_FEED_RESULT':
            return { ...state, feedItineraries: payload }
            // return state.feedItineraries

        case 'SET_ITINERARY_TOTAL_COUNT':
            return { ...state, itineraryTotalCount: payload }
            // return state.itineraryTotalCount

        case 'SET_FEED_FILTERS':
            return { ...state, feedFilters: payload }
            // return state.feedFilters

        case 'APPEND_FEED_RESULT':
            return { ...state, feedItineraries: [ ...state.feedItineraries, ...payload ] }
            // return state.feedItineraries

        case 'SET_SELECTED_FILTER_AND_SORT_THEN_INITIALIZE_RESULT':
            return { 
                ...state, 
                selectedFeedFilters: payload.updatedFilters ? { ...payload.updatedFilters } : { ...state.selectedFeedFilters }, 
                // isSortingCheapest: !isUndefined(payload.sort) ? payload.sort : state.isSortingCheapest, 
                sort: !isUndefined(payload.sort) ? payload.sort : state.sort,
                feedItineraries: [], 
                itineraryTotalCount: 0 
            };
            // return state.selectedFeedFilters
        
        case 'SET_FLIGHT_SEARCH_LOADING_STATE':
            return { ...state, isLoading: payload }
            // return state.isLoading

        // case 'INITIALIZE_FEED_RESULT':
        //     return { ...state, feedItineraries: [], itineraryTotalCount: 0 }
        case "INITIALIZE_FEED_RESULT_AND_FILTERS":
            return { 
                ...state, 
                selectedItineraries: [], 
                feedItineraries: [], 
                itineraryTotalCount: 0, 
                feedFilters: {}, 
                // isSortingCheapest: true, 
                sort: "_CHEAPEST_",
                selectedFeedFilters: {} 
            }

        case "SET_FEED_RESULT_AND_FILTERS":
            return { 
                ...state, 
                feedItineraries: [...payload.feedItineraries], 
                feedFilters: {...payload.feedFilters}, 
                itineraryTotalCount: payload.itineraryTotalCount 
            }

        case "SET_FEED_RESULT_AFTER_ADVANCED_SEARCH":
            return { 
                ...state, 
                feedItineraries: [...payload.feedItineraries], 
                itineraryTotalCount: payload.itineraryTotalCount 
            }

        case "SELECT_SORT_AND_INIT_FEED_RESULT":
            return { 
                ...state, 
                // isSortingCheapest: true, 
                sort: "_CHEAPEST_",
                selectedItineraries: [], 
                feedItineraries: [], 
                itineraryTotalCount: 0 
            }

        case "SET_SELECTED_ITINERARIES":
            return { ...state, selectedItineraries: [ ...payload ] }

        default:
            return state
    }
}