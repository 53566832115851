import gql from "graphql-tag"

export default gql`
    query getConfigurationForClient {
    getConfigurationForClient{
        statusCode
        message
        result{
            id
            uid
            platformType
            meta{
                companyName
                email
                countryCode
                phone
                address
            }
            auth{
                forgotPasswordAttemptCounter
                loginAttemptCounter
            }
            flight{
                defaultBookingStatus
                defaultTicketingStatus
                defaultSubAgentLimit
                defaultAgentCommission
                defaultCacheExpireTimeInSecond
                serviceCharge{
                    international{
                        bookingCharge
                        bookingCancellationCharge
                        ticketingCharge
                        ticketingVoidCharge
                        ticketingCancellationCharge
                        ticketingReissueCharge
                        ticketingRefundCharge
                    }
                    domestic{
                        bookingCharge
                        bookingCancellationCharge
                        ticketingCharge
                        ticketingVoidCharge
                        ticketingCancellationCharge
                        ticketingReissueCharge
                        ticketingRefundCharge
                    }
                }
                payment{
                    cardCharge
                    bkashCharge
                    applicableForCustomer
                }
            }
            createdAt
            updatedAt
        }
    }
}
`