import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles"
import { cloneObjectOrArray, isUndefined, stringToObjRef } from "helper";
import CommonTextField from "modules/common/components/CommonTextField";
import CommonSelectComponent from "modules/common/components/CommonSelectComponent";
import MultiAirportSearchBoxComponent from "modules/flightMarkup/components/MultiAirportSeachBoxComponent";
import CustomDatePicker from "modules/common/components/CustomDatePicker";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UiConfig } from "config";
import MultiAirlineSearchBox from "modules/flightMarkup/components/MultiAirlineSearchBoxComponent";

// import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CommonDialog from "modules/common/components/CommonDialog";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.down("sm")]: {
			padding: "0.5rem",
			marginBottom: "2rem"
		}
	},
	bottomMargin: {
		marginBottom: "1rem"
  },

  w100: {
    width: '100% !important',
  },

  formSubtitle: {
    fontSize: '1rem',
      margin: '1rem 0',
  },
  
}))

function StyledCompanyDiscountDetails(props){
  const classes = useStyles()
  return props.children(classes)
}

  
  function MultiAirlinesSelect(props) {
    //const [values, setvalues] = useState([]);
    const { className, label, options, showKey, value, errorText, ...restProps } = props;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
    const onMultiSelectChange = (event, values) => {
      props.onChange(values);
    };
    return (
      <Autocomplete
        multiple
        {...restProps}
        value={value}
        className={className}
        options={options}
        size="small"
        disableCloseOnSelect
        getOptionLabel={option => option.key.toUpperCase()}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {showKey ? `${option.key} -` : ""} {option.value}
          </React.Fragment>
        )}
        style={{ width: 500 }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={Boolean(errorText)}
            helperText={errorText}
            placeholder="All"
            fullWidth
          />
        )}
        onChange={onMultiSelectChange}
      />
    );
  }
  
function CreateForm(props) {
      const { discount, discountUid, discountError, onFormChange, ...restProps } = props;
    const item = discount;
      const classes = useStyles();
  
    const handleChange = (value, fieldName) => {
      let discountObj = cloneObjectOrArray(item),
        discountErrObj = cloneObjectOrArray(discountError);
  
      stringToObjRef(discountObj, fieldName, value);
      if (!isUndefined(stringToObjRef(discountErrObj, fieldName)))
        stringToObjRef(discountErrObj, fieldName, "");
  
      onFormChange({ discount: discountObj, discountError: discountErrObj });
    };
  
    return (
      <div className="px-5">
        <div>
          <h6 className={classes.formSubtitle}>
            Discount Configuration
          </h6>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CommonSelectComponent
                value={item.discount.type}
                required
                errorText={discountError.discount.type}
                className={classes.w100}
                label="Discount Type"
                items={[
                  { text: "Percentage", value: "_PERCENTAGE_" },
                  { text: "Flat", value: "_FLAT_" }
                ]}
                onChange={val => handleChange(val, "discount.type")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CommonTextField
                value={item.discount.value}
                required
                label="Discount Value"
                className={classes.w100}
                errorText={discountError.discount.value}
                onChange={e => handleChange(e.target.value, "discount.value")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomDatePicker
                disablePast
                label="Validity From"
                value={item.validityFrom}
                className={classes.w100}
                required
                errorText={discountError.validityFrom}
                onChange={date => handleChange(date, "validityFrom")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomDatePicker
                label="Validity To"
                value={item.validityTo}
                minDate={item.validityFrom}
                className={classes.w100}
                required
                error={discountError.validityTo}
                onChange={date => handleChange(date, "validityTo")}
              />
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <CommonSelectComponent
                value={item.platformType}
                className={classes.w100}
                label="Platform Type"
                disabled={discountUid}
                items={[
                  { text: "B2C", value: "_B2C_" },
                  { text: "B2B", value: "_B2B_" },
                  { text: "B2E", value: "_B2E_" },
                ]}
                required
                errorText={discountError.platformType}
                onChange={val => handleChange(val, "platformType")}
              />
            </Grid> */}
            <Grid item xs={12} lg={6}>
              <CommonSelectComponent
                value={item.serviceType}
                className={classes.w100}
                disabled={discountUid ? true : false}
                label="Service Type"
                items={[
                  { text: "Flight", value: "_FLIGHT_" },
                //   { text: "Visa", value: "_VISA_" },
                //   { text: "Package", value: "_PACKAGE_" },
                //   { text: "Hotel", value: "_HOTEL_" },
                ]}
                required
                errorText={discountError.serviceType}
                onChange={val => handleChange(val, "serviceType")}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
            {/* <FormControlLabel
              value="end"
                control={
                  <Checkbox
                    checked={item.isDisplayable}
                    onChange={e => handleChange(e.target.checked, "isDisplayable")}
                    color="primary"
                  />
                }
              label="Display Discount on Frontend"
              labelPlacement="end"
            /> */}
            </Grid>
          </Grid>
        </div>
        <div className={classes.bottomMargin}>
          <h6 className={classes.formSubtitle}>
            Module - Flight
          </h6>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <CommonSelectComponent
                value={item.flight.applyOn}
                className={classes.w100}
                label="Apply On"
                items={[
                  { text: "Base Fare", value: "_BASE_FARE_" },
                  { text: "Tax", value: "_TAX_" }
                ]}
                required
                errorText={discountError.flight.applyOn}
                onChange={val => handleChange(val, "flight.applyOn")}
              />
            </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
          <MultiAirlineSearchBox
                      value={item.flight.airlines}
                label="Airlines"
                errorText={discountError.flight.airlines}
                      //onChange={val => handleChange(val, "destinationAirportCodes")}
                      onInput={val => handleChange(val, "flight.airlines")}
                      {...restProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <MultiAirlineSearchBox
                value={item.flight.exceptAirlines}
                label="Exclude Airlines"
                errorText={discountError.flight.exceptAirlines}
                //onChange={val => handleChange(val, "destinationAirportCodes")}
                onInput={val => handleChange(val, "flight.exceptAirlines")}
                required
                {...restProps}
              />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.originAirportCodes}
                    label="Origin Airport Codes"
                    className={classes.w100}
                    errorText={discountError.flight.originAirportCodes}
                    onInput={val => handleChange(val, "flight.originAirportCodes")}
                    required
                {...restProps}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.destinationAirportCodes}
                    label="Destination Airport Codes"
                    className={classes.w100}
                    errorText={discountError.flight.destinationAirportCodes}
                    required
                //onChange={val => handleChange(val, "destinationAirportCodes")}
                onInput={val => handleChange(val, "flight.destinationAirportCodes")}
                {...restProps}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.exceptOriginAirportCodes}
                    label="Exclude Origin Airport Codes"
                    className={classes.w100}
                    errorText={discountError.flight.exceptOriginAirportCodes}
                //onChange={val => handleChange(val, "destinationAirportCodes")}
                onInput={val => handleChange(val, "flight.exceptOriginAirportCodes")}
                {...restProps}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.exceptDestinationAirportCodes}
                    label="Exclude Destination Airport Codes"
                    className={classes.w100}
                    errorText={discountError.flight.exceptDestinationAirportCodes}
                //onChange={val => handleChange(val, "destinationAirportCodes")}
                onInput={val => handleChange(val, "flight.exceptDestinationAirportCodes")}
                {...restProps}
                />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }  

export default class AddOrUpdateDiscountComponent extends Component {

    
    discountObj = {
        platformType: "_B2B_",
        serviceType: "",
        flight: {
          airlines: [],
          exceptAirlines: [],
            originAirportCodes: [],
          destinationAirportCodes: [],
          exceptOriginAirportCodes: [],
          exceptDestinationAirportCodes: [],
            applyOn: "",
        },
        discount: {
            type: "",
            value: "",
        },
        validityFrom: null,
        validityTo: null,
        isActive: false,
        // isDisplayable: true,
        // inActiveNote: "",
      };
    
      discountErrObj = {
        // platformType: "",
        serviceType: "",
        flight: {
            airlines: "",
            originAirportCodes: "",
            destinationAirportCodes: "",
            applyOn: "",
        },
        discount: {
            type: "",
            value: "",
        },
        validityFrom: null,
        validityTo: null,
      };
  
      state = {
        discountUid: null,
        discount: { ...this.discountObj },
        discountError: { ...this.discountErrObj },
        isLoadingDiscountDetails: false
      };

	// componentDidMount() {
    //     this.getDiscountDetails();
    // }

    componentDidUpdate(prevProps){
        // init inputs & errors
      if (!this.props.isOpen && prevProps.isOpen) {
        this.setState({ discount: { ...this.discountObj }, discountError: { ...this.discountErrObj } });
      }
    
        // get full object to update
      if (this.props.discountUid && !prevProps.discountUid && this.props.isOpen) this.getDiscountDetails(this.props.discountUid)
    }
    

	async getDiscountDetails(uid) {
            this.setState({ isLoadingDiscountDetails: true })

            const response = await this.props.actionGetDiscountForUpdate(uid);

            if (response) {
              let discount = cloneObjectOrArray(response)
              
              console.log("response--> ", response);
                discount.flight.airlines = discount.flight.airlines.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
              
                discount.flight.exceptAirlines = discount.flight?.exceptAirlines?.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
              
                discount.flight.exceptOriginAirportCodes = discount.flight?.exceptOriginAirportCodes?.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
              
                discount.flight.exceptDestinationAirportCodes = discount.flight?.exceptDestinationAirportCodes?.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
          
                discount.flight.originAirportCodes = discount.flight.originAirportCodes.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
          
                discount.flight.destinationAirportCodes = discount.flight.destinationAirportCodes.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
          
                // discount.bookingClasses = discount.bookingClasses.map((str, index) => ({key: str, value: str}))
				this.setState({ 
                    isLoadingDiscountDetails: false, 
                    discount: discount,
                    discountUid: uid,
                    discountError: { ...this.discountErrObj }
                })
			} else this.props.actionStopLoading()
    
		
    }
    
    validateAndSubmit(event) {
        if (event) event.preventDefault();
        console.log("SUBMITTED");
    
        let discountObj = cloneObjectOrArray(this.state.discount);
    
        if (this.isValidInputs()) {
          if (this.state.discountUid) this.updateCompanyDiscount(discountObj);
          else this.addCompanyDiscount(discountObj);
        }
    }
    
    isValidInputs() {
        let isValid = true,
          discountErrObj = cloneObjectOrArray(this.state.discountError);
    
        const requiredInputs = [
            "platformType",
            "serviceType",
            // "flight.airlines",
            // "flight.originAirportCodes",
            // "flight.destinationAirportCodes",
            // "flight.applyOn",
            "discount.type",
            "discount.value",
            "validityFrom",
            "validityTo",

        ];
    
        requiredInputs.forEach(input => {
          if (!stringToObjRef(this.state.discount, input)) {
            stringToObjRef(discountErrObj, input, UiConfig.REQUIRED_MESSAGE);
            console.log("Validation failed for ", input);
            isValid = false;
          }
        });
    
        const requiredArrayInputs = [
          "flight.airlines",
          'flight.originAirportCodes',
          "flight.destinationAirportCodes"
        ];
    
        requiredArrayInputs.forEach(input => {
         // console.log(stateReftoItem.length)
          if (!stringToObjRef(this.state.discount, input).length) {
            stringToObjRef(discountErrObj, input, UiConfig.REQUIRED_MESSAGE );
            console.log("Validation failed for ", input);
          isValid = false;
          }
        })
    
        // if (this.state.discount.fareFrom &&
        //     this.state.discount.fareTo &&
        //     parseInt(this.state.discount.fareFrom) > parseInt(this.state.discount.fareTo)) {
        //   stringToObjRef(discountErrObj, 'fareFrom', 'Fare From can not be greater than Fare To')
        //   isValid = false;
        // }
    
        this.setState({ discountError: discountErrObj });
    
        return isValid;
    }

    async addCompanyDiscount(discount) {

        let discountError = cloneObjectOrArray(this.state.discountError);
    
        let formattedDiscount = cloneObjectOrArray(discount);
        // if(!formattedDiscount.fareFrom) formattedDiscount.fareFrom = 0 // If fareFrom is empty, send default value as Zero
        // formattedDiscount.fareFrom = parseFloat(formattedDiscount.fareFrom);
        // formattedDiscount.fareTo = parseFloat(formattedDiscount.fareTo);
        // TODO: Check optional values
        // formattedDiscount.discount = parseFloat(formattedDiscount.discount);
        formattedDiscount.flight.airlines = formattedDiscount.flight.airlines.map(item => item.meta.iataCode);
        formattedDiscount.flight.exceptAirlines = formattedDiscount.flight?.exceptAirlines?.map(item => item.meta.iataCode);
        // formattedDiscount.bookingClasses = formattedDiscount.bookingClasses.map(
        //   item => item.key
        // );
        formattedDiscount.flight.originAirportCodes = formattedDiscount.flight.originAirportCodes.map(
          item => item.meta.iataCode
        );
      formattedDiscount.flight.destinationAirportCodes = formattedDiscount.flight.destinationAirportCodes.map(
          item => item.meta.iataCode
      );
      formattedDiscount.flight.exceptOriginAirportCodes = formattedDiscount.flight?.exceptOriginAirportCodes?.map(
        item => item.meta.iataCode
      );
      formattedDiscount.flight.exceptDestinationAirportCodes = formattedDiscount.flight?.exceptDestinationAirportCodes?.map(
        item => item.meta.iataCode
      );
        console.log("formattedDiscount -> ", formattedDiscount);
        this.props.actionStartLoading("Adding Discount, please wait..");
        try {
          const newDiscountResponse = await this.props.actionCreateDiscount(
            { discount: formattedDiscount}
           );
       
          console.log("newDiscountResponse-> ", newDiscountResponse);
          
       
           if (newDiscountResponse) {
             this.props.actionShowSnackbar({
               message: "Discount added successfully!",
               secondaryMessage:
                 "Please activate your discount when you are ready",
               type: "SUCCESS"
             });
             this.props.onCreateNew(newDiscountResponse.result);
           } else if (newDiscountResponse.message === "FAREFROM_NOT_GREATER_THAN_FARETO") {
             discountError.fareFrom = "Fare From can not be greater than Fare To"
           } else {
             this.props.actionShowSnackbar({
               message: "Something went wrong! Please try again later..",
               type: "ERROR"
             });
           }
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({ discountError })
          this.props.actionStopLoading();
        }
        

      }
    
      async updateCompanyDiscount(discount) {
        let formattedDiscount = cloneObjectOrArray(discount);
        // if(!formattedDiscount.fareFrom) formattedDiscount.fareFrom = 0 // If fareFrom is empty, send default value as Zero
        // formattedDiscount.fareFrom = parseFloat(formattedDiscount.fareFrom);
        // formattedDiscount.fareTo = parseFloat(formattedDiscount.fareTo);
        // // TODO: Check optional values
        // formattedDiscount.discount = parseFloat(formattedDiscount.discount);
        formattedDiscount.flight.airlines = formattedDiscount.flight.airlines.map(item => item.meta.iataCode);
        formattedDiscount.flight.exceptAirlines = formattedDiscount.flight?.exceptAirlines?.map(item => item.meta.iataCode);
        // formattedDiscount.bookingClasses = formattedDiscount.bookingClasses.map(
        //   item => item.key
        // );
        formattedDiscount.flight.originAirportCodes = formattedDiscount.flight.originAirportCodes.map(
          item => item.meta.iataCode
        );
        formattedDiscount.flight.destinationAirportCodes = formattedDiscount.flight.destinationAirportCodes.map(
          item => item.meta.iataCode
        );
        formattedDiscount.flight.exceptOriginAirportCodes = formattedDiscount.flight?.exceptOriginAirportCodes?.map(
          item => item.meta.iataCode
        );
        formattedDiscount.flight.exceptDestinationAirportCodes = formattedDiscount.flight?.exceptDestinationAirportCodes?.map(
          item => item.meta.iataCode
        );
    
        delete formattedDiscount.id;
        delete formattedDiscount.uid
        delete formattedDiscount.serviceType
        delete formattedDiscount.platformType
        delete formattedDiscount.createdAt
        delete formattedDiscount.updatedAt
    
        this.props.actionStartLoading("Updating Company Discount, please wait..");
    
        const updatedDiscount = await this.props.actionUpdateDiscount({
          discount: formattedDiscount,
          discountUid: this.state.discountUid
        });
    
        if (updatedDiscount) {
          this.props.actionShowSnackbar({
            message: "Discount updated successfully",
            type: "SUCCESS"
          });
          this.props.onUpdate(updatedDiscount);
        } else {
          this.props.actionShowSnackbar({
            message: "Discount update failed",
            type: "ERROR"
          });
        }
        this.props.actionStopLoading();
      }
    
      handleFormChange({ discount, discountError }) {
        this.setState({ discount, discountError });
      }


	render() {
		const { ...restProps } = this.props;
        const { discount, isLoadingDiscountDetails, discountUid, discountError } = this.state;


		// if(isLoadingDiscountDetails) return <InlineLoader/>

        return (
                <CommonDialog
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                    header={`${this.props.discountUid ? "Update" : "Add A"} Discount`}
                    content={(
                        <CreateForm
                            discount={discount}
                            discountUid={discountUid}
                            discountError={discountError}
                            onFormChange={this.handleFormChange.bind(this)}
                            {...this.props}
                        />
                    )}
                    footer={(
                        <Fragment>
                            <Button
                                size="large"
                                color="primary"
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.validateAndSubmit.bind(this)}
                            >
                                Submit
                            </Button>
                        </Fragment>
                    )}
                />

		);
	}
}
