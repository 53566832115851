import client from "apolloClient"
import getPromoCodes from "gql/promocode/queries/getPromoCodes"

export default (uid) => async () => {
    try {
        const response = await client.query({
            query: getPromoCodes,
            variables: {
                filter: { uid }
            }
        }).then(response => response.data.getPromoCodes.result.promoCodes[0])

        return response
        
    } catch (error) {
        console.error("Err in getPromoCodes", error)
    }
}