const initialState = {
    loading: {},
    snackbar: {
        message: "",
        type: "",
        secondaryMessage: ""
    },
    isTouchDevice: true,
    isLoadingApp: false,
    profile: {},
    config: {},
    appUpdateCount: 0,
    privileges: []
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'TOGGLE_LOADING':
            return { ...state, loading: payload }

        case 'SHOW_SNACKBAR_MESSAGE':
            return { ...state, snackbar: { message: payload && payload.message, type: payload && payload.type, secondaryMessage: payload && payload.secondaryMessage } }

        case 'HIDE_SNACKBAR_MESSAGE':
            return { ...state, snackbar: { message: "", type: "", secondaryMessage: "" } }

        case 'SET_IS_TOUCH_DEVICE_STATUS':
            return { ...state, isTouchDevice: payload }

        case 'TOGGLE_APP_LOADER':
            return { ...state, isLoadingApp: payload }

        case 'SET_PROFILE_INFORMATION':
            return { ...state, profile: { ...payload } }

        case 'SET_CONFIGURATION':
            return { ...state, config: { ...payload } }

        case 'RESET_APP_UPDATE_COUNT':
            return { ...state, appUpdateCount: 0 }
        
        case 'INCREASE_APP_UPDATE_COUNT':
            return { ...state, appUpdateCount: state.appUpdateCount ? state.appUpdateCount + 1 : 1 }

        case 'SET_PROFILE_PRIVILEGES':
            return { ...state, privileges: payload }
    
        default:
            return state
    }
}