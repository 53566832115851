import React, { Component } from "react"
import CommonAsyncSearchBox from "modules/common/components/CommonAsyncSearchBox"

export default class MultiAirlineSearchBox extends Component {
    initAirlines = [
        { fullName: "All", shortName: "All", meta: { iataCode: "_ALL_" } },
        { fullName: "Emirates Airline", shortName: "Emirates Airline", meta: { iataCode: "EK" } },
        { fullName: "Qatar Airways", shortName: "Qatar Airways", meta: { iataCode: "QR" } },
        { fullName: "Bangladesh Biman Airline", shortName: "Bangladesh Biman Airline", meta: { iataCode: "BG" } },
        { fullName: "Thai Airways", shortName: "Thai Airways", meta: { iataCode: "TG" } },
        { fullName: "Saudi Arabian Airlines", shortName: "Saudi Arabian Airlines", meta: { iataCode: "SV" } }
    ]
    
    state = {
        airlines: [ ...this.initAirlines ],
        isLoadingAirlines: false,
        isOpenDropdown: false,
        query: ""
    }

    searchInputTimer = null;

    componentWillUnmount(){
        if(this.searchInputTimer) clearTimeout(this.searchInputTimer)
    }

    openDropdown(){
        this.setState({ isOpenDropdown: true })
    }

    closeDropdown(){
        this.setState({ isOpenDropdown: false }, () => {
            if(
                this.state.query && 
                this.state.query.length === 3 &&
                this.props.value && 
                this.props.value.meta && 
                this.props.value.meta.iataCode && 
                this.state.query.toLowerCase() !== this.props.value.meta.iataCode.toLowerCase()
            ){
                this.props.onInput({ meta: { iataCode: this.state.query.toUpperCase() } })
            } else if(
                this.state.query && 
                this.props.value && 
                this.props.value.meta && 
                this.props.value.meta.iataCode && 
                this.state.query.toLowerCase() !== this.props.value.meta.iataCode.toLowerCase()
            ) {
                this.props.onInput(null)
            }
        })
    }

    async getAirlines(searchQuery){
        if(!searchQuery || !this.state.isOpenDropdown) {
            this.setState({ isLoadingAirlines: false, airlines: [ ...this.initAirlines ] })
            return;
        } else if(searchQuery && searchQuery.length < 2){
            this.setState({ isLoadingAirlines: false, airlines: [] })
            return;
        }

        const airlines = await this.props.actionGetAirlines(searchQuery)

        this.setState({ isLoadingAirlines: false, airlines })
    }

    handleInputChange(event,value){
        console.log("input", JSON.stringify(value))
        this.props.onInput(value)
    }

    onInputChange(e,val){
        if(val && !this.state.isLoadingAirlines && this.state.isOpenDropdown) this.setState({ isLoadingAirlines: true })

        this.setState({ query: val })

        if(this.searchInputTimer) clearTimeout(this.searchInputTimer)
        this.searchInputTimer = setTimeout(() => { this.getAirlines(val) }, 800)
    }

    render(){
        const { label, value, errorText, className } = this.props

        return (
            <CommonAsyncSearchBox
                size="small"
                value={value}
                multiple={true}
                open={this.state.isOpenDropdown}
                onOpen={this.openDropdown.bind(this)}
                onClose={this.closeDropdown.bind(this)}
                options={this.state.airlines}
                noOptionsText="No Airlines Found!"
                loading={this.state.isLoadingAirlines}
                errorText={errorText}
                label={label}
                renderOption={option => (
                    <React.Fragment>
                        { option.shortName } ({ option.meta && option.meta.iataCode })
                    </React.Fragment>
                )}
                getOptionLabel={option => option && option.meta && option.meta.iataCode ? `${option.meta.iataCode}` : ""}
                // onOpen={() => this.getAirlines()}
                onChange={this.handleInputChange.bind(this)}
                onInputChange={this.onInputChange.bind(this)}
                className={className}
            />
        )
    }
}
