import React from "react"
import CircularLoader from "modules/common/components/CircularLoader"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
    }
})

export default function InlineLoader({ width }){
    const classes = useStyles()
    return(
        <div className={classes.container}>
            <CircularLoader
                width={width}
            />
        </div>
    )
}