import React from "react"
import * as actions from "modules/auth/actions"
import * as appActions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import NotFound from "modules/notFound/components/NotFoundComponent"

const NotFoundContainer = props => <NotFound {...props}/>

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({}, actions, appActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundContainer)