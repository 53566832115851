const theme = {
    DATE_FORMAT: "YYYY-MM-DD",
    /* COLORS */
    // primaryColor: "#f36f32",
    // primaryColor: "#192a56",
    // primaryColor: "#ffa801",
    // primaryColor: "#3499b2", //prev
    primaryColor: "#FECC45",
    // primaryColor: "#0356c4",
    // secondaryColor: "#ffde34",
    secondaryColor: "#00276c",
    //warningColor: "#f8a826",
    warningColor: "#ffa700",
    accentColor: "#0356c4",
    errorColor: "#e74d4d",
    successColor: "#27ae60",
    fontColor: '#00083c',
    backgroundColor: '#f9f9f9',
    secondaryBackgroundColor: "#ffffff",
    borderColor: "rgba(0, 0, 0, 0.23)",
    smBorderColor: "rgba(0, 0, 0, 0.1)",
    
    /* FONTS */
    fontSize: 14,
    fontFamily: "'Poppins', sans-serif",
    navHeight: 48,
    
    /* GRIDS */
    smWidth: "961px",
    mdWidth: "1281px",
    
    /* BOX SHADOW */
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    smBoxShadow: "0px 1px 2px -0.5px rgba(0,0,0,0.2), 0px 2px 2.5px 0px rgba(0,0,0,0.14), 0px 0.5px 5px 0px rgba(0,0,0,0.12)",
    
    /* BORDER RADIUS */
    borderRadius: "5px",
    smBorderRadius: "3px",
    lgBorderRadius: "8px"
}

export default theme