import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container';
import CommonImage from 'modules/common/components/CommonImage';
import Grid from '@material-ui/core/Grid';
import AppFooter from 'modules/common/components/AppFooter';
import { makeStyles } from '@material-ui/core/styles';
import { authToken, homePageAfterAuth } from 'config';
import PublicPageComponents from 'modules/common/components/PublicPageComponents';

const useStyles = makeStyles((theme) => ({
	container: {
		// marginTop: "0.5rem",
		// marginLeft: "3rem",
		backgroundRepeat: 'no-repeat',
		minHeight: '60%',
		backgroundPosition: 'center left',

		[theme.breakpoints.up('xs')]: {
			minHeight: '60%',
		},
		// [theme.breakpoints.up('sm')]: {
		//     backgroundImage: "url('/images/bg/man_browsing_internet.svg')"
		// }
	},
	toolbar: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center',
		},
	},
}));

function ElevationScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
	window: PropTypes.func,
};

export default function AuthLayout(props) {
	const classes = useStyles();

	useEffect(() => {
		// if logged in user, go to agent home page
		if (localStorage.getItem(authToken.key)) props.history.push(homePageAfterAuth);
	}, []);

	return (
		<React.Fragment>
			<CssBaseline />
			<ElevationScroll {...props}>
				<AppBar color="inherit">
					<Toolbar className={classes.toolbar}>
						<CommonImage
							height="44px"
							src="images/logo/flight_local_logo.svg"
							alt="flight-local-logo-circle"
						/>
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			<Toolbar />
			<Container className={classes.container}>
				<Grid
					container
					// spacing={2}
					justify="flex-end"
					alignItems="center">
				
					<Grid item xs={12} sm={12} md={6} lg={7}>
						<BannerLayout />
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={5}>
						{props.children}
					</Grid>
				</Grid>
			</Container>
			<PublicPageComponents />
			<AppFooter />
		</React.Fragment>
	);
}

function BannerLayout(props) {
	const useStyles = makeStyles((theme) => ({
		container: {
			color: 'white',
            padding: '5.9rem 20% 5.9rem 3.2rem',
            backgroundImage: "url('/images/bg/bg_home.jpg')",

            [theme.breakpoints.down('sm')]: {
                display: 'none',
			},

			'& h1': {
				fontSize: '2.6rem',
				lineHeight: '3rem',
				marginBottom: '25px',
			},

			'& p': {
				fontSize: '1rem',
			},
		},
	}));

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<h1>Flight Local is the one-tap solution for all your travel concerns</h1>
			<p>
				We are the largest B2B portal for travel agencies to deal with the best resources beneficial to visa
				Support, tickets, holiday packages, tour Packages, Hotels & many more.
			</p>
		</div>
	);
}
