import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
  cloneObjectOrArray,
  translateEnumToText,
  formatDate,
  minifyArrayToString,
  isAuthorized
} from "helper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CommonChip from "modules/common/components/CommonChip";
import CommonSpeedDial from "modules/common/components/CommonSpeedDial";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
// import createOrUpdateSubAgentComponent from "./createOrUpdateSubAgentComponent";
import CommonTextField from "modules/common/components/CommonTextField";
import ConfirmationDialog from "modules/common/components/ConfirmationDialog";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import CircularLoader from "modules/common/components/CircularLoader";
import CommonTable from "modules/common/components/CommonTable";
import DiscountCreateOrUpdateDialog from "./PromoCodeCreateOrUpdateDialog";
import { AUTH_CREATE_PROMOCODE, AUTH_UPDATE_PROMOCODE } from "config/authActions";

function ConfirmationDialogContent(props) {
  if (props.isActive) {
    // get inactive note
    return (
      <div className="px-3">
        <div className="mb-3">
          Are you sure you want to{" "}
          <strong>Inactivate this promocode?</strong>
        </div>
        <CommonTextField
          label="Describe the reason to inactivate"
          value={props.inactiveNoteValue}
          required
          multiline
          rows={4}
          onChange={props.handleInactiveNoteChange}
        />
      </div>
    );
  } else {
    // confirm to active
    return (
      <div className="px-3">
        Are you sure? You want to{" "}
        <strong>Activate this promocode?</strong>
      </div>
    );
  }
}

function InActiveNoteTooltip(props) {
  return (
    <span className="d-flex">
      Inactive
      <Tooltip title={props.promocode.inActiveNote ? props.promocode.inActiveNote : ""} placement="bottom">
        <HelpIcon color={props.promocode.inActiveNote ? "inherit" : "disabled"} fontSize="small" className="ml-2" />
      </Tooltip>
    </span>
  )
}

export default class PromoCodeOverviewComponent extends Component {
  state = {
    promocodes: [],
    promocodeCount: 0,
    isLoading: false,
    isOpenCreateOrUpdateDialog: false,
    selectedPromocode: {},
    isOpenConfirmationDialog: false,
    inactiveNoteValue: "",
  };

  componentDidMount() {
    this.getPromocodes();
  }

  openCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: true, selectedPromocode: {} });
  }

  closeCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: false, selectedPromocode: {} });
  }

  openUpdateDialog(promocode) {
    this.setState({ selectedPromocode: promocode, isOpenCreateOrUpdateDialog: true });

  }

  getNewlyCreatedData(promocode) {
    if (promocode) this.setState({ promocodes: [promocode, ...this.state.promocodes], isOpenCreateOrUpdateDialog: false });
    console.log("getNewlyCreatedData -> ", promocode);
  }

  getUpdatedData(promocode) {
    let currentPromocodes = cloneObjectOrArray(this.state.promocodes),
      index;

    index = currentPromocodes.findIndex(
      (sagent) => sagent.uid === promocode.uid
    );

    if (index > -1) currentPromocodes[index] = promocode;

    this.setState({
      promocodes: currentPromocodes,
      isOpenCreateOrUpdateDialog: false,
      selectedPromocode: {}
    });
  }

  async getPromocodes(skip = 0) {
    let promocodes = cloneObjectOrArray(this.state.promocodes),
      promocodeCount = 0;

    if (skip === 0) this.setState({ isLoading: true });

    const apiResponse = await this.props.actionGetPromocodes(
      {
        pagination: {
          limit: 10,
          skip: skip
        }
      }
    );

    if (
      apiResponse &&
      apiResponse.count &&
      apiResponse.promoCodes
    ) {
      promocodes = [...promocodes, ...apiResponse.promoCodes];
      promocodeCount = apiResponse.count;
    }

    this.setState({ isLoading: false, promocodes, promocodeCount });
    // this.props.actionStopLoading();
  }

  openConfirmationDialog(promocode) {
    this.setState({
      selectedPromocode: promocode,
      isOpenConfirmationDialog: true,
    });
  }

  closeConfirmationDialog() {
    this.setState({
      selectedPromocode: {},
      isOpenConfirmationDialog: false,
    });
  }

  async changeActiveStatus() {
    if (
      (this.state.selectedPromocode &&
        this.state.selectedPromocode.isActive &&
        this.state.inactiveNoteValue) ||
      (this.state.selectedPromocode &&
        !this.state.selectedPromocode.isActive)
    ) {
      let promocodes = cloneObjectOrArray(this.state.promocodes),
        index;

      this.setState({ isOpenConfirmationDialog: false }, () => {
        this.props.actionStartLoading();
      });

      const updatedPromocode = await this.props.actionUpdatePromocode({
        promoCodeUid: this.state.selectedPromocode.uid,
        promoCode: {
          isActive: !this.state.selectedPromocode.isActive,
          inActiveNote: this.state.selectedPromocode.isActive
            ? this.state.inactiveNoteValue
            : null,
        },
      });

      if (updatedPromocode && updatedPromocode.uid)
        index = promocodes.findIndex(
          (promocode) => promocode.uid === updatedPromocode.uid
        );

      if (index > -1) {
        promocodes[index] = updatedPromocode;
        this.props.actionShowSnackbar({
          message: `Promo Code has been successfully ${this.state.selectedPromocode.isActive
              ? "Inactivated"
              : "Activated"
            }!`,
          type: "SUCCESS",
        });
      } else {
        this.props.actionShowSnackbar({
          message: "Something went wrong! Try again later..",
          type: "ERROR",
        });
      }

      this.setState({ promocodes, selectedPromocode: {} }, () => {
        this.props.actionStopLoading();
      });
    }
  }

  handleInactiveNoteChange(e) {
    this.setState({ inactiveNoteValue: e.target.value });
  }


  render() {

    const TableHeaders = [
      { title: "UID", buildCell: item => item.uid },
      { title: "Code", align: "center", buildCell: item => item.code },
      { title: "Amount", align: "center", buildCell: item => `${item.amount} ${item.type == '_PERCENTAGE_' ? '%' : 'BDT'}` },
      { title: "Status", align: "center", buildCell: item => <CommonChip color={item.isActive ? "success" : "error"} label={item.isActive ? "Active" : "Inactive"} /> },
      // { title: "Origin", uppercase: true, buildCell: item => minifyArrayToString(item.flight.originAirportCodes) },
      // { title: "Destination", uppercase: true, align: "center", buildCell: item => minifyArrayToString(item.flight.destinationAirportCodes)  },
      // { title: "Airline", uppercase: true, align: "center", buildCell: item => minifyArrayToString(item.flight.airlines)  },
      { title: "Validity From", align: "center", buildCell: item => formatDate(item.validityFrom, 'DD MMM \'YY', null, false) },
      { title: "Validity To", align: "center", buildCell: item => formatDate(item.validityTo, 'DD MMM \'YY', null, false) },
      { title: "Created At", align: "center", buildCell: item => formatDate(item.createdAt, 'DD MMM \'YY, hh:mm A', null, false) },
      
    ]

    if(isAuthorized({
      privileges: this.props.app.privileges,
      featurePrivilege: AUTH_UPDATE_PROMOCODE
    })){
      TableHeaders.push({
        title: 'Actions',
        align: 'right',
        buildCell: item =>
        (
          <CommonSpeedDial
            items={[
              {
                name: "Edit", icon: <EditIcon />,
                onClick: e => {
                  e.stopPropagation()
                  this.openUpdateDialog(item)
                }
              },
              {
                name: item.isActive ? "Deactivate" : "Activate",
                icon: item.isActive ? <BlockIcon /> : <CheckIcon />,
                color: item.isActive ? "error" : "success",
                onClick: e => {
                  e.stopPropagation()
                  this.openConfirmationDialog(item)
                }
              },
            ]}
          />
        )
      })
    }

    const {
      promocodes,
      promocodeCount,
      selectedPromocode,
      isLoading,
      isOpenCreateOrUpdateDialog,
      isOpenConfirmationDialog,
      inactiveNoteValue
    } = this.state;

    return (
      <div className="container">
        <Paper className="p-5">
          <div className="d-flex justify-between align-center mb-3">
            <h5 className="text-uppercase m-0">Promocodes Overview</h5>
            {
              isAuthorized({
                privileges: this.props.app.privileges,
                featurePrivilege: AUTH_CREATE_PROMOCODE
              }) ?
              <Button
                variant="contained"
                color="primary"
                // size="small"
                onClick={this.openCreateDialog.bind(this)}
              >
                <AddIcon className="mr-1" />
                Add Promo Code
              </Button> : ""
            }
            
          </div>
          <div className="w-100 overflow-x-auto">
            {isLoading ? (
              <CircularLoader />
            ) : (
              <CommonTable
                headers={TableHeaders}
                items={promocodes}
                onLoadMore={this.getPromocodes.bind(this)}
                hasMore={promocodes?.length < promocodeCount}
                noItemText="No item found"
              />
            )}
          </div>
          <ConfirmationDialog
            isOpen={isOpenConfirmationDialog}
            content={
              <ConfirmationDialogContent
                // operatorName={
                //     selectedPromocode &&
                //     selectedPromocode.meta &&
                //     `${selectedPromocode.meta.firstName} ${selectedPromocode.meta.lastName}`
                // }
                isActive={selectedPromocode.isActive}
                inactiveNoteValue={inactiveNoteValue}
                handleInactiveNoteChange={this.handleInactiveNoteChange.bind(
                  this
                )}
              />
            }
            onCancel={this.closeConfirmationDialog.bind(this)}
            onProceed={this.changeActiveStatus.bind(this)}
            proceedButtonText={
              selectedPromocode.isActive
                ? "Inactivate"
                : "Activate"
            }
          />
          <DiscountCreateOrUpdateDialog
            isOpen={isOpenCreateOrUpdateDialog}
            onClose={this.closeCreateDialog.bind(this)}
            promoCodeUid={selectedPromocode && selectedPromocode.uid}
            onCreateNew={this.getNewlyCreatedData.bind(this)}
            onUpdate={this.getUpdatedData.bind(this)}
            {...this.props}
          />

        </Paper>
      </div>
    );
  }
}
