import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
// import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
// import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// import moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",

        "& .MuiGrid-spacing-xs-3": {
            width: "auto",
            margin: 0
        }
    },
    icon: {
        fontSize: "3rem"
    }
}))

export default function AppFooter(props){
    const classes = useStyles()

    return (
        <div>
            <div className={classes.container}>
                <Grid container spacing={3}>
                    <Grid 
                        item 
                        xs={4} 
                        sm={4} 
                        md={4} 
                        lg={4}
                        className="d-flex flex-column px-3 py-5 align-center"
                    >
                        <LocationOnIcon className={classes.icon}/>
                        <div className="text-center">74, Gulshan avenue, Gulshan-1, Dhaka-1212</div>
                    </Grid>
                    <Grid 
                        item 
                        xs={4} 
                        sm={4} 
                        md={4} 
                        lg={4}
                        className="d-flex flex-column px-3 py-5 align-center"
                    >
                        <MailIcon className={classes.icon}/>
                        <div className="text-center">admin@flightlocal.com</div>
                    </Grid>
                    <Grid 
                        item 
                        xs={4} 
                        sm={4} 
                        md={4} 
                        lg={4}
                        className="d-flex flex-column px-3 py-5 align-center"
                    >
                        <PhoneIcon className={classes.icon}/>
                        <div className="text-center">(+88) 09606999966</div>
                    </Grid>
                </Grid>
            </div>
            <div className="py-3 px-2 text-center">
                Copyright © { new Date().getFullYear() } Flightlocal, Bangladesh. All rights reserved.
            </div>
            
        </div>
    )
}