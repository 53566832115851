import client from "apolloClient"
import createBankAccount from "gql/bankAccount/mutations/createBankAccount"

export default ({ bankAccount }) => async () => {
    try {
        const createResponse = await client.mutate({
            mutation: createBankAccount,
            variables: { bankAccount }
        }).then(response => response.data.createBankAccount.result)

        return createResponse
    } catch (error) {
        console.error("Err in createBankAccount", error)
    }
}