import client from "apolloClient"
import getSubAgents from "gql/subAgents/queries/getSubAgents"

export default ({ pagination, filter }) => async () => {
    try {
        const getSubAgentsResponse = await client.query({
            query: getSubAgents,
            variables: {
                pagination, filter
            }
        }).then(response => response.data.getSubAgents.result)

        return getSubAgentsResponse
        
    } catch (error) {
        console.error("Err in getSubAgents", error)
    }
}