import client from "apolloClient"
import forgotAgentPassword from "gql/auth/mutations/forgotAgentPassword"

export default ({ email }) => async () => {
    try {
        const forgotPasswordResponse = await client.mutate({
            mutation: forgotAgentPassword,
            variables: { email }
        }).then(response => response.data.forgotAgentPassword)

        return forgotPasswordResponse
    } catch (error) {
        console.error("Err in forgotAgentPassword", error)
    }
}