import gql from "graphql-tag"

export default gql`
    query getTodayFlightSaleForClient($filter: FilterInput) {
        getTodayFlightSaleForClient(filter: $filter){
            statusCode
            message
            result{
                date
                amount
                change
            }
        }
    }
`