import gql from "graphql-tag"

export default gql`
    query checkVerificationLink($token: String!) {
        checkVerificationLink(token: $token){
            message
            statusCode
            result{
                id
            }
        }
    }
`