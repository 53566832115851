import gql from "graphql-tag"

export default gql`
query getFlightBookingManagementRequisitions($pagination: PaginationInput, $filter: FlightBookingManagementRequisitionFilterInput) {
    getFlightBookingManagementRequisitions(pagination: $pagination, filter: $filter){
        message
        statusCode
        result{
            count
            flightBookingManagementRequisitions{
                id
                uid
                gdsPNR
                requestType
                requestNote
                operatorNote
                requestedBy{
                    uid
                    name
                    companyName
                    userType
                    at
                }
                handledBy{
                    uid
                    name
                    userType
                    at
                }
                status
                refund{
                    amount
                    refundDate
                }
                createdAt
                updatedAt
            }
        }
    }
}
`