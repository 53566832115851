import Grid from "@material-ui/core/Grid";
import { cloneObjectOrArray, isValidEmail, stringToObjRef } from "helper";
import CommonLink from "modules/common/components/CommonLink";
import CommonSelectComponent from "modules/common/components/CommonSelectComponent";
import CommonTextField from "modules/common/components/CommonTextField";
import Paper from "@material-ui/core/Paper";
import { Component } from "react";
import CommonButton from "modules/common/components/CommonButton";
import { makeStyles } from "@material-ui/core/styles"
import PhoneNumberField from "modules/common/components/PhoneNumberField";
import CustomDatePicker from "modules/common/components/CustomDatePicker";
import Districts from "../../../assets/data/districts.json"
import { UiConfig } from "config";
import RegistrationStepThree from "./RegistrationStepThreeComponent";
import OtherLinks from "./OtherLinks";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    primaryHeader: {
        color: theme.palette.secondary.main,
        marginBottom: 0
    }
}))

function StyledForm({ children }){
    const classes = useStyles()
    return children(classes)
}

export default class RevisedRegistration extends Component {
    agentObj = {
        meta: {
            title: "",
            firstName: "",
            lastName: "",
            company: {
                name: "",
                establishDate: null,
                address: {
                    line1: "",
                    city: ""
                }
            }
        },
        contact: {
            account: {
                countryCode: "+880",
                phone: "",
                email: ""
            }
        }
    }

    agentErrorObj = {
        meta: {
            title: "",
            firstName: "",
            lastName: "",
            company: {
                name: "",
                establishDate: "",
                address: {
                    line1: "",
                    city: ""
                }
            }
        },
        contact: {
            account: {
                countryCode: "",
                phone: "",
                email: ""
            }
        }
    }

    state = {
        agent: {...this.agentObj},
        agentError: {...this.agentErrorObj},
        agentCreateResponse: null
    }

    handleChange(value,fieldType){
        const agent = cloneObjectOrArray(this.state.agent), agentError = cloneObjectOrArray(this.state.agentError)

        stringToObjRef(agent,fieldType,value)
        stringToObjRef(agentError,fieldType,"")

        this.setState({ agent, agentError })
    }

    handleSubmit(event){
        if(event) event.preventDefault()
        if(this.isValidInputs()) this.submitAgentInputs()
    }

    async submitAgentInputs(){
        this.props.actionStartLoading()

        const agentResponse = await this.props.actionCreateAgent(this.state.agent)

        if(agentResponse && agentResponse.message === "AGENT_EMAIL_ALREADY_EXIST"){
            const agentError = cloneObjectOrArray(this.state.agentError)

            stringToObjRef(agentError,"contact.account.email","We already have an account associated with this email address")

            this.setState({ agentError })

        } else {
            this.setState({ 
                agent: {...this.agentObj},
                agentError: {...this.agentErrorObj},
                agentCreateResponse: agentResponse?.statusCode === 200 ? "SUCCESS" : "ERROR" 
            })
        }

        this.props.actionStopLoading()
    }

    isValidInputs(){
        let isValid = true

        const requiredInputs = [
            'meta.title',
            'meta.firstName',
            'meta.lastName',
            'meta.company.name',
            'meta.company.establishDate',
            'meta.company.address.line1',
            'meta.company.address.city',
            'contact.account.phone',
            'contact.account.email',
        ], currentErrors = cloneObjectOrArray(this.state.agentError)

        requiredInputs.forEach(key => {
            if(!stringToObjRef(this.state.agent,key)){
                stringToObjRef(currentErrors,key,UiConfig.REQUIRED_MESSAGE)
                isValid = false
            }
        })

        if(
            this.state.agent.contact.account.phone &&
            (isNaN(this.state.agent.contact.account.phone) || 
            this.state.agent.contact.account.phone.length < 10 ||
            this.state.agent.contact.account.phone.length > 11)
        ){
            isValid = false
            stringToObjRef(currentErrors,'contact.account.phone',"Please enter a valid phone number")
        }

        if(
            this.state.agent.contact.account.email &&
            !isValidEmail(this.state.agent.contact.account.email)
        ){
            isValid = false
            stringToObjRef(currentErrors,'contact.account.email',"Please enter a valid email address")
        }

        if(!isValid) this.setState({ agentError: currentErrors })

        return isValid
    }

    render(){
        const { agent, agentError, agentCreateResponse } = this.state

        if(agentCreateResponse){
            return <RegistrationStepThree {...this.props} isErrorOccured={agentCreateResponse === "ERROR"} />
        }

        return (
            <StyledForm>
                {classes => (
                    <Paper className="p-5 mt-5 mb-5">
                        <div className="text-center mb-5">
                            <h4 className={classes.primaryHeader}>
                                B2B Agent Portal Registration
                            </h4>
                            <div>Please complete to create your account</div>
                        </div>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <CommonSelectComponent
                                        value={agent.meta.title}
                                        label="Title"
                                        className="w-100"
                                        items={[
                                            { text: "Mr", value: "Mr" },
                                            { text: "Ms", value: "Ms" },
                                            { text: "Mrs", value: "Mrs" },
                                        ]}
                                        required
                                        errorText={agentError.meta.title}
                                        onChange={val => this.handleChange(val,"meta.title")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <CommonTextField
                                        label="First Name"
                                        fullWidth
                                        required
                                        value={agent.meta.firstName}
                                        errorText={agentError.meta.firstName}
                                        onChange={e => this.handleChange(e.target.value,"meta.firstName")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <CommonTextField
                                        label="Last Name"
                                        fullWidth
                                        required
                                        value={agent.meta.lastName}
                                        errorText={agentError.meta.lastName}
                                        onChange={e => this.handleChange(e.target.value,"meta.lastName")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CommonTextField
                                        label="Email"
                                        fullWidth
                                        required
                                        type="email"
                                        value={agent.contact.account.email}
                                        errorText={agentError.contact.account.email}
                                        onChange={e => this.handleChange(e.target.value,"contact.account.email")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <PhoneNumberField
                                        label="Phone No."
                                        id="-phone-number-input"
                                        numberValue={agent.contact.account.phone}
                                        countryCodeValue={agent.contact.account.countryCode}
                                        handleNumberChange={e => this.handleChange(e.target.value,`contact.account.phone`)}
                                        handleCountryCodeChange={val => this.handleChange(val,`contact.account.countryCode`)}
                                        errorText={agentError.contact.account.phone}
                                        required
                                        className="w-100"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CommonTextField
                                        label="Company Name"
                                        fullWidth
                                        required
                                        value={agent.meta.company.name}
                                        errorText={agentError.meta.company.name}
                                        onChange={e => this.handleChange(e.target.value,"meta.company.name")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CustomDatePicker
                                        label="Company Establish Date"
                                        value={agent.meta.company.establishDate}
                                        className="w-100"
                                        openTo="year"
                                        disableFuture
                                        required
                                        views={["year", "month", "date"]}
                                        onChange={date => this.handleChange(date,"meta.company.establishDate")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CommonSelectComponent
                                        value={agent.meta.company.address.city}
                                        label="District"
                                        className="w-100"
                                        items={Districts.map(d => ({
                                            text: d, value: d
                                        }))}
                                        required
                                        errorText={agentError.meta.company.address.city}
                                        onChange={val => this.handleChange(val,"meta.company.address.city")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CommonTextField
                                        label="Address"
                                        fullWidth
                                        required
                                        // multiline
                                        value={agent.meta.company.address.line1}
                                        errorText={agentError.meta.company.address.line1}
                                        onChange={e => this.handleChange(e.target.value,"meta.company.address.line1")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justify="center" className="mt-5">
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CommonButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        fullWidth
                                    >
                                        Register
                                    </CommonButton>
                                </Grid>
                            </Grid>
                            
                        </form>
                        <Divider variant="middle" className="mt-3"/>
                        <div className="text-center mt-2">
                            <CommonLink
                                href="/login"
                            >
                                Already have an account?
                            </CommonLink>
                            <Grid container spacing={1} justify="center" className="mt-1">
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CommonButton
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    className={classes.loginButton}
                                    fullWidth
                                    onClick={ () => this.props.history.push("/login")}
                                    >
                                    Login
                                </CommonButton>
                                </Grid>
                            </Grid>
                            
                        </div>
                        <OtherLinks/>
                    </Paper>
                )}
            </StyledForm>
            
        )
    }
}