import gql from "graphql-tag"

export default gql`
    query getInAppNotifications($pagination: PaginationInput, $filter: InAppNotificationFilter) {
    getInAppNotifications(pagination: $pagination, filter: $filter){
        statusCode
        message
        result{
            count
            inAppNotifications{
                id
                uid
                platformType
                title
                image{
                    url
                    name
                    signedUrl
                }
                content
                isActive
                inActiveNote
                createdAt
                updatedAt
            }
        }
    }
}
`