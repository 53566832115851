import gql from "graphql-tag"

export default gql`
query getTotalFlightSaleForClient($filter: FilterInput) {
    getTotalFlightSaleForClient(filter: $filter){
        statusCode
        message
        result{
            totalSale
            totalProfit
        }
    }
}
`