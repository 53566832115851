import React, { Component, Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import AccountStatusComponent from "modules/dashboard/components/AccountStatusComponent"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import Link from "@material-ui/core/Link"
import { Paper } from "@material-ui/core"
import CircularLoader from "modules/common/components/CircularLoader"
import CommonResponsiveList from "modules/common/components/CommonResponsiveList"
import CommonChip from "modules/common/components/CommonChip"
import { getQueryParamValue } from "helper";
import PaperWithHeader from "modules/common/components/PaperWithHeader";
import CommonGoBack from "modules/common/components/CommonGoBack";

function BankAccountDetails(props){
    const { loading, bankAccount } = props

    const getBankDetailsArr = () => {
        let detArr = []

        if(bankAccount && bankAccount.uid){
            detArr = [
                { title: "Account holder name", value: bankAccount.accountName ? bankAccount.accountName : "N/A" },
                { title: "Account number", value: bankAccount.accountNumber ? bankAccount.accountNumber : "N/A" },
                { title: "Bank name", value: bankAccount.bankName ? bankAccount.bankName : "N/A" },
                { title: "Branch", value: bankAccount.branchName ? bankAccount.branchName : "N/A" },
                { title: "Routing Number", value: bankAccount.routingNumber ? bankAccount.routingNumber : "N/A" },
                { title: "Swift Code", value: bankAccount.swiftCode ? bankAccount.swiftCode : "N/A" },
                { title: "IFSC", value: bankAccount.IFSC ? bankAccount.IFSC : "N/A" },
                { title: "address", value: bankAccount.address ? bankAccount.address : "N/A" },
                { title: "city", value: bankAccount.city ? bankAccount.city : "N/A" },
                { title: "Status", value: <CommonChip color={bankAccount.isActive ? "success" : "error"} label={ bankAccount.isActive ? "Active" : "Inactive" } /> },
            ]
        }

        return detArr
    }

    return (
        <Fragment>
            {
                loading ?
                <CircularLoader/> :
                <Fragment>
                    {/* <h5 className="text-uppercase mb-3">Bank Account Details</h5> */}
                    <CommonResponsiveList items={getBankDetailsArr()} segmentsCount={1} />
                </Fragment>
            }
        </Fragment>
    )
}

export default class BankAccountDetailsComponent extends Component {
    state = {
        isLoadingDetails: true,
        bankAccount: {}
    }

    componentDidMount(){
        console.log("his", this.props)
        const uid = getQueryParamValue(this.props.location,'uid');
        
        this.getBankDetails(uid)
    }

    async getBankDetails(uid){
        let bankAccount = {}
        
            bankAccount = await this.props.actionGetClientBankAccountForDetails(uid)

        this.setState({ bankAccount, isLoadingDetails: false })
    }

    goBack(){
        this.props.history.push("/bank-accounts")
    }

    render(){
        return (
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <div className="d-flex mb-4 justify-between">
                        <CommonGoBack {...this.props} pathname={"/bank-accounts"} />

                        </div>
                        <PaperWithHeader
                            header={ 'Bank Account Details' }
                            // className="mt-3"
                        >
                            
                            <BankAccountDetails 
                                loading={this.state.isLoadingDetails}
                                bankAccount={this.state.bankAccount}
                                {...this.props} 
                            />
                        </PaperWithHeader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <AccountStatusComponent {...this.props} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}