import React, { Component } from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import CommonTextField from "modules/common/components/CommonTextField"
import Button from "@material-ui/core/Button"
import { UiConfig } from "config"
import OtherLinks from "./OtherLinks"

const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.secondary.main
    },
    loginButton: {
        width: "10rem",
    }
}))

function StyledEmailInput(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class ForgotPasswordEmailInputComponent extends Component {
    state = {
        email: "",
        emailError: "",
        isSubmitted: false
    }

    validateAndSubmitData(event){
        if(event) event.preventDefault()

        if(!this.state.email){
            this.setState({ emailError: UiConfig.REQUIRED_MESSAGE })
        } else {
            this.submitData()
        }
    }

    async submitData(){
        this.props.actionStartLoading()

        const forgotPasswordResponse = await this.props.actionForgotAgentPassword({ email: this.state.email })

        if(forgotPasswordResponse && forgotPasswordResponse.message === "USER_NOT_FOUND"){
            this.setState({ emailError: "We can't find any user associated with this email. Please re-check and try again later.." })
        } else if(forgotPasswordResponse && forgotPasswordResponse.message === "MAXIMUM_FORGOT_PASSWORD_ATTEMPTS_IS_EXCEEDED"){
            this.setState({ emailError: "Too many forgot password attempts. Please re-check and try again later.." })
        } else if (forgotPasswordResponse && forgotPasswordResponse.message === "SUCCESS") {
            this.setState({ isSubmitted: true })
        }

        this.props.actionStopLoading()
    }

    handleEmailChange(event){
        this.setState({ email: event.target.value, emailError: "" })
    }

    goToLogin(){
        this.props.history.push("/login")
    }

    render(){
        return (
            <StyledEmailInput>
                {classes => (
                    <Paper className="p-5 mt-5">
                        {
                            this.state.isSubmitted ?
                            <div className="text-center">
                                <div className="text-center mb-5">
                                    <h4 className={classes.heading}>We have sent you an email</h4>
                                    <div>Please check your inbox and follow that email instructions to set a new password</div>
                                    <div className="mt-5">
                                        Thanks for being with Flight Local.
                                    </div>
                                </div>
                                <div className="mt-5 d-flex justify-center">
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        size="large"
                                        className={classes.loginButton}
                                        onClick={this.goToLogin.bind(this)}
                                    >
                                        Go to Login
                                    </Button>
                                </div>
                            </div> :
                            <form onSubmit={this.validateAndSubmitData.bind(this)}>
                                <div className="text-center mb-5">
                                    <h4 className={classes.heading}>Forgot your password?</h4>
                                    <div>Let's find your account</div>
                                </div>
                                <CommonTextField
                                    label="Email"
                                    value={this.state.email}
                                    type="email"
                                    errorText={this.state.emailError}
                                    required
                                    onChange={this.handleEmailChange.bind(this)}
                                />
                                <div className="mt-5 d-flex justify-between">
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        size="large"
                                        className={classes.loginButton}
                                        onClick={this.goToLogin.bind(this)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        className={classes.loginButton}
                                        onClick={this.validateAndSubmitData.bind(this)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        }
                        <OtherLinks/>
                    </Paper>
                )}
            </StyledEmailInput>
            
        )
    }
}