import translateEnumToText from './translateEnumToText'
export default function minifyArrayToString(list, itemsToShow) {
  if (!list) return;
  if (!itemsToShow) {
    itemsToShow = 3; //default value
  }

  if (list.length > itemsToShow) {
    let newlist = list.slice(0, itemsToShow).map(item => translateEnumToText(item));
    newlist.push(`+${list.length - itemsToShow} More`);
    return newlist.join(", ");
  } else {
    return list.map(item => translateEnumToText(item)).join(", ");
  }
}
