import Grid from "@material-ui/core/Grid";
import { Theme as CustomTheme } from "config";
import dayjs from "dayjs";
// import CommonButton from "modules/common/components/CommonButton";
import CustomDatePicker from "modules/common/components/CustomDatePicker";
import { useState } from "react";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles"
import CommonButton from "modules/common/components/CommonButton";
import apiEndPoint from "config/apiEndPoint";
import { Paper } from "@material-ui/core";
import { authToken } from "config";

const useStyles = makeStyles(theme => ({
    downloadLink: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: "0.25rem"
    },
    downloadContainer: {
        display: "flex",
        justifyContent: "center"
    },
}))

export default function SalesReportDownloadOption({
    urlSlug, heading
}){
    const defaultStartDate = dayjs().subtract(7,"day").format(CustomTheme.DATE_FORMAT)
    const defaultEndDate = dayjs().format(CustomTheme.DATE_FORMAT)
    const classes = useStyles()
    const token = localStorage.getItem(authToken.key)

    const [startDate,setStartDate] = useState(defaultStartDate)
    const [endDate,setEndDate] = useState(defaultEndDate)

    return (
        <Paper className="p-5">
          <div>
            <Grid container spacing={3}>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                >
                    <CustomDatePicker
                        value={startDate}
                        label="Start date"
                        onChange={setStartDate}
                        disableFuture
                        fullWidth
                        maxDate={new Date(endDate)}
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                >
                    <CustomDatePicker
                        value={endDate}
                        label="End date"
                        onChange={setEndDate}
                        disableFuture
                        fullWidth
                        maxDate={new Date(defaultEndDate)}
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    className={classes.downloadContainer}
                >
                    <CommonButton
                        color="secondary"
                        target="_blank"
                        variant="contained"
                        fullWidth
                        href={`${apiEndPoint().downloadUrl}${urlSlug}/${startDate}/${endDate}/${token}`}
                    >
                        <GetAppOutlinedIcon
                            className={classes.icon}
                        />
                        Download {heading} (.csv)
                    </CommonButton>
                </Grid>
            </Grid>
            </div>
        </Paper>
    )
}