import gql from "graphql-tag"
//FIXME: remove unnecessary return data
export default gql`
mutation updateFlightMarkup($flightMarkupUid: String!, $flightMarkup: FlightMarkupUpdateInput!) {
    updateFlightMarkup(flightMarkupUid: $flightMarkupUid, flightMarkup: $flightMarkup){
        statusCode
        message
        result{
            id
            uid
            # operator{
            #     uid
            #     name
            #     userType
            # }
            title
            platformType
            airlines
            bookingClasses
            cabinType
            travelValidityFrom
            travelValidityTo
            fareFrom
            fareTo
            markup
            markupType
            applyOn
            applyFor
            fareType
            currency
            originAirportCodes
            destinationAirportCodes
            tripType
            # isCompanyMarkup
            isActive
            inActiveNote
            createdAt
            updatedAt
        }
    }
}
`