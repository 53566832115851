import React from "react"
import Chip from "@material-ui/core/Chip"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root: {
        textTransform: "uppercase"
    },
    minimal: {
        error: {
            color: theme.palette.error.dark,
        },
        warning: {
            color: theme.palette.warning.dark,
        },
        success: {
            color: theme.palette.success.dark,
        }
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.background.paper
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.background.paper
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.background.paper

    }
}))

const useMinimalStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
    },
    warning: {
        color: theme.palette.warning.dark,
    },
    success: {
        color: theme.palette.success.dark,
    }
}))

export default function CommonChip(props){
    const { color, className, chipType, label, ...restProps } = props, classes = useStyles(),
    minimalClasses = useMinimalStyles();

    let customClass, customColor;

    if (color === "error" || color === "warning" || color === "success") {
        if(chipType === 'minimal') customClass = minimalClasses[`${color}`]
        else customClass = classes[`${color}`]
    } else if(color === "primary" || color === "secondary" || color === "default"){
        customColor = color
    }

    console.log(customClass)

    return (
        <>
            {
                chipType && chipType === 'minimal' ?
                <span className={`${classes.root} ${customClass} ${className}`}>
                    { label }
                </span>
                    :
                <Chip
                    color={customColor}
                    className={`${className} ${customClass} ${classes.root}`}
                    label={label}
                    {...restProps}
                />
            }
        </>
        
    )
}


CommonChip.propTypes = {
    color: PropTypes.oneOf([ "primary", "secondary", "default", "error", "warning", "success" ]),
    className: PropTypes.string,
    
    // other props are same as 'Button' of Material UI
}