import gql from "graphql-tag"

export default gql`
query getFlightMarkups($pagination: PaginationInput, $filter: FlightMarkupFilterInput) {
    getFlightMarkups(pagination: $pagination, filter: $filter){
        statusCode
        message
        result{
            count
            flightMarkups{
                id
                uid
                title
                # operator{
                #     id
                #     userType
                # }
                airlines
                bookingClasses
                cabinType
                travelValidityFrom
                travelValidityTo
                fareFrom
                fareTo
                markup
                markupType
                applyOn
                applyFor
                fareType
                currency
                originAirportCodes
                destinationAirportCodes
                tripType
                isActive
                inActiveNote
                createdAt
                updatedAt
            }
        }
    }
}
`