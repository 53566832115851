import client from "apolloClient";
import createFlightMarkup from "gql/flightMarkup/mutations/createFlightMarkup";

export default markup => async () => {
  try {
    const createFlightMarkupResponse = await client
      .mutate({
        mutation: createFlightMarkup,
        variables: { flightMarkup: markup }
      })
      .then(response => response.data.createFlightMarkup);

    if (createFlightMarkupResponse) return createFlightMarkupResponse;

    console.log("createFlightMarkupResponse", createFlightMarkupResponse);
  } catch (error) {
    console.error("Err in createFlightMarkup", error);
  }
};
