import gql from "graphql-tag";

export default gql`
    mutation createAgent($agent: AgentCreateInput!) {
    createAgent(agent: $agent){
        message
        statusCode
        result{
            id
            uid
            userType
            operator{
                uid
                name
                companyName
                userType
            }
            meta{
                title
                firstName
                lastName
                avatar{
                    url
                    name
                    signedUrl
                }
                company{
                    name
                    companyType
                    logo{
                        url
                        name
                    }
                    tradeLicenseNumber
                    webSiteUrl
                    address{
                        country
                        city
                        postalCode
                        line1
                        line2
                    }
                    documents{
                        url
                        name
                        type
                    }
                    country
                    email
                    countryCode
                    phone
                    timeZone
                    establishDate
                }
                currency
            }
            contact{
                business{
                    countryCode
                    phone
                    isPhoneNumberVerified
                    email
                }
                account{
                    countryCode
                    phone
                    isPhoneNumberVerified
                    email
                }
            }
            role{
                id
                uid
                name
                userType
                privileges{
                    feature{
                        id
                        name
                    }
                    action{
                        id
                        name
                        shortCode
                    }
                }
                isActive
                inActiveNote
            }
            service{
                flight{
                    commission
                    commissionType
                }
            }
            payment{
                mode
                totalDeposit
                totalSpent
                totalLoanAmount
                overdrawLimit
                currentBalance
                paymentCycle
            }
            subAgent{
                limit
            }
            smtp{
                smtpServerUrl
                smtpPort
                userName
                fromEmailAddress
                emailPassword
                isSSLEnable
                isActive
            }
            isActive
            isVerified
            inActiveNote
        }
    }
}
`;
