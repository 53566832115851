import client from "apolloClient"
import getProfileForAccountStatus from "gql/dashboard/queries/getProfileForAccountStatus"

export default () => async () => {
    try {
        const accountStatusResponse = await client.query({
            query: getProfileForAccountStatus
        }).then(response => response.data.getAgentProfile.result)

        return accountStatusResponse
    } catch (error) {
        console.error("Error in getProfile", error)
    }
}