import client from "apolloClient"
import verifyResetPasswordLink from "gql/auth/queries/verifyResetPasswordLink"

export default ({ token }) => async () => {
    try {
        const verifyResetPasswordResponse = await client.query({
            query: verifyResetPasswordLink,
            variables: { token }
        }).then(response => response.data.checkVerificationLink)

        console.log("checkVerificationLink", verifyResetPasswordResponse)

        return verifyResetPasswordResponse
    } catch (error) {
        console.error("Err in verifyResetPasswordLink", error)
    }
}