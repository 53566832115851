export default function isAuthorized({
    privileges = [],
    featurePrivilege,
    featurePrivileges = []
}){
    if(!featurePrivilege && !featurePrivileges.length) return true;
    if(featurePrivilege && privileges.includes(featurePrivilege)) return true;
    else if(!featurePrivilege && featurePrivileges?.length){
        const matchedPrivileges = featurePrivileges.filter(pri => privileges.includes(pri))

        if(matchedPrivileges.length) return true
    }

    return false;
}