import client from "apolloClient"
import updateFlightMarkup from "gql/flightMarkup/mutations/updateFlightMarkup"

export default ({ flightMarkupUid, flightMarkup }) => async () => {
    try {
        const updateFlightMarkupResponse = await client.mutate({
            mutation: updateFlightMarkup,
            variables: { flightMarkupUid, flightMarkup }
        }).then(response => response.data.updateFlightMarkup)

        if(updateFlightMarkupResponse && (updateFlightMarkupResponse.statusCode === 200 || updateFlightMarkupResponse.statusCode === 201)){
            return updateFlightMarkupResponse.result
        }

        console.log("updateFlightMarkupResponse", updateFlightMarkupResponse)
    } catch (error) {
        console.error("Err in updateFlightMarkup", error)
    }
}