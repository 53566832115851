import React, { Component } from "react";
import { withRouter } from "react-router-dom"
import Button from "@material-ui/core/Button";
import NotFound from "modules/notFound/styledComponents/NotFound";

class NotFoundComponent extends Component {
  handleClick = () => {
    this.props.history.push('/')
  }

  render() {
    const notFoundImage = "/images/bg/404.svg";
    return (
      <NotFound>
        {classes => (
          <div className={classes.container}>
            <div className={classes.imgContainer}>
              <img
                className={classes.img}
                src={notFoundImage}
                alt="404 Not Found"
              />
              <div className={classes.textContainer}>
                <h3 className={classes.title}>
                  {//TODO: Add actual 404 message that makes sense
                  }
                  GAWRSH!
                </h3>
                <span className={classes.subtitle}>
                  Something is not working right.
                  Please try again soon.
                </span>
                <div className={classes.buttonContainer}>
                  <Button 
                  variant="contained" 
                  size="medium"
                  onClick={this.handleClick}>
                    Go to Home
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </NotFound>
    );
  }
}

export default withRouter(NotFoundComponent)