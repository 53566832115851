import React, { lazy, Suspense } from 'react'
import { Route, Switch, withRouter } from "react-router-dom"
import { WithoutAuthRoutes, RoutesOnSidebar, RoutesForSettings, RoutesNotOnSidebar, RoutesForBankAccounts, RoutesForCompanyBankAccount,
    RoutesForBookingPanel, RoutesForFlightQueue, RoutesForDeposit, RoutesForTraveller, RoutesForGroupFare } from "routes"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// import InlineLoader from "modules/common/components/InlineLoader"
import * as actions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import NotFound from 'modules/notFound/components/NotFoundComponent'
import { AnimatePresence } from "framer-motion"
// import { TransitionGroup } from "react-transition-group"
import CommonSnackBar from "modules/common/components/CommonSnackBar"
import AuthLayout from "layouts/AuthLayout"
// import HomeLayout from "layouts/HomeLayout"
import FullPageLoader from "modules/common/components/FullPageLoader"
import AuthRequired from "modules/auth/components/AuthRequired"
import RehydrationCheckPost from './RehydrationCheckPost'
import ResponsiveApp from './ResponsiveApp'
// import InlineLoader from 'modules/common/components/InlineLoader'
import AppLoaderTriggerComponent from 'modules/common/components/AppLoader'
import ScrollToTop from 'modules/common/components/ScrollToTop'
// import LinearLoader from "modules/common/components/LinearLoader"
import GlobalErrorHandler from "./GlobalErrorHandler"
import packageJson from "../../package.json"
import CacheBuster from './CacheBuster'
import ErrorBoundary from "./ErrorBoundary"

global.appVersion = packageJson.version;

const HomeLayout = lazy(() => import("layouts/HomeLayout"))

const pathsForLayout = routes => routes.map(route => route.path)

const createStyles = makeStyles(theme => ({
    [`body`]: {
        fontSize: theme.fontSize,
    },
    app: {
        fontFamily: theme.fontFamily,
        color: theme.fontColor,
        boxSizing: "border-box",
        // height: "100vh",
        // overflowY: "auto",
        backgroundColor: theme.backgroundColor,
        "& *": {
            fontFamily: theme.fontFamily,
            // fontSize: `${theme.fontSize} !important`,
        },

        "& .MuiFormLabel-root": {
            backgroundColor: theme.secondaryBackgroundColor,
            // padding: "0 0.25rem"
        },

        "& .primary-color": {
            color: `${theme.primaryColor} !important`
        },
    }
}))

const AuthRequiredRoutes = [ 
    ...RoutesNotOnSidebar, 
    ...RoutesOnSidebar, 
    ...RoutesForBankAccounts, 
    ...RoutesForCompanyBankAccount, 
    ...RoutesForBookingPanel, 
    ...RoutesForFlightQueue, 
    ...RoutesForDeposit,
    ...RoutesForGroupFare, 
    ...RoutesForTraveller, 
    ...RoutesForSettings 
]

const Home = props => {
    const theme = useTheme(), classes = createStyles()

    return (
        <div className={classes.app}>
                {/* <InlineLoader/> */}
            <GlobalErrorHandler {...props}>
                <CacheBuster {...props}>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                        // if (loading) return null;
                        // if (!loading && !isLatestVersion) {
                        //     // force reload
                        //     refreshCacheAndReload();
                        // }
                        return (
                            <RehydrationCheckPost {...props}>
                                <ResponsiveApp theme={theme} {...props}>
                                    <FullPageLoader {...props}/>
                                    <CommonSnackBar {...props} />
                                    {/* <LinearLoader/> */}
                                    <ScrollToTop>
                                        <Switch>
                                            <Route exact path={pathsForLayout(WithoutAuthRoutes)}>
                                                <AuthLayout {...props}>
                                                    <Switch>
                                                        {
                                                            WithoutAuthRoutes.map((route,index) => (
                                                                <Route
                                                                    key={index}
                                                                    exact={route.exact}
                                                                    path={route.path}
                                                                    component={route.component}
                                                                />
                                                            ))
                                                        }
                                                    </Switch>
                                                </AuthLayout>
                                            </Route>
                                            <Route exact path={pathsForLayout(AuthRequiredRoutes)}>
                                                <Suspense fallback={<AppLoaderTriggerComponent {...props}/>}>
                                                    <HomeLayout>
                                                        <ErrorBoundary>
                                                            <AnimatePresence>
                                                                <Suspense fallback={<AppLoaderTriggerComponent {...props}/>}>
                                                                    <Switch>
                                                                        {
                                                                            AuthRequiredRoutes.map((route,index) => (
                                                                                <Route
                                                                                    key={index}
                                                                                    exact={route.exact}
                                                                                    path={route.path}
                                                                                    render={() => (
                                                                                        <AuthRequired 
                                                                                            {...props}
                                                                                            privilege={route.privilege}
                                                                                            orComponent={route.component}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </Switch>
                                                                </Suspense>
                                                            </AnimatePresence>
                                                        </ErrorBoundary>
                                                    </HomeLayout>
                                                </Suspense>
                                                    
                                            </Route>
                                            <Route component={() => <NotFound />} />
                                            {/*<Redirect to='/404' />*/}
                                        </Switch>
                                    </ScrollToTop>
                                </ResponsiveApp>
                            </RehydrationCheckPost>
                        )}
                    }
                </CacheBuster>
            </GlobalErrorHandler>
        </div>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))