import client from "apolloClient"
import getAirports from "gql/flightSearch/queries/getAirports"

export default searchQuery => async dispatch => {
    try {
        const airlinesResponse = await client.query({
            query: getAirports,
            variables: searchQuery ? { filter: { query: searchQuery }, pagination: { limit: null, skip: 0 } } : { pagination: { limit: 10, skip: 0 } }
        }).then(response => response.data.getAirports.result.airports)

        return airlinesResponse
    } catch (error) {
        console.error("Err in getAirports", error)
    }
}