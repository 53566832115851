import gql from "graphql-tag"

export default gql`
    subscription subscribeAgentBalance($agentUid: String!) {
    subscribeAgentBalance(agentUid: $agentUid){
        name
        status
        uid
        currentBalance
    }
}
`