import gql from "graphql-tag"

export default gql`
query getAirlines($pagination: PaginationInput, $filter: AirLineFilterInput) {
    getAirlines(pagination: $pagination, filter: $filter){
        message
        statusCode
        result{
            count
            airlines{
                id
                fullName
                shortName
                meta{
                    iataCode
                    countryCode
                    countryName
                }
                isActive
                inActiveNote
            }
        }
    }
}
`