import client from "apolloClient"
import getFlightMarkupsForFullMarkupObject from "gql/flightMarkup/queries/getFlightMarkupsForFullMarkupObject"

export default ({ filter }) => async() => {
    try {
        const markupsResponse = await client.query({
            query: getFlightMarkupsForFullMarkupObject,
            variables: {
                 filter
            }
        }).then(response => response.data.getFlightMarkups)
        return markupsResponse.result
    } catch (error) {
        console.error("Err in getFlightMarkupsForFullMarkupObject", error)
    }
}