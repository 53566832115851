import React, { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.secondary.main,
    },
    registrationButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem",
    },
    registrationButton: {
        width: "100%",
    },
    errorText: {
        color: theme.palette.error.main,
    },
}));

export default function RegistrationStepThreeComponent(props) {
    const { isErrorOccured } = props;
    const classes = useStyles();
    return (
        <Paper className={`${classes.heading} p-5 mt-5 mb-5`}>
            {!isErrorOccured ? (
            <Fragment>
                <div className="text-center">
                    <h4 className={classes.heading}>
                        Your Registration Request Has Been Received!
                    </h4>
                    <div className="mt-3">Our Customer Care Executives Will Contact you Shortly!</div>
                </div>
            </Fragment>
            ) : <Fragment>
                <div className="text-center">
                    <h4 className={classes.errorText}>
                        Booh! Server is in the spirit world!
                    </h4>
                    <div className={`${classes.errorText} mt-3`}>Please try again later..</div>
                </div>
                
                
            </Fragment>}
            <div className={classes.registrationButtonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                    className={classes.registrationButton}
                    fullWidth
                    onClick={(e) => {
                        e.preventDefault();
                        props.history.push({
                            pathname: "/login",
                        });
                    }}>
                    Go Back to Main Page
                </Button>
            </div>
        </Paper>
    );
}
