import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    pointerOnHover: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    tooltip: {
        fontSize: 16
    }

}))

export default function ViewStubAgent(props){
    const classes = useStyles();

    return props.children(classes)
}