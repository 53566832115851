import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
  cloneObjectOrArray,
  translateEnumToText,
  formatDate,
  minifyArrayToString
} from "helper";
import SubAgentOverview from "../styledComponents/SubAgentOverview";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CommonChip from "modules/common/components/CommonChip";
import CommonSpeedDial from "modules/common/components/CommonSpeedDial";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
// import createOrUpdateSubAgentComponent from "./createOrUpdateSubAgentComponent";
import CommonTextField from "modules/common/components/CommonTextField";
import ConfirmationDialog from "modules/common/components/ConfirmationDialog";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonWithLoader from "modules/common/components/ButtonWithLoader";
import CircularLoader from "modules/common/components/CircularLoader";
import CommonTable from "modules/common/components/CommonTable";
import PhoneIcon from '@material-ui/icons/Phone'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import SubAgentCreateOrUpdateDialog from "./SubAgentCreateOrUpdateDialog";

function ConfirmationDialogContent(props) {
  if (props.isActive) {
      // get inactive note
      return (
          <div className="px-3">
              <div className="mb-3">
                  Are you sure you want to{" "}
                  <strong>Inactivate this sub agent?</strong>
              </div>
              <CommonTextField
                  label="Describe the reason to inactivate"
                  value={props.inactiveNoteValue}
                  required
                  multiline
                  rows={4}
                  onChange={props.handleInactiveNoteChange}
              />
          </div>
      );
  } else {
      // confirm to active
      return (
          <div className="px-3">
              Are you sure? You want to{" "}
              <strong>Activate this sub agent?</strong>
          </div>
      );
  }
}

function InActiveNoteTooltip(props) {
  return (
    <span className="d-flex">
      Inactive
      <Tooltip title={props.subAgent.inActiveNote ? props.subAgent.inActiveNote : "" } placement="bottom">
        <HelpIcon color={props.subAgent.inActiveNote ? "inherit" : "disabled" } fontSize="small" className="ml-2" />
      </Tooltip>
    </span>
  )
}

export default class SubAgentOverviewComponent extends Component {
  state = {
    subAgents: [],
    subAgentsCount: 0,
    isLoading: false,
    isOpenCreateOrUpdateDialog: false,
    selectedSubAgent: {},
    isOpenConfirmationDialog: false,
    inactiveNoteValue: "",
  };

  componentDidMount() {
    this.getSubAgents();
  }

  openCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: true, selectedSubAgent: {} });
  }

  closeCreateDialog() {
    this.setState({ isOpenCreateOrUpdateDialog: false, selectedSubAgent: {} });
  }

  openUpdateDialog(subAgent) {
    this.setState({ selectedSubAgent: subAgent, isOpenCreateOrUpdateDialog: true });
    
  }

  getNewlyCreatedData(subAgent) {
    if(subAgent) this.setState({ subAgents: [subAgent, ...this.state.subAgents], isOpenCreateOrUpdateDialog: false });
    console.log("getNewlyCreatedData -> ", subAgent);
  }
  
  getUpdatedData(subAgent) {
    let currentSubAgents = cloneObjectOrArray(this.state.subAgents),
        index;

    index = currentSubAgents.findIndex(
        (sagent) => sagent.uid === subAgent.uid
    );

    if (index > -1) currentSubAgents[index] = subAgent;

    this.setState({
        subAgents: currentSubAgents,
        isOpenCreateOrUpdateDialog: false,
        selectedSubAgent: {}
    });
}

  async getSubAgents(skip = 0) {
    let subAgents = cloneObjectOrArray(this.state.subAgents),
      subAgentsCount = 0;

    if( skip === 0) this.setState({ isLoading: true });

    const apiResponse = await this.props.actionGetSubAgents(
      {
        pagination: {
        limit: 10,
        skip: skip
      }}
    );

    if (
      apiResponse &&
      apiResponse.count &&
      apiResponse.subAgents
    ) {
      subAgents = [...subAgents, ...apiResponse.subAgents];
      subAgentsCount = apiResponse.count;
    }

    this.setState({ isLoading: false, subAgents, subAgentsCount });
    // this.props.actionStopLoading();
  }

  openConfirmationDialog(subAgent) {
    this.setState({
        selectedSubAgent: subAgent,
        isOpenConfirmationDialog: true,
    });
}

closeConfirmationDialog() {
    this.setState({
        selectedSubAgent: {},
        isOpenConfirmationDialog: false,
    });
}

async changeActiveStatus() {
    if (
        (this.state.selectedSubAgent &&
            this.state.selectedSubAgent.isActive &&
            this.state.inactiveNoteValue) ||
        (this.state.selectedSubAgent &&
            !this.state.selectedSubAgent.isActive)
    ) {
        let subAgents = cloneObjectOrArray(this.state.subAgents),
            index;

        this.setState({ isOpenConfirmationDialog: false }, () => {
            this.props.actionStartLoading();
        });

        const updatedSubAgent = await this.props.actionUpdateSubAgent({
          subAgentUid: this.state.selectedSubAgent.uid,
          subAgent: {
                isActive: !this.state.selectedSubAgent.isActive,
                inActiveNote: this.state.selectedSubAgent.isActive
                    ? this.state.inactiveNoteValue
                    : null,
            },
        });

        if (updatedSubAgent && updatedSubAgent.uid)
            index = subAgents.findIndex(
                (subAgent) => subAgent.uid === updatedSubAgent.uid
            );

        if (index > -1) {
            subAgents[index] = updatedSubAgent;
            this.props.actionShowSnackbar({
                message: `Sub-agent has been successfully ${
                    this.state.selectedSubAgent.isActive
                        ? "Inactivated"
                        : "Activated"
                }!`,
                type: "SUCCESS",
            });
        } else {
            this.props.actionShowSnackbar({
                message: "Something went wrong! Try again later..",
                type: "ERROR",
            });
        }

        this.setState({ subAgents, selectedSubAgent: {} }, () => {
            this.props.actionStopLoading();
        });
    }
}

handleInactiveNoteChange(e) {
    this.setState({ inactiveNoteValue: e.target.value });
}


  render() {

    const TableHeaders = [
      {
        title: 'Name',
        buildCell: item => `${item.meta.firstName} ${item.meta.lastName}`
      },
      {
        title: "Contact (Account)", buildCell: item => (
            <>
                <div className="d-flex align-center mb-1">
                    <PhoneIcon fontSize="small" className="mr-1"/>
                    <small>{ item.contact.account.phone ? `${item.contact.account.countryCode}${item.contact.account.phone}` : "N/A" }</small>
                </div>
                <div className="d-flex align-center">
                    <AlternateEmailIcon fontSize="small" className="mr-1"/>
                    <small>{ item.contact.account.email ? item.contact.account.email : "N/A" }</small>
                </div>
            </>
        )
      },
      {
        title: 'Role',
        buildCell: item => translateEnumToText(item.role.name)
      },
      {
        title: "Status",
        align: "center",
        buildCell: item => (
          <CommonChip
          color={item.isActive ? "success" : "error"}
          label={item.isActive ? "Active" : <InActiveNoteTooltip subAgent={item} />}
        />)
    },
      {
        title: 'Actions',
        align: 'right',
        buildCell: item =>
        (
            <CommonSpeedDial
                items={[
                    {
                        name: "Edit",
                        icon: <EditIcon />,
                        onClick: () => this.openUpdateDialog(item)
                      },
                      {
                        name: item.isActive
                          ? "Deactivate"
                          : "Activate",
                        icon: item.isActive ? (
                          <BlockIcon />
                        ) : (
                            <CheckIcon />
                          ),
                        color: item.isActive ? "error" : "success",
                        onClick: () =>
                          this.openConfirmationDialog(item)
                      }
                ]}
            />
            )
    }
      

  ]

    const {
      subAgents,
      subAgentsCount,
      selectedSubAgent,
      isLoading,
      isOpenCreateOrUpdateDialog,
      isOpenConfirmationDialog,
      inactiveNoteValue
    } = this.state;
    return (
      <div className="container">
        <Paper className="p-5">
          <div className="d-flex justify-between align-center mb-3">
            <h5 className="text-uppercase m-0">Sub-agents Overview</h5>
            <Button
              variant="contained"
              color="primary"
              // size="small"
              onClick={this.openCreateDialog.bind(this)}
            >
              <AddIcon className="mr-1" />
              Add Sub-agent
            </Button>
          </div>
          <div className="w-100 overflow-x-auto">
            {isLoading && !subAgents.length ? (
              <CircularLoader />
            ) : (
              <CommonTable
                headers={TableHeaders}
                items={subAgents}
                onLoadMore={this.getSubAgents.bind(this)}
                hasMore={subAgents?.length < subAgentsCount}
                noItemText="No item found"
              />
            )}
              </div>       
          <ConfirmationDialog
                        isOpen={isOpenConfirmationDialog}
                        content={
                            <ConfirmationDialogContent
                                // operatorName={
                                //     selectedSubAgent &&
                                //     selectedSubAgent.meta &&
                                //     `${selectedSubAgent.meta.firstName} ${selectedSubAgent.meta.lastName}`
                                // }
                                isActive={selectedSubAgent.isActive}
                                inactiveNoteValue={inactiveNoteValue}
                                handleInactiveNoteChange={this.handleInactiveNoteChange.bind(
                                    this
                                )}
                            />
                        }
                        onCancel={this.closeConfirmationDialog.bind(this)}
                        onProceed={this.changeActiveStatus.bind(this)}
                        proceedButtonText={
                            selectedSubAgent.isActive
                                ? "Inactivate"
                                : "Activate"
                        }
                    />
          <SubAgentCreateOrUpdateDialog
            isOpen={isOpenCreateOrUpdateDialog}
            onClose={this.closeCreateDialog.bind(this)}
            subAgentUid={selectedSubAgent && selectedSubAgent.uid}
            onCreateNew={this.getNewlyCreatedData.bind(this)}
            onUpdate={this.getUpdatedData.bind(this)}
            {...this.props}
          />

        </Paper>
      </div>
    );
  }
}
