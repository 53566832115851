import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    skeletonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .MuiSkeleton-root:first-child': {
            marginBottom: '3px',
        }
    },
    hidden: {
        display: 'none',
    }
}))
export default function CommonImage (props){
    const { height, width, src, alt, fallbackSrc, className, showLoader = false, ...restProps } = props

    const [imageSource, setImageSource] = useState("")
    const [loaded, setLoaded] = useState(false)

    const [errored, setError] = useState(false)
    const classes = useStyles();
    useEffect(() => {
        setImageSource(src)
    }, [src])

    const setImageError = () => {
        if(!errored && fallbackSrc){
            setImageSource(fallbackSrc)
            setError(true)
        }
    }

    const handleImageLoaded = () => setLoaded(true);

    const defaultSkeleton = () => {
        return (
            <div className={classes.skeletonWrapper}>
                <Skeleton variant="circle" width="25px" height="25px" />
                <Skeleton variant="rect" width="40px" height="8px" />
            </div>
        )
    }

    return (
        <div className="imageWrapper">
            {showLoader && !loaded && defaultSkeleton() }
            <img 
                src={imageSource} 
                alt={alt ? alt : "Image"}
                style={{ height, width }}
                className={`${!loaded && classes.hidden} ${className}`}
                { ...restProps }
                onError={() => setImageError()}
                onLoad={handleImageLoaded}
            />
        </div>
        
    )
}

CommonImage.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.any
}