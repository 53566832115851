export const AUTH_GET_ITINERARY_GROUP = "GET-ITINERARY-GROUP";
export const AUTH_CREATE_FLIGHT_BOOKING = "CREATE-FLIGHT-BOOKING";
export const AUTH_GET_FLIGHT_BOOKINGS = "GET-FLIGHT-BOOKINGS";
export const AUTH_GET_TRAVELLERS = "GET-TRAVELLERS";
export const AUTH_GET_BANK_ACCOUNTS = "GET-BANK-ACCOUNTS";
export const AUTH_GET_DEPOSITS = "GET-DEPOSITS";
export const AUTH_GET_LEDGERS = "GET-LEDGERS";
export const AUTH_GET_FLIGHT_MARKUPS = "GET-FLIGHT-MARKUPS";
export const AUTH_GET_SUBAGENTS = "GET-SUBAGENTS";
export const AUTH_GET_DISCOUNTS = "GET-DISCOUNTS";
export const AUTH_CREATE_DISCOUNT = "CREATE-DISCOUNT";
export const AUTH_UPDATE_DISCOUNT = "UPDATE-DISCOUNT";
export const AUTH_GET_PROMOCODES = "GET-PROMOCODES";
export const AUTH_CREATE_PROMOCODE = "CREATE-PROMOCODE";
export const AUTH_UPDATE_PROMOCODE = "UPDATE-PROMOCODE";
export const AUTH_DOWNLOAD_B2B_FLIGHT_SALE = "DOWNLOAD-B2B-FLIGHT-SALE";