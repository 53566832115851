import client from "apolloClient"
import getInAppNotifications from "gql/notification/queries/getInAppNotifications"

export default ({ pagination, filter }) => async () => {
    try {
        const response = await client.query({
            query: getInAppNotifications,
            variables: { pagination, filter }
        }).then(response => response.data.getInAppNotifications)

        if(response && (response.statusCode === 200 || response.statusCode === 201)){
            return response.result
        }

    } catch (error) {
        console.error("Err in response", error)
    }
}