import React, { useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
    imgContainer: {
        display: "flex",
        flexWrap: "wrap",

        "& .img-wrapper": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "9rem",
            width: "9rem",
            cursor: "pointer",
            position: "relative",

            "& .img": {
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "hidden"
                // width: "3rem"
            },

            "& .overlay": {
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundColor: "#000000",
                opacity: 0,
                transition: "opacity 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                "& .img-title": {
                    color: "#fff"
                }
            },

            "&:hover .overlay": {
                opacity: 0.7
            }
        },

        "& .img-wrapper:not(:last-child)":{
            marginRight: "1rem"
        },

        
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: "rgba(27, 29, 27, 0.8)",
        flexDirection: "column"
    },
    imgViewContainer: {
        maxHeight: "100vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100vw",

        "& .img": {
            width: "calc(100% - 6rem)",
            margin: "1rem 3rem 3rem",
            height: "fit-content"
        }
    },
    closeIcon: {
        position: "fixed",
        right: 0,
        top: 0,
        color: "gray"
    }
}))

const ImageDialog = props => {
    const { classes } = props

    return (
        <Backdrop
            className={classes.backdrop}
            open={props.isOpen}
            onClick={() => props.onClose()}
        >
            <div className={classes.imgViewContainer}>
                <IconButton aria-label="delete" className={classes.closeIcon}>
                    <CloseIcon fontSize="large" />
                </IconButton>
                {
                    props.name ? <h6 className="mt-3">{ props.name }</h6> : ""
                }
                { 
                    props.src ? 
                    <img 
                        className="img" 
                        onClick={e => { e.stopPropagation() }} 
                        src={props.src} 
                        alt="img"
                    /> : "" 
                }
            </div>
        </Backdrop>
    )
}

export default function ImageGallery(props){
    const classes = useStyles()
    const [selectedIndex, selectIndex] = useState(null)

    const selectAnIndex = index => {
        selectIndex(index)
    }

    const closeDialog = () => {
        selectIndex(null)
    }

    return (
        <div className={classes.imgContainer}>
            {
                props.images && props.images.length ?
                props.images.map((image,index) => (
                    <ButtonBase className="img-wrapper" onClick={() => selectAnIndex(index)} key={index}>
                        <img className="img" src={image.src} alt={`img${index}`}/>
                        <div className="overlay">
                            { image.name ? <div className="img-title">{ image.name }</div> : "" }
                        </div>
                    </ButtonBase>
                    
                )) :
                <div>
                    <small className="gray-text">
                        { props.noImageText ? props.noImageText : "No image found!" }
                    </small>
                </div>
            }
            {
                props.images ?
                <ImageDialog
                    isOpen={selectedIndex !== null}
                    src={props.images[selectedIndex] && props.images[selectedIndex].src}
                    name={props.images[selectedIndex] && props.images[selectedIndex].name}
                    onClose={closeDialog}
                    classes={classes}
                /> : ""
            }
            
        </div>
    )
}

ImageGallery.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string.isRequired,
        name: PropTypes.any
    })),
    noImageText: PropTypes.string 
}