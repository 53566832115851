import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import CommonImage from './CommonImage';

const useStyles = makeStyles((theme) => ({
	commonContainer: {
		padding: '2rem 0 !important',
		'& p': {
			fontSize: '1rem',
		},
	},
	whyUsContainer: {
		backgroundColor: '#DDF0F3',
		textAlign: 'center',
		padding: '1rem 0',
		'& h2': {
			color: '#00083C',
			fontSize: '25px',
			fontWeight: '400 !important',
			marginBottom: '2rem',
		},
	},
	circledIcon: {
		backgroundColor: '#FFFFFF',
		textAlign: 'center',
		borderRadius: '50%',
		padding: '2.8rem',
		height: '5rem',
		width: '5rem',
		margin: '0 auto',
		boxShadow: '0 3px 6px 0px rgb(0 0 0 / 0.16)',
	},
	circledIconContainer: {
		textAlign: 'center',
	},
	circledText: {
		marginTop: '1rem',
		fontSize: '0.8rem',
		fontWeight: 'bold',
	},
	h2: {
		color: '#00083C',
		fontWeight: 'bold',
	},
}));

function InfoBox(props) {
	const useStylesForInfoBox = makeStyles((theme) => ({
		container: {
			textAlign: 'center',
		},
		img: {marginBottom: '.5rem'},
		title: {
			fontWeight: 'bold !important',
			fontSize: '20px',
			textTransform: 'uppercase',
			marginBottom: '.8rem',
		},
		subTitle: {},
	}));
	const classes = useStylesForInfoBox();

	return (
		<div className={`d-flex align-center justify-center flex-column ${classes.container}`}>
			<CommonImage src={props.sourcePath} className={classes.img} width={props.width ?? '12rem'} />
			<div className={classes.title}>{props.title}</div>
			<div className={classes.subTitle}>{props.subTitle}</div>
		</div>
	);
}

function CircledIcon(props) {
	const classes = useStyles();
	return (
		<div className={classes.circledIconContainer}>
			<div className={`d-flex align-center justify-center ${classes.circledIcon}`}>
				<CommonImage src={props.sourcePath} height="2.5rem" />
			</div>
			<div className={classes.circledText}>{props.text}</div>
		</div>
	);
}

function PublicPageComponents(props) {
	const classes = useStyles();
	return (
		<Fragment>
			<AffiliationComponent classes={classes} />
			<OurServicesComponent classes={classes} />
			<WhyUsComponent classes={classes} />
			<AboutUsComponent classes={classes} />
		</Fragment>
	);
}

function AffiliationComponent(props) {
    const { classes } = props;
    const width = '10rem';
	return (
		<div className={classes.commonContainer}>
			<div className={`container`}>
				<h2 className={classes.h2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
					Affiliations
				</h2>
				<Grid container justify="center" alignItems="center" spacing={3}>
					<Grid item xs={6} sm={6} md={6} lg={3}>
                        <InfoBox sourcePath={'/images/icons/logo_tryotel.svg'} width={width}/>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<InfoBox sourcePath={'/images/icons/logo_saimonglobal.svg'} width={width}/>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<InfoBox sourcePath={'/images/icons/logo_amedeus.svg'} width={width}/>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<InfoBox sourcePath={'/images/icons/logo_sabre.svg'} width={width}/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

function OurServicesComponent(props) {
	const { classes } = props;
	return (
		<div className={classes.commonContainer}>
			<div className={`container`}>
				<h2 className={classes.h2} style={{ textAlign: 'center',  marginBottom: '2rem'  }}>
					Our Services
				</h2>
				<Grid container justify="center" alignItems="baseline" spacing={3}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<InfoBox
							sourcePath={'/images/icons/icon_flight.svg'}
							title="Flights"
							subTitle="700+ airlines where you can get the 
                        cheapest airfares for your destinations."
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<InfoBox
							sourcePath={'/images/icons/icon_hotel.svg'}
							title="Hotels"
							subTitle="500K+ hotels to choose from. It makes 
                        your budget travel more cost-effective."
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<InfoBox
							sourcePath={'/images/icons/icon_holiday.svg'}
							title="Holidays"
							subTitle="Holiday packages for remarkable 
                        destinations all over the world."
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<InfoBox
							sourcePath={'/images/icons/icon_visa_processing.svg'}
							title="Visa Processing"
							subTitle="We process visas for all the attractive 
                        travel destinations around the world.
                        "
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

function WhyUsComponent(props) {
	const classes = useStyles();
	return (
		<div className={classes.whyUsContainer}>
			<div className={`container`}>
				<h2>Why Us?</h2>
				<Grid container justify="center" alignItems="baseline" spacing={3}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<CircledIcon
							sourcePath={'/images/icons/icon_passport.svg'}
							text="40 years of experience in 
Travel Industry"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<CircledIcon sourcePath={'/images/icons/icon_pad.svg'} text="Access to vast inventory" />
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<CircledIcon
							sourcePath={'/images/icons/icon_pricing.svg'}
							text="Grow your profit through our 
cost-effective pricing"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<CircledIcon sourcePath={'/images/icons/icon_payment.svg'} text="Flexible payment" />
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

function AboutUsComponent(props) {
	const { classes } = props;
	return (
		<div className={classes.commonContainer}>
			<div className={`container`}>
				<Grid container justify="center" alignItems="center" spacing={5}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<h2 className={classes.h2}>About Us</h2>

						<p>
							Flight Local is a rapidly growing company who dominates the online travel & tourism trade. We
							provide a complete travel solution for your business.
						</p>
						<p>
							Our motto is to establish globalized amenities for every travel company that wishes to expand
							its business from ground to web.It is an enriched platform where we ensure services
							compared to international quality. We have successfully entrenched our service as an
							authentic & adaptable brand in the digital aura of technology worldwide.
						</p>
						<p>
							We offer prompt visa service, varieties of ticketing options, multiple lists for short &
							long holiday plans, group tour packages, endless hotel lists as per requirement & what not.
						</p>
						<p>
							By connecting with Flight Local, you will acquire attractive yet affordable fares,
							custom-built search options, hassle-free booking facilities, etc. We are committed to work
							restlessly for the growth of OTA in our country.
						</p>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<CommonImage src="/images/icons/waiting_for_plane.svg" width="100%" />
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default PublicPageComponents;
