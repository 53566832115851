import client from "apolloClient"
import getConfiguration from "gql/auth/queries/getConfiguration"

export default () => async dispatch => {
    try {
        const config = await client.query({
            query: getConfiguration
        }).then(response => response.data.getConfigurationForClient.result)

        dispatch({
            type: "SET_CONFIGURATION",
            payload: config
        })
    } catch (error) {
        console.error("Err in getConfiguration", error)
    }
}