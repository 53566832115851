import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles"
import { cloneObjectOrArray, isUndefined, stringToObjRef } from "helper";
import CommonTextField from "modules/common/components/CommonTextField";
import CommonSelectComponent from "modules/common/components/CommonSelectComponent";
import MultiAirportSearchBoxComponent from "modules/flightMarkup/components/MultiAirportSeachBoxComponent";
import CustomDatePicker from "modules/common/components/CustomDatePicker";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UiConfig } from "config";
import MultiAirlineSearchBox from "modules/flightMarkup/components/MultiAirlineSearchBoxComponent";

// import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CommonDialog from "modules/common/components/CommonDialog";
import { Button, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.down("sm")]: {
			padding: "0.5rem",
			marginBottom: "2rem"
		}
	},
	bottomMargin: {
		marginBottom: "1rem"
  },

  w100: {
    width: '100% !important',
  },

  formSubtitle: {
    fontSize: '1rem',
      margin: '1rem 0',
  },
  
}))

  
  function CreateForm(props) {
      const { promoCode, promoCodeUid, promoCodeError, onFormChange } = props;
    const item = promoCode;
      const classes = useStyles();
  
    const handleChange = (value, fieldName) => {
      let promoCodeObj = cloneObjectOrArray(item),
        promoCodeErrObj = cloneObjectOrArray(promoCodeError);
  
      stringToObjRef(promoCodeObj, fieldName, value);
      if (!isUndefined(stringToObjRef(promoCodeErrObj, fieldName)))
        stringToObjRef(promoCodeErrObj, fieldName, "");
  
      onFormChange({ promoCode: promoCodeObj, promoCodeError: promoCodeErrObj });
    };
  
    return (
      <div className="px-5">
        <div>
          <h6 className={classes.formSubtitle}>
            Promo Code Configuration
          </h6>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
              <CommonTextField
                value={item.code}
                label="Promo Code"
                className={classes.w100}
                errorText={promoCodeError.code}
                disabled={promoCodeUid != null ? true : false}
                onChange={e => handleChange(e.target.value, "code")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <CommonSelectComponent
                value={item.type}
                className={classes.w100}
                label="Promo Code Type"
                items={[
                { text: "Percentage", value: "_PERCENTAGE_" },
                { text: "Flat", value: "_FLAT_" },
                ]}
                // required
                errorText={promoCodeError.type}
                onChange={val => handleChange(val, "type")}
              /> 
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <CommonTextField
                value={item.amount}
                label="Promo Amount"
                required
                className={classes.w100}
                errorText={promoCodeError.amount}
                onChange={e => handleChange(e.target.value, "amount")}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                          {(item.type != null && item.type == '_PERCENTAGE_') ? '%' : 'BDT'}
                        </InputAdornment>
                    ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommonTextField
                value={item.description}
                label="Promo Code Description"
                className={classes.w100}
                multiline
                rows={3}
                errorText={promoCodeError.description}
                onChange={e => handleChange(e.target.value, "description")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomDatePicker
                disablePast
                label="Validity From"
                value={item.validityFrom}
                className={classes.w100}
                required
                errorText={promoCodeError.validityFrom}
                onChange={date => handleChange(date, "validityFrom")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomDatePicker
                label="Validity To"
                value={item.validityTo}
                minDate={item.validityFrom}
                className={classes.w100}
                required
                error={promoCodeError.validityTo}
                onChange={date => handleChange(date, "validityTo")}
              />
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <CommonSelectComponent
                value={item.platformType}
                className={classes.w100}
                label="Platform Type"
                disabled={true}
                items={[
                  { text: "B2C", value: "_B2C_" },
                  { text: "B2B", value: "_B2B_" },
                  { text: "B2E", value: "_B2E_" },
                ]}
                required
                errorText={promoCodeError.platformType}
                onChange={val => handleChange(val, "platformType")}
              />
            </Grid> */}
            <Grid item xs={12} lg={6}>
              <CommonSelectComponent
                value={item.serviceType}
                className={classes.w100}
                label="Service Type"
                items={[
                  { text: "Flight", value: "_FLIGHT_" },
                //   { text: "Visa", value: "_VISA_" },
                //   { text: "Package", value: "_PACKAGE_" },
                //   { text: "Hotel", value: "_HOTEL_" },
                ]}
                required
                errorText={promoCodeError.serviceType}
                onChange={val => handleChange(val, "serviceType")}
              />
            </Grid>
            
          </Grid>
        </div>
        <div className={classes.bottomMargin}>
          <h6 className={classes.formSubtitle}>
            Promocode Usage
          </h6>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={promoCodeUid ? 4 : 6}>
                <CommonTextField
                    value={item.usage.limit}
                    label="Promo Limit"
                    required
                    className={classes.w100}
                    errorText={promoCodeError.usage.limit}
                    onChange={e => handleChange(e.target.value, "usage.limit")}
                /> 
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={promoCodeUid ? 4 : 6}>
                <CommonSelectComponent
                    value={item.usage.limitType}
                    className={classes.w100}
                    label="Limit Type"
                    items={[
                    { text: "Daily", value: "_DAILY_" },
                    { text: "Lifetime", value: "_LIFETIME_" },
                    { text: "Total", value: "_TOTAL_" },
                    ]}
                    required
                    errorText={promoCodeError.usage.limitType}
                    onChange={val => handleChange(val, "usage.limitType")}
                /> 
              </Grid>
            {
              promoCodeUid &&   
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <CommonTextField
                  value={item.usage.counter}
                  label="Promo Counter"
                  className={classes.w100}
                  disabled={true}
                  errorText={promoCodeError.usage.counter}
                // onChange={e => handleChange(e.target.value, "usage.counter")}
                />
              </Grid>
            }
          </Grid>
          </div>
        <div className={classes.bottomMargin}>
          <h6 className={classes.formSubtitle}>
            Module - Flight
          </h6>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
          <MultiAirlineSearchBox
                      value={item.flight.airlines}
                label="Airlines"
                errorText={promoCodeError.flight.airlines}
                      //onChange={val => handleChange(val, "destinationAirportCodes")}
                      onInput={val => handleChange(val, "flight.airlines")}
                      {...props}
              />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.originAirportCodes}
                    label="Origin Airport Codes"
                    className={classes.w100}
                    errorText={promoCodeError.flight.originAirportCodes}
                    onInput={val => handleChange(val, "flight.originAirportCodes")}
                {...props}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MultiAirportSearchBoxComponent
                value={item.flight.destinationAirportCodes}
                    label="Destination Airport Codes"
                    className={classes.w100}
                    errorText={promoCodeError.flight.destinationAirportCodes}
                //onChange={val => handleChange(val, "destinationAirportCodes")}
                onInput={val => handleChange(val, "flight.destinationAirportCodes")}
                {...props}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControlLabel
                    control={<Checkbox 
                      checked={item.flight.isApplicableForIndividualTraveller} 
                      color="primary"
                      onChange={ev => handleChange(ev.target.checked, "flight.isApplicableForIndividualTraveller")}
                      name="checkedH" />}
                      label="Applicable For Individual Traveller"
                  />
                </Grid>
          </Grid>
        </div>
      </div>
    );
  }  

export default class AddOrUpdatePromoCodeComponent extends Component {
    
  promoCodeObj = {
    code: "",
    platformType: "_B2B_",
    serviceType: "",
    flight: {
        airlines: [],
        originAirportCodes: [],
        destinationAirportCodes: [],
        isApplicableForIndividualTraveller: false
    },
    type: "",
    amount: "",
  usage: {
    limit: "",
    limitType: "",
    counter: "",
    },
    validityFrom: null,
  validityTo: null,
    description: "",
    isActive: false,
  };
    
  promoCodeErrObj = {
    code: "",
    platformType: "",
    serviceType: "",
    flight: {
        airlines: "",
        originAirportCodes: "",
        destinationAirportCodes: "",
        // applyOn: "",
    },
    amount: "",
    usage: {
      limit: "",
      limitType: "",
      counter: "",
      },
    validityFrom: null,
    validityTo: null,
  };
  
      state = {
        promoCodeUid: null,
        promoCode: { ...this.promoCodeObj },
        promoCodeError: { ...this.promoCodeErrObj },
        isLoadingPromoCodeDetails: false
      };

	// componentDidMount() {
    //     this.getPromoCodeDetails();
    // }

    componentDidUpdate(prevProps){
        // init inputs & errors
      if (!this.props.isOpen && prevProps.isOpen) {
        this.setState({ promoCode: { ...this.promoCodeObj }, promoCodeError: { ...this.promoCodeErrObj } });
      }
    
        // get full object to update
      if (this.props.promoCodeUid && !prevProps.promoCodeUid && this.props.isOpen) this.getPromoCodeDetails(this.props.promoCodeUid)
    }
    

	async getPromoCodeDetails(uid) {
    this.setState({ isLoadingPromoCodeDetails: true })
    this.props.actionStartLoading();

            const response = await this.props.actionGetPromocodeForUpdate(uid);

            if (response) {
              let promoCode = cloneObjectOrArray(response)
              
              console.log("response--> ", response);
                promoCode.flight.airlines = promoCode.flight.airlines.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
              
                // promoCode.flight.exceptAirlines = promoCode.flight?.exceptAirlines?.map((str, index) => ({
                //   fullName: index + 1, shortName: index + 1, meta: {
                //   iataCode: str
                //   }
                // }))
              
                // promoCode.flight.exceptOriginAirportCodes = promoCode.flight?.exceptOriginAirportCodes?.map((str, index) => ({
                //   fullName: index + 1, shortName: index + 1, meta: {
                //   iataCode: str
                //   }
                // }))
              
                // promoCode.flight.exceptDestinationAirportCodes = promoCode.flight?.exceptDestinationAirportCodes?.map((str, index) => ({
                //   fullName: index + 1, shortName: index + 1, meta: {
                //   iataCode: str
                //   }
                // }))
          
                promoCode.flight.originAirportCodes = promoCode.flight.originAirportCodes.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
          
                promoCode.flight.destinationAirportCodes = promoCode.flight.destinationAirportCodes.map((str, index) => ({
                  fullName: index + 1, shortName: index + 1, meta: {
                  iataCode: str
                  }
                }))
          
                // promoCode.bookingClasses = promoCode.bookingClasses.map((str, index) => ({key: str, value: str}))
				this.setState({ 
                    isLoadingPromoCodeDetails: false, 
                    promoCode: promoCode,
                    promoCodeUid: uid,
                    promoCodeError: { ...this.promoCodeErrObj }
                })
            }
  this.props.actionStopLoading()
    
		
    }
    
    validateAndSubmit(event) {
        if (event) event.preventDefault();
        console.log("SUBMITTED");
    
        let promoCodeObj = cloneObjectOrArray(this.state.promoCode);
    
        if (this.isValidInputs()) {
          if (this.state.promoCodeUid) this.updatePromoCode(promoCodeObj);
          else this.addPromoCode(promoCodeObj);
        }
    }
    
    isValidInputs() {
        let isValid = true,
          promoCodeErrObj = cloneObjectOrArray(this.state.promoCodeError);
    
        const requiredInputs = [
          "code",
          "platformType",
          "serviceType",
          "amount",
          "usage.limit",
          "usage.limitType",
          "validityFrom",
          "validityTo",

        ];
    
        requiredInputs.forEach(input => {
          if (!stringToObjRef(this.state.promoCode, input)) {
            stringToObjRef(promoCodeErrObj, input, UiConfig.REQUIRED_MESSAGE);
            console.log("Validation failed for ", input);
            isValid = false;
          }
        });
    
        const requiredArrayInputs = [
          "flight.airlines",
          'flight.originAirportCodes',
          "flight.destinationAirportCodes"
        ];
    
        requiredArrayInputs.forEach(input => {
         // console.log(stateReftoItem.length)
          if (!stringToObjRef(this.state.promoCode, input).length) {
            stringToObjRef(promoCodeErrObj, input, UiConfig.REQUIRED_MESSAGE );
            console.log("Validation failed for ", input);
          isValid = false;
          }
        })
    
        // if (this.state.promoCode.fareFrom &&
        //     this.state.promoCode.fareTo &&
        //     parseInt(this.state.promoCode.fareFrom) > parseInt(this.state.promoCode.fareTo)) {
        //   stringToObjRef(promoCodeErrObj, 'fareFrom', 'Fare From can not be greater than Fare To')
        //   isValid = false;
        // }
    
        this.setState({ promoCodeError: promoCodeErrObj });
    
        return isValid;
    }

    async addPromoCode(promoCode) {

        let promoCodeError = cloneObjectOrArray(this.state.promoCodeError);
    
      let formattedPromoCode = cloneObjectOrArray(promoCode);
      formattedPromoCode.usage.limit = parseFloat(formattedPromoCode.usage.limit);

      formattedPromoCode.flight.airlines = formattedPromoCode.flight.airlines.map(item => item.meta.iataCode);

        formattedPromoCode.flight.originAirportCodes = formattedPromoCode.flight.originAirportCodes.map(
          item => item.meta.iataCode
        );
      formattedPromoCode.flight.destinationAirportCodes = formattedPromoCode.flight.destinationAirportCodes.map(
          item => item.meta.iataCode
      );
      delete formattedPromoCode.usage.counter;

    
        console.log("formattedPromoCode -> ", formattedPromoCode);
        this.props.actionStartLoading("Adding Promo Code, please wait..");
        try {
          const newPromoCodeResponse = await this.props.actionCreatePromocode(
            { promoCode: formattedPromoCode}
           );
       
          console.log("newPromoCodeResponse-> ", newPromoCodeResponse);
          
       
           if (newPromoCodeResponse) {
             this.props.actionShowSnackbar({
               message: "Promo Code added successfully!",
               secondaryMessage:
                 "Please activate your promoCode when you are ready",
               type: "SUCCESS"
             });
             this.props.onCreateNew(newPromoCodeResponse.result);
           } else {
             this.props.actionShowSnackbar({
               message: "Something went wrong! Please try again later..",
               type: "ERROR"
             });
           }
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({ promoCodeError })
          this.props.actionStopLoading();
        }
        

      }
    
      async updatePromoCode(promoCode) {
        let formattedPromoCode = cloneObjectOrArray(promoCode);
        formattedPromoCode.usage.limit = parseFloat(formattedPromoCode.usage.limit);
        formattedPromoCode.flight.airlines = formattedPromoCode.flight.airlines.map(item => item.meta.iataCode);
        // formattedPromoCode.flight.exceptAirlines = formattedPromoCode.flight?.exceptAirlines?.map(item => item.meta.iataCode);

        formattedPromoCode.flight.originAirportCodes = formattedPromoCode.flight.originAirportCodes.map(
          item => item.meta.iataCode
        );
        formattedPromoCode.flight.destinationAirportCodes = formattedPromoCode.flight.destinationAirportCodes.map(
          item => item.meta.iataCode
        );
    
        delete formattedPromoCode.id;
        delete formattedPromoCode.uid
        delete formattedPromoCode.code
        delete formattedPromoCode.usage.counter
        delete formattedPromoCode.serviceType
        delete formattedPromoCode.platformType
        delete formattedPromoCode.createdAt
        delete formattedPromoCode.updatedAt
    
        this.props.actionStartLoading("Updating Company Promo Code, please wait..");
    
        const updatedDiscount = await this.props.actionUpdatePromocode({
          promoCode: formattedPromoCode,
          promoCodeUid: this.state.promoCodeUid
        });
    
        if (updatedDiscount) {
          this.props.actionShowSnackbar({
            message: "Promo Code updated successfully",
            type: "SUCCESS"
          });
          this.props.onUpdate(updatedDiscount);
        } else {
          this.props.actionShowSnackbar({
            message: "Promo Code update failed",
            type: "ERROR"
          });
        }
        this.props.actionStopLoading();
      }
    
      handleFormChange({ promoCode, promoCodeError }) {
        this.setState({ promoCode, promoCodeError });
      }


	render() {
        const { promoCode, isLoadingPromoCodeDetails, promoCodeUid, promoCodeError } = this.state;


		// if(isLoadingPromoCodeDetails) return <InlineLoader/>

        return (
                <CommonDialog
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                    header={`${this.props.promoCodeUid ? "Update" : "Add A"} Promo Code`}
                    content={(
                        <CreateForm
                            promoCode={promoCode}
                            promoCodeUid={promoCodeUid}
                            promoCodeError={promoCodeError}
                            onFormChange={this.handleFormChange.bind(this)}
                            {...this.props}
                        />
                    )}
                    footer={(
                        <Fragment>
                            <Button
                                size="large"
                                color="primary"
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.validateAndSubmit.bind(this)}
                            >
                                Submit
                            </Button>
                        </Fragment>
                    )}
                />

		);
	}
}
