import gql from "graphql-tag"

export default gql`
    mutation forgotAgentPassword($email: Email!) {
        forgotAgentPassword(email: $email){
            message
            statusCode
            result{
                id
            }
        }
    }
`