import client from "apolloClient";
import createPromoCode from "gql/promocode/mutations/createPromoCode";

export default ({promoCode}) => async () => {
  try {
    const response = await client
      .mutate({
        mutation: createPromoCode,
        variables: { promoCode }
      })
      .then(response => response.data.createPromoCode);

    if (response) return response;

    console.log("createPromoCode", response);
  } catch (error) {
    console.error("Err in createPromoCode", error);
  }
};
